import React from 'react';
import ourimpactIMG from '../../assets/images/ourimpactIMG.jpg';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import FeaturedCourses from '../GenericComponents/FeaturedCourses';

export default function MachineLearning() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img5 machine-learning-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            Machine Learning frameworks
                            <span className="watermark">tools and model</span>
                        </h1>
                        <span className="sub-text">We are a team of experienced professionals who are determined to provide efficient IT solutions. Our
                            team of ML engineers has always got your back. Our engineers are qualified, well-trained, and
                            experienced Machine Learners with a strong sense of heavy data usage</span>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}
            {/* About Section Start */}
            <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container custom">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/machine-learning.png" alt="Images" />
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-15">
                            <div className="sec-title">
                                <h2 className="title pb-22">
                                    Machine Learning
                                </h2>
                                <p className="margin-0 pt-15">For a company to rise higher in the market, customer satisfaction is extremely important, which can be
                                    achieved by identifying their preferences and behaviour. Today, Machine Learning and Artificial
                                    Intelligence technologies play a crucial role in making this possible.</p>
                                <p className="margin-0 pt-15">
                                    These technologies offer
                                    enterprises data about the trends in customer behaviour and helps them interpret the preferences and
                                    expectations of their existing and potential customers.
                                </p>
                                <p className="margin-0 mt-3">
                                    Modern-day applications and tools play a key role in reading, solving, and applying the latest algorithms
                                    to carry out various operations. These objectives are unachievable without the assistance of
                                    professional Machine Learning engineers.
                                </p>
                            </div>
                            {/* Counter Section End */}
                            {/* <div class="rs-counter style1 pt-60">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 xs-mb-30">
                                        <div class="counter-area">
                                            <div class="counter-list mb-37">
                                                <div class="counter-icon">
                                                    <img src="assets/images/counter/icons/1.png" alt="Counter">
                                                </div>
                                                <div class="counter-number">
                                                    <span class="rs-count">582</span>
                                                </div>
                                            </div>
                                            <div class="content-part">
                                                <h5 class="title">Projects completed for our respected clients.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="counter-area">
                                            <div class="counter-list mb-37">
                                                <div class="counter-icon">
                                                    <img src="assets/images/counter/icons/3.png" alt="Counter">
                                                </div>
                                                <div class="counter-number">
                                                    <span class="rs-count">215</span>
                                                    <span class="prefix">+</span>
                                                </div>
                                            </div>
                                            <div class="content-part">
                                                <h5 class="title">Experienced people serving to clients.</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* Counter Section End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}
            <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container custom">
                    <div className="row">
                        <div className="col-lg-12 pl-60 md-pl-15">
                            <div className="sec-title">
                                <h2 className="title pb-22">
                                    How can VSIT LLC  assist you?
                                </h2>
                                <p className="margin-0 pt-15">We are a team of experienced professionals who are determined to provide efficient IT solutions. Our
                                    team of ML engineers has always got your back. Our engineers are qualified, well-trained, and
                                    experienced Machine Learners with a strong sense of heavy data usage. </p>
                                <ul className="margin-0 mt-4">
                                    <h5>This is what makes them different:</h5>
                                    <li className="margin-0 mt-3">
                                        They collect appropriate data and employ it to build models using the ML tools for enhanced
                                        results. They focus on the present value while also considering the future possibilities of
                                        Machine Learning.
                                    </li>
                                    <li className="margin-0 mt-3">
                                        Images, audio, and video usually contain heavy data. Our ML engineers make the best use of
                                        algorithms and data pipelines for creating error-free and responsive programmes running
                                        complex data
                                    </li>
                                    <li className="margin-0 mt-3">
                                        They create highly-responsive MLOps platforms to carry out a wide range of Machine Learning
                                        data operations, which also includes designing serverless deployment models.
                                    </li>
                                    {/* <li class="margin-0 mt-3">
                                        <img src="https://miro.medium.com/max/787/0*qe-bxSE2vgfSxsfd"/>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            Here’s what well-trained <br /> Machine Learning engineers do
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/1.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Decision making <br /> technology</a></h2>
                                    <p className="services-txt"> Generate data that is utilised in escalating a business’s growth using data-driven decision making technology</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/2.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Accurate <br /> results</a></h2>
                                    <p className="services-txt"> Ensure the correct and fruitful use of data models for 100% accurate results</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/3.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Work with the <br /> ML frameworks</a></h2>
                                    <p className="services-txt"> Precisely and closely work with the ML frameworks, tools, and models</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/4.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Working on large <br /> data</a></h2>
                                    <p className="services-txt"> Constantly working on large data files and data research to derive the latest information useful
                                        for the company</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/5.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Avoid all <br /> problems</a></h2>
                                    <p className="services-txt"> Detect and raise issues on time so that potential risks and problems are completely avoided</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            {/* Why Choose Section Start */}
            <div className="rs-choose pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 pr-70 md-pr-15 md-mb-50">
                            <div className="sec-title mb-35">
                                <h2 className="title pb-30">
                                    Why choose us
                                </h2>
                                <p className="margin-0">With trained and experienced Machine Learning engineers, your company can create effective models.
                                    These engineers provide the best of both worlds, i.e. they make the codes of data scientists more
                                    accessible and useful, while also paying attention to the programming method.</p>
                            </div>
                            {/* Skillbar Section Start */}
                            <div className="rs-skillbar style1">
                                <div className="cl-skill-bar">
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Immigration Services</span>
                                    <div className="skillbar" data-percent={90}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Financial Advices</span>
                                    <div className="skillbar" data-percent={85}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">AI Strategy</span>
                                    <div className="skillbar" data-percent={75}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Business Security</span>
                                    <div className="skillbar margin-no" data-percent={65}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                </div>
                            </div>
                            {/* Skillbar Section End */}
                        </div>
                        <div className="col-lg-6">
                            <div className="rs-videos choose-video">
                                <div className="images-video">
                                    <img src="assets/images/choose/chosse-video.jpg" alt="images" />
                                </div>
                                <div className="animate-border">
                                    <a className="popup-border" href="https://www.youtube.com/watch?v=FMvA5fyZ338">
                                        <i className="fa fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose Section Start */}
            {/* Partner Section Start */}
            <div className="rs-patter-section black-bg pt-80 pb-75">
                <div className="container custom">
                    <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Partner Section End */}
        </div>
    )
}