import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function SQLMorph() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img16 aws-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <div className='d-flex'>
                            <h1 className="page-title col-sm-6 col-xs-12">
                                VSIT LLC  SQLMorph is here for instant and easy translation. It saves your time and energy.
                            </h1>
                            <div className='col-sm-6 col-xs-12'>
                                <h1 className="page-title">What is SQL Morph?</h1>
                                <span className="sub-text" style={{ paddingRight: 0 }}>SQL Morph is an application that helps translate SQL language to another without any errors and cutting off the extra time. It works exactly like Google translate. With its help, your migration can be completed to a cloud-native database with ease. SQL Morph is a SaaS tool for easy working and handling of data in a better way. This application analyzes the source SQL in its primary language and then alters the semiotic definition.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}

            <div id="rs-about" className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70">
                <div className="container">
                    <div className="row y-middle">

                        <div className="col-lg-12 pl-46 md-pl-15">
                            <div className="sec-title3">
                                <h2 className="title pb-30">
                                    Advantages of using SQL Morph:
                                </h2>
                                {/* <p className="margin-0 pb-40">We provide the best Dataiku guidance to our clients. Dataiku implementation and execution is made easy with VSIT LLC . </p> */}
                                <ul className="icon-item">
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Saves time:</b> It reduces manual work and increases machine efficiency.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>No extra cost:</b> The application is free or is of very less cost for users. You can simply download it and use it without any extra cost.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Makes migration fast:</b> It can translate data from one language to another without any extra human effort.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Error-free:</b> It cuts out the errors and makes the data error-prone.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Many languages:</b> A large number of languages can be used and translated with the help of this application.</span>
                                    </li>
                                </ul>
                                <p className="margin-0 pb-40">For more information and assistance, you can contact VSIT LLC . </p>

                            </div>
                        </div>
                        <div className="col-lg-12 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/pages/sqlmorph/1.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Services Section End */}
            <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            SQL Morph assistance at VSIT LLC  Inc.

                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 md-mb-50">
                            <div className="services-item">
                                {/* <div className="services-icon">
                              <img src="assets/images/services/style3/4.png" alt="Services">
                          </div> */}
                                <div className="services-content">
                                    <h2 className="services-title"><a>What are the features of SQL Morph?</a></h2>
                                    <p className="services-txt">This application can serve your company in multiple ways. The SQL language needs to be translated to another one before being used on the Cloud platforms. SQLMorph  translates that to an average representation. It allows in making simple "find and substitutes" differences as well as more obscure changes.</p>
                                    <p className="services-txt">The compiler has FOUR phases. In the first phase, the source language is changed using the grammar written for each source language. Then the result of that parser is transcribed into an intermediary design. Then the rewriters perform target-specific translations, and finally, the result is changed in the target language.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mb-50">
                            <div className="services-item">
                                {/* <div className="services-icon">
                              <img src="assets/images/services/style3/2.png" alt="Services">
                          </div> */}
                                <div className="services-content">
                                    <h2 className="services-title"><a>The languages that can be translated are:</a></h2>
                                    {/* <p className="services-txt">Dataiku impedes the quick creation of interactive visual front ends for rational outcomes. It organizes analytical dashboards and applications for business punters. </p> */}
                                    <ul className="icon-item mt-20">
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Oracle to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Microsoft SQL Server to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">SAP Hana to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Teradata to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Hive to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Impala to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">SparkSQL to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Databricks to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Netezza to Snowflake</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Teradata to Impala</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Snowflake to SAP Hana</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Snowflake to Impala</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Snowflake to Oracle</span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* Services Section End */}
            <div className="rs-services style3 pt-100 pb-10">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            What makes VSIT LLC  SQLMorph reliable?
                        </h2>
                        <p className="services-txt mt-30">We, at VSIT LLC , know that manual language translation is expensive and time consuming. But this doesn't entirely deny the existence of translation. Your business needs a cost-effective and time saving solution. </p>
                        <p className="services-txt">Our company can assist you with SQL translations by lowering the consumption costs. We help you migrate to a cloud database with zero errors and delays. VSIT LLC  enables fast SQL migrations with their SQLMorph tool that's regularly updated. </p>

                        <p className="services-txt">Wondering where this confidence came from? The engineers at VSIT LLC  are well-versed with their work. They are trained specialists who have a long working experience in the field of SQLMorph migration and translation. So, all you need to do is trust us and the rest will be taken care of by VSIT LLC .  </p>

                        <p className="services-txt">Contact VSIT LLC  for more information on SQLMorph... </p>
                    </div>
                </div>
            </div>


            {/* Partner Section Start */}
            <div className="rs-patter-section black-bg pt-80 pb-75">
                <div className="container custom">
                    <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Partner Section End */}
        </div>
    )
}