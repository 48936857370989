import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function PAMS() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img7 aws-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            What is PAMS?
                            <span className="watermark">pams</span>
                        </h1>
                        <span className="sub-text">Pluggable Authentic Memory Software (PAMS) is a structure that gives system directors the capability to include multiple authentication mechanisms into an existing system for the use of pluggable modules. PAMS uses a pluggable and modular design, which gives the system director the inflexibility in setting authentication styles for the system.

                        </span>
                        <p className='sub-text'>
                            Pluggable authentication modules are a common frame for authentication and safety. Understanding and working with PAMS can be veritably propitious for outlining and enforcing a secure, effective, single and right result.
                        </p>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}

            <div id="rs-about" className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70">
                <div className="container">
                    <div className="row y-middle">

                        <div className="col-lg-6 pl-46 md-pl-15">
                            <div className="sec-title3">
                                <h2 className="title pb-30">
                                    Utility of PAMS for inventors
                                </h2>
                                <p className="margin-0 pb-40">PAMS is a helpful system for inventors and directors for numerous reasons, so some of them are listed below</p>
                                <ul className="icon-item">
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">PAMS helps in furnishing a common authentication design that can be used with a large variety of operations.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">PAMS provides vital inflexibility and power over authentication for both system directors and operation inventors.</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">PAMS provides a single, completely- proved library that allows inventors to write programs without having to produce their authentication schemes</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-lg-6 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/pages/pams/1.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Services Section End */}
            <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            So, what's PAMS Configuration Files?

                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 md-mb-50">
                            <div className="services-item">
                                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                                <div className="services-content">
                                    <h2 className="services-title"><a>Configuration</a></h2>
                                    <p className="services-txt"> The PAMS configuration lines are stored in the /etc/pam.d/ directory which holds for each PAMS-apprehensive operation. In earlier performances of PAMS, the/ etc/pam.conf train was used, but this train is now replaced and is only used if the/ etc/pam.d/ directory doesn't live.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mb-50">
                            <div className="services-item">
                                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                                <div className="services-content">
                                    <h2 className="services-title"><a>Let's talk about PAMS Service Files </a></h2>
                                    <p className="services-txt">Each PAMS-apprehensive operation has a train in the/ etc/pam.d/ directory. Each train in this directory holds the same name as the service to which it controls the access. Operations that are enabled to make use of PAMS can be plugged-in to new technologies without altering the being operations. This inflexibility allows directors to do the following
                                    </p>
                                    <ul className="icon-item mt-20">
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Elect any authentication service on the system for an operation.</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Use a variety of authentication mechanisms for a handed service .</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">You can add new authentication service modules without making changes in being operations.</span>
                                        </li>


                                    </ul>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Services Section End */}
            <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            Why choose VSIT LLC ’ PAM?
                        </h2>
                        <p className="services-txt mt-30 mb-30">Being a leading IT solutions company, VSIT LLC  has all the necessary applications needed for setting up a cloud platform. Controlling multiple accounts in a system can be cumbersome. But with RV IT System’s Pluggable Module, you can easily regulate your firm’s authentication systems.
                        </p>
                        <p className="services-txt mb-30"><b>No malware: </b>We are particular about our client’s confidential information and data. To protect all the files from malware and hacking activities, we have employed strict surveillance and protection tools. This way all of your data and important information stay safe while you enjoy the best services.
                        </p>
                        <p className="services-txt mb-30"><b>PAM Management Groups:</b> The Management groups that are a part of PAM manage the distinct procedures of accessing the restricted files. Account, Authentication, Password, and Session are the four aspects that are strictly managed. VSIT LLC  carefully manages them all for enhanced results and extra safety.
                        </p>
                        <p className="services-txt mb-30"><b>Add new modules:</b> With VSIT LLC ’ modules, you can efficiently and effortlessly add new authentication modules. Adding new modules doesn’t mean the original or old system is altered. Your new modules are added without making changes to the existing operations.
                        </p>

                    </div>
                
                </div>
            </div>

            {/* Partner Section Start */}
            <div className="rs-patter-section black-bg pt-80 pb-75">
                <div className="container custom">
                    <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Partner Section End */}
        </div>
    )
}