    import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AIStrategy from './AIStrategy';
import DataStrategy from './DataStrategy';
import DataScience from './DataScience';
import DataEngineering from './DataEngineering';

export default function Documentation() {
    return (
        <div className="mt-30 mb-30 gray-bg">
            <Tabs className="container pt-50 pb-50">
                <TabList>
                <Tab>Data Engineering</Tab>

                <Tab>AI Strategy</Tab>
                <Tab>Data Strategy</Tab>
                <Tab>Data Science</Tab>
                </TabList>

                <TabPanel>
                    <DataEngineering/>
                </TabPanel>
                <TabPanel>
                    <AIStrategy/> 
                </TabPanel>
                <TabPanel>
                    <DataStrategy/>
                </TabPanel>
                <TabPanel>
                    <DataScience/>
                </TabPanel>
              
            </Tabs>
        </div>
    )
}