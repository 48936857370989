import React from 'react';
import ourimpactIMG from '../../assets/images/ourimpactIMG.jpg';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import FeaturedCourses from '../GenericComponents/FeaturedCourses';
import ComingSoon from './ComingSoon';

export default function BigData() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img12 machine-learning-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        BIGDATA INTRODUCTION
                                {/* <span className="watermark">tools and model</span> */}
                        </h1>
                        <span className="sub-text">For industries, big data is a huge deal. The proliferation of IoT and other connected devices has resulted in a tremendous increase in the quantity of data that businesses gather, manage, and analyse. Huge data has the potential to reveal big insights — for all industries, big and small.</span>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}
            {/* About Section Start */}
            <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container custom">
                    <div className="row">                       
                        <div className="col-lg-12 pl-60 md-pl-15">
                            <div className="sec-title">
                                <h2 className="title pb-22">
                                    BIG Data
                                </h2>
                                <p className="margin-0 pt-15">Big Data has a significant influence on organisations all over the world, with applications in areas as diverse as healthcare, insurance, transportation, logistics, and customer service.
</p>
                                <p className="margin-0 pt-15">
                                As a Big Data Engineer, you'll be on your way to a fascinating, ever-changing profession that's expected to develop rapidly through 2025 and beyond.

                                </p>
                                <p className="margin-0 mt-3">
                                This certification course will introduce you to the world of big data engineering. Expert seminars and Ask Me Anything sessions are included. 

                                </p>
                                <p className="margin-0 mt-3">
                                Using interactive live sessions, practical laboratories, and industry projects, master job-critical skills such as Big Data and Hadoop frameworks, how to harness the capability of AWS services, and how to utilise the database management tool and MongoDB to store data.


                                </p>
                                <p className="margin-0 mt-3">
                                Through hands-on exposure with the tools and systems used by big data scientists and engineers, you will get a grasp of what insights big data can give.


                                </p>
                            </div>
                            {/* Counter Section End */}
                            <div className="rs-counter style1 pt-60">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 xs-mb-30">
                                        <div className="counter-area">
                                            <div className="counter-list mb-37">
                                                <div className="counter-icon">
                                                    <img src="assets/images/counter/icons/1.png" alt="Counter"/>
                                                </div>
                                                <div className="counter-number">
                                                    <span className="rs-count">582</span>
                                                </div>
                                            </div>
                                            <div className="content-part">
                                                <h5 className="title">Projects completed for our respected clients.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="counter-area">
                                            <div className="counter-list mb-37">
                                                <div className="counter-icon">
                                                    <img src="assets/images/counter/icons/3.png" alt="Counter"/>
                                                </div>
                                                <div className="counter-number">
                                                    <span className="rs-count">215</span>
                                                    <span className="prefix">+</span>
                                                </div>
                                            </div>
                                            <div className="content-part">
                                                <h5 className="title">Experienced people serving to clients.</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Counter Section End */}
                        </div>
                        <div className="col-lg-12 mt-70 md-mb-20">
                            <div className="images-part">
                                <img src="assets/images/pages/bigdata/2.png" className='w-100' alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}
            <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container custom">
                    <div className="row">
                        <div className="col-lg-12 pl-60 md-pl-15">
                            <div className="sec-title">
                                <h2 className="title pb-22">
                                KEY FEATURES

                                </h2>
                                <p className="margin-0 pt-15">You will learn how to execute predictive modelling and use graph analytics to model problems by following along with the given code. This specialty will teach you how to ask the proper questions about data, communicate successfully with data scientists, and do basic dataset exploration.
 </p>
                                
 <p className="margin-0 pt-15">Fundamental approaches such as data mining and stream processing will be covered. You'll also learn how to use MapReduce, Hadoop cluster, to develop and execute PageRank algorithms. You'll discover how big data has enhanced web search and how online advertising works.

 </p>
 <p className="margin-0 pt-15">You will have a better knowledge of the numerous uses of big data approaches in industry and research by the conclusion of this course.

 </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                        LEARNING OUTCOMES
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/1.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">MapReduce knowledge and <br /> application</a></h2>
                                    <p className="services-txt"> he MapReduce task is mainly divided into 2 phases i.e. Map phase and Reduce phase. Map: As the name suggests its main use is to map the input data in key-value pairs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/2.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Understanding the frequency of event  <br /> occurrences in large data</a></h2>
                                    <p className="services-txt"> The frequency of a particular data value is the number of times the data value occurs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style3/3.png" alt="Services" />
                                </div>
                                <div className="services-content">
                                    <h2 className="services-title"><a href="">Develop algorithms for stream processing and<br/> counting frequent elements</a></h2>
                                    <p className="services-txt"> On the processing side, there are a few choices: Samza, Storm and Spark Streaming are the most popular stream processing frameworks.</p>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            {/* Why Choose Section Start */}
            <div className="rs-choose pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 pr-70 md-pr-15 md-mb-50">
                            <div className="sec-title mb-35">
                                <h2 className="title pb-30">
                                    Why choose us
                                </h2>
                                <p className="margin-0">With trained and experienced Machine Learning engineers, your company can create effective models.
                                    These engineers provide the best of both worlds, i.e. they make the codes of data scientists more
                                    accessible and useful, while also paying attention to the programming method.</p>
                            </div>
                            {/* Skillbar Section Start */}
                            <div className="rs-skillbar style1">
                                <div className="cl-skill-bar">
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Immigration Services</span>
                                    <div className="skillbar" data-percent={90}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Financial Advices</span>
                                    <div className="skillbar" data-percent={85}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">AI Strategy</span>
                                    <div className="skillbar" data-percent={75}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                    {/* Start Skill Bar */}
                                    <span className="skillbar-title">Business Security</span>
                                    <div className="skillbar margin-no" data-percent={65}>
                                        <p className="skillbar-bar" />
                                        <span className="skill-bar-percent" />
                                    </div>
                                </div>
                            </div>
                            {/* Skillbar Section End */}
                        </div>
                        <div className="col-lg-6">
                            <div className="rs-videos choose-video">
                                <div className="images-video">
                                    <img src="assets/images/choose/chosse-video.jpg" alt="images" />
                                </div>
                                <div className="animate-border">
                                    <a className="popup-border" href="https://www.youtube.com/watch?v=FMvA5fyZ338">
                                        <i className="fa fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose Section Start */}
            {/* Partner Section Start */}
            <div className="rs-patter-section black-bg pt-80 pb-75">
                <div className="container custom">
                    <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Partner Section End */}
        </div>
    )
}