import React, { useState } from 'react';
import { AppContainer } from './styles';
import Header from './GenericComponents/Header';
import Footer from './GenericComponents/Footer';

function ApplicationEntry(Props: any) {

    return (
        <>
            <div className={'d-flex vh-100'}>
                <AppContainer className="main-content">
                    <Header />
                    <Footer />
                </AppContainer>
                {/* <a href="//api.whatsapp.com/send?phone=+14695967883&text=Hi, I'm looking for job." title="Share on whatsapp">
                    <img src="assets/images/whatsapp.svg" className="img-responsive whatsapp-section" alt="course" />
                </a> */}
            </div>
        </>
    )
}

export default ApplicationEntry
