import React, { useState } from "react";
import { SuccessWrapper } from "../styles";

export default function Success() {
  return (
    <div className="container mt-5 mb-5 d-flex justify-content-center">
      <SuccessWrapper>
        <section className="success-wrapper">
          <i className="fa fa-check-circle fa-5x text-info"></i>
          <h2 className="text-info">
            The form has been successfully submitted.{" "}
          </h2>
          <p>We'll get back to you very soon.</p>
        </section>
      </SuccessWrapper>
    </div>
  );
}
