import React from 'react';
import ourimpactIMG from '../../assets/images/ourimpactIMG.jpg';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';

export default function Clients() {
    return (
        <div className="rs-patter-section style2 pt-20">
            <div className="container custom">
                <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-ipad-device={3} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png"  alt="" />
                        </a>
                    </div>
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png"  alt="" />
                        </a>
                    </div>
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png"  alt="" />
                        </a>
                    </div>
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png"  alt="" />
                        </a>
                    </div>
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png"  alt="" />
                        </a>
                    </div>
                    <div className="logo-img">
                        <a>
                            <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/6.png"  alt="" />
                            <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/6.png"  alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}