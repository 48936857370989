import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function MicrosoftAzure() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img1 machine-learning-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            Microsoft Azure. The innovate everywhere and create anything cloud
                            <span className="watermark text-right">AZURE</span>
                        </h1>
                        <span className="sub-text">Microsoft Azure is a public cloud platform with more than 200 products and many services accessible over the internet.
                        </span>
                        <p className="sub-text">Since 2008, Microsoft Azure has grown to become the 2nd largest of the top three public cloud platforms. Just behind market leader Amazon Web Services (AWS) and ahead of Google Cloud Platform (GCP).
                        </p>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}

            {/* Services Section Start */}
            <div className="rs-services style6">
                <div className="container custom">
                    <div className="services-box-area bg20">
                        <div className="row margin-0">
                            <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                                <div className="services-item">
                                    <div className="services-icon">
                                        <img className="dance_hover" src="assets/images/services/style6/icon-box/1.png" alt="Services" />
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title"><a href="">Trust cloud</a></h3>
                                        <p className="margin-0">Get full security from the ground up, backed by a team of experts, and proactive compliance trusted by governments and startups.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                                <div className="services-item">
                                    <div className="services-icon">
                                        <img className="dance_hover" src="assets/images/services/style6/icon-box/2.png" alt="Services" />
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title"><a href="">Use hybrid</a></h3>
                                        <p className="margin-0">On-premises, across multiple clouds, and at the edge. we’ll meet you where you are. Integrate and manage your environments with services designed for hybrid cloud.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                                <div className="services-item">
                                    <div className="services-icon">
                                        <img className="dance_hover" src="assets/images/services/style6/icon-box/3.png" alt="Services" />
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title"><a href="">Build on terms</a></h3>
                                        <p className="margin-0">With a commitment to open source, and support for all languages and frameworks, build how you want, and deploy where you want to.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                                <div className="services-item no-border">
                                    <div className="services-icon">
                                        <img className="dance_hover" src="assets/images/services/style6/icon-box/4.png" alt="Services" />
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title"><a href="">The Future ready</a></h3>
                                        <p className="margin-0">Continuous innovation from Microsoft supports your development today, and your product visions for tomorrow for sure.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}

            {/* About Section Start */}
            <div id="rs-about" className="rs-about style4 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-12 pr-40 md-pr-15 mt-45 md-mt-0 md-mb-50">
                            <div className="sec-title3">
                                <span className="sub-text">Advantages</span>

                                <div className="pb-25">
                                    <h2 className="title pb-25">
                                        How Cloud Computing With Azure Can Benefit Your Business
                                    </h2>
                                    <div className="desc pb-17">
                                    The technological revolutions just keep coming, and modern organizations need to adapt to new ways of doing business to remain competitive.
                                    </div>
                                    <p className="margin-0">
                                    One of the primary changes to the operational models a company needs to consider when it comes to managing information is whether to move to a cloud service provider.
                                    Microsoft Azure Cloud Services delivers a comprehensive suite of solutions for companies to accelerate their digital transformation initiatives, and leverage new technologies for the business’ benefit.

                                    </p>
                                </div>

                                <div className="pb-25">
                                    <h2 className="title pb-25">
                                    Azure Cloud Services and Data Security
                                    </h2>
                                    <div className="desc pb-17">
                                    Microsoft leverages decades of being a primary enterprise operating system and productivity solution provider to secure all information hosted in their Azure cloud products and services.
                                    </div>
                                    <p className="margin-0">
                                    An enormous amount of resources and effort go into securing an organization’s on-site data centers, where the slightest erroneous configuration can expose the company to advanced threats and exploits.
                                    With Microsoft Azure, all elements related to information security come included with the service, and customers gain access to the latest in threat prevention and detection technologies.
                                    </p>
                                </div>

                                <div className="pb-25">
                                    <h2 className="title pb-25">
                                    Microsoft Azure Cloud Security Center

                                    </h2>
                                    <div className="desc pb-17">
                                    As part of the Microsoft Azure Cloud Security Center, a range of security solutions and architecture implementations ensure all business data remains protected.


                                    </div>
                                    <p className="margin-0">
                                    These include Advanced Threat Protection and Threat Intelligence reports using Windows Defender analytics, and Information Protection that classifies and protects specific information from unauthorized disclosure by applying labels to documents and emails.
                                    Similarly, with network segmentation and encryption, every transmitted byte of data remains secure from any outside threat. Microsoft Azure Cloud Services uses threat monitoring agents that generate real-time security alerts and threat intelligence reports. These threat intelligence reports provide insights into attackers, their objectives, and the tactics employed.



                                    </p>
                                </div>

                                <div className="pb-25">
                                    <h2 className="title pb-25">
                                    Improved Scalability and Business Sustainability

                                    </h2>
                                    <div className="desc pb-17">
                                    One of the best advantages of Microsoft Azure Cloud Services comes from the scalability that the platform provides businesses.


                                    </div>
                                    <p className="margin-0">
                                    Traditionally, as companies expanded and grew, provisioning new infrastructure for them would be both a lengthy and a costly process for the organization.

                                    In the last year, Azure averaged an uptime 99.995% across its cloud infrastructure—meaning a total downtime of 26 minutes per year, worldwide. Microsoft plans to improve this number by adding more servers to its regions by 2021.

                                    For data management systems, Microsoft Azure Cloud Services use elastic pools to ensure the same database performance levels that companies expect from their locally hosted systems. With elastic SQL database pools, organizations gain the best price-per-performance benefits while staying within their prescribed budgets.


                                    </p>
                                </div>

                                <div className="pb-25">
                                    <h2 className="title pb-25">
                                    Simple and Effective Disaster Recovery

                                    </h2>
                                    <div className="desc pb-17">
                                    Data loss presents a serious risk to any organization. The impact of both lost revenue during the downtime, as well as the effort required to reconstitute the information can cost a company hundreds of thousands, if not millions, if a suitable, business continuity procedure isn’t already in place.


                                    </div>
                                    <p className="margin-0">
                                    A disaster recovery plan requires multiple built-in redundancies while achieving an acceptable time-to-recovery objective.

                                    With Microsoft Azure, companies can enforce specific recovery criteria for different BUs (Business Units) and types of data. Businesses can test their plans for efficacy as frequently as required, and find the perfect balance between the cost and control of their data recovery processes.

                                    Running backups and advanced replication of applications in a cloud environment will reduce the time required to recover from any type of outage, while also limiting the risk of becoming a victim of a ransomware attack.

                                    The Azure Site Recovery tool allows companies to create recovery plans that include replication, failover, and failback procedures from a single location. Azure Site Recovery also enables companies to reserve IP addresses for applications, establish and configure load-balancers, and integrate Azure Traffic Manager for seamless network switchovers.


                                    </p>
                                </div>

                                <div className="btn-part mt-45">
                                    <a className="readon consultant discover orange-more" href="">Discover More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="about-img">
                                <img src="assets/images/about/home4/about-1.png" alt="images" />
                                <div className="animations one">
                                    <img className="dance2" src="assets/images/about/home4/1.png" alt="About" />
                                </div>
                                <div className="animations two">
                                    <img className="scale" src="assets/images/about/home4/2.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* Counter Section Start */}
            <div className="rs-counter style1 counter-home5-style bg25 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 md-mb-30">
                    <div className="counter-area">
                        <div className="counter-list mb-25">
                        <div className="counter-icon">
                            <img src="assets/images/counter/icons/azure/icon-1.svg" alt="Counter" />
                        </div>
                        <div className="counter-number">
                            <span className="rs-count">$1 Billion </span>
                        </div>
                        </div>
                        <div className="content-part">
                        <h5 className="title">Investment per year in security to protect users data </h5>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                    <div className="counter-area">
                        <div className="counter-list mb-25">
                        <div className="counter-icon">
                            <img src="assets/images/counter/icons/azure/icon-2.svg" alt="Counter" />
                        </div>
                        <div className="counter-number">
                            <span className="rs-count">95%</span>
                            <span className="prefix"></span>
                        </div>
                        </div>
                        <div className="content-part">
                        <h5 className="title">of top 500 companies trust their business on Microsoft Azure.</h5>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 sm-mb-30">
                    <div className="counter-area">
                        <div className="counter-list mb-25">
                        <div className="counter-icon">
                            <img src="assets/images/counter/icons/azure/icon-3.svg" alt="Counter" />
                        </div>
                        <div className="counter-number">
                            <span className="rs-count">90</span>
                            <span className="prefix">+</span>
                        </div>
                        </div>
                        <div className="content-part">
                        <h5 className="title">compliance offerings the top portfolio in the IT industry.</h5>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Counter Section End */}

            {/* Services Section Start */}
            <div id="rs-services" className="rs-services main-home services-style1 home-4-style bg7 pt-95 pb-100 md-pt-70 md-pb-65">
                <div className="container">
                    <div className="sec-title3 text-center mb-65 md-mb-45">
                        <span className="sub-title">Valued Services</span>
                        <h2 className="title pb-25">
                            What is Microsoft Azure Cloud? &amp; <br /> Benefits of Microsoft Azure Cloud
                        </h2>
                        <div className="heading-border-line" />
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-65">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/1.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a>Azure Compute</a></h2>
                                    <p className="services-txt"> This service group empowers companies to quickly provision Linux and Windows virtual machines, achieve high availability through autoscaling and supercharge app development using a serverless architecture.</p>
                                    <div className="serial-number">
                                        01
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-65">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/2.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a>Azure Networking</a></h2>
                                    <p className="services-txt">This feature set allows organizations to create hybrid computing frameworks that utilize both private and public cloud infrastructure. By doing so, users can seamlessly deliver their custom applications to thousands of virtual machines.</p>
                                    <div className="serial-number">
                                        02
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-65">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/3.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a>Azure Storage</a></h2>
                                    <p className="services-txt">Microsoft’s virtual datacenters provide companies with agile connectivity and scalable infrastructure, eliminating the need for on-premises hardware. All data written to Azure is encrypted and accessible over HTTP or HTTPS.</p>
                                    <div className="serial-number">
                                        03
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 md-mb-65">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/4.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a>Azure Analytics</a></h2>
                                    <p className="services-txt"> Azure supercharges business decision-making through its advanced analytics services, which offer unrivaled time to insight. Users can take advantage of a fully managed cloud Hadoop or Spark environment with real-time data stream processing.</p>
                                    <div className="serial-number">
                                        04
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 sm-mb-65">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/5.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a>Azure Backup</a></h2>
                                    <p className="services-txt"> This group of services simplifies data recovery, allowing users to back up virtual machines, SQL workloads and on-premises VMware with a single click. Through Azure, companies can quickly restore data using VSS snapshot or fsfreeze without fear of application consistency.</p>
                                    <div className="serial-number">
                                        05
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/services/style6/6.png" alt="Services" />
                                </div>
                                <div className="services-text">
                                    <h2 className="title"><a href="">Azure Disaster Recovery</a></h2>
                                    <p className="services-txt">Microsoft helps keep critical applications running during planned and unplanned outages through its built-in disaster recovery tools. Users can replicate, failover and recover processes as needed, while also minimizing downtime by sequencing multi-tier applications running on multiple virtual machines.</p>
                                    <div className="serial-number">
                                        06
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            {/* Project Section Start */}
            <div id="rs-project" className="rs-project home4-responsive">
                <div className="row">
                    <div className="col-lg-7 padding-0">
                        <div className="images-part">
                            <img src="assets/images/pages/azure/1.jpg" className='w-100' alt="Portfolio" />
                        </div>
                    </div>
                    <div className="col-lg-5 padding-0">
                        <div className="features-content">
                            <div className="sec-title3 mb-35 md-mb-25">
                                <span className="sub-text">Benefits</span>
                                <h2 className="title pb-25">
                                Top Business Benefits of Microsoft Azure Cloud

                                </h2>
                              
                            </div>
                            <ul className="rs-features-list">
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">On-demand scalability</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">No on-site hardware required</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">Cost-effective subscription models</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">High availability</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">Enterprise-level development tools</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">World-class cybersecurity</span>
                                </li>
                                <li>
                                    <i className="fa fa-check" />
                                    <span className="feature-text">Advanced compliance features</span>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Project Section End */}

        </div>
    )
}