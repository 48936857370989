import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function Traverse() {
    return (
        <div className="article-page__container container">
            <h1>What is Traverse Supported Infrastructure?</h1>
            <article className="article clearfix">
                <div className="article__body markdown">
                    <p className="wysiwyg-text-align-left">Traverse is an network monitoring software with integrated performance monitoring capabilities, business service management and this has a scalable and extensible architecture, can be deployed within few hours and requires training to use. It supports all monitoring network devices, hosting servers, many applications, external and internal storage, environmental and more.</p>
                    <ol>
                        <li><a>Cloud and Virtualization</a></li>
                        <li><a>Applications</a></li>
                        <li><a>Databases</a></li>
                        <li><a>Networks</a></li>
                        <li><a>Wireless</a></li>
                        <li><a>Servers</a></li>
                        <li><a>Storage (SAN, NAS)</a></li>
                        <li><a>Firewalls</a></li>
                        <li><a>Datacenter Power &amp; Cooling</a></li>
                        <li><a>Voice Over IP</a></li>
                        <li><a>NCM Support</a></li>
                    </ol>
                    <h3>1.&nbsp;&nbsp; Cloud and Virtualization</h3>
                    <p>Traverse supports monitoring of both public and private cloud infrastructures with a seamless, single pane across CloudStack, OpenStack, VMware, Xen, HyperV &amp; Redhat KVM. Some of the Cloud infrastructure that Traverse supports include:</p>
                    <ul>
                        <li>CloudStack, OpenStack</li>
                        <li>AWS, Google Apps, Rackspace</li>
                        <li>VMware, vCenter</li>
                        <li>Xen, XenCenter, Xen Virtual Clusters</li>
                        <li>HyperV</li>
                        <li>UCS, EMC, Vblock</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; AWS</strong></p>
                    <ul>
                        <li>Database Available Storage Space</li>
                        <li>Database Connections</li>
                        <li>Database Freeable Memory</li>
                        <li>Database Read/Write IOPS</li>
                        <li>Database Read/Write Latency</li>
                        <li>Database Read/Write Throughput</li>
                        <li>Database Swap Usage</li>
                        <li>Instance CPU Utilization</li>
                        <li>Instance Disk Read</li>
                        <li>Instance Disk Write</li>
                        <li>Instance Network In</li>
                        <li>Network Out</li>
                        <li>Volume Average Write Latency</li>
                        <li>Volume Idle Time</li>
                        <li>Volume Read Throughput</li>
                        <li>Volume Write Bandwidth</li>
                        <li>Volume Write Throughput</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco UCS</strong></p>
                    <ul>
                        <li>Array Status</li>
                        <li>Blade Memory</li>
                        <li>Blade Temperature</li>
                        <li>Component Operability</li>
                        <li>CPU Load</li>
                        <li>CPU Status</li>
                        <li>Disk Status</li>
                        <li>Equipment Power</li>
                        <li>Equipment Status</li>
                        <li>Equipment Temperature</li>
                        <li>Fabric Extender Status</li>
                        <li>Fabric Extender Temperature</li>
                        <li>Fan Speed</li>
                        <li>Fan Status</li>
                        <li>Logical Disk Status</li>
                        <li>Network Interface Errors</li>
                        <li>Network Interface Packets</li>
                        <li>Network Interface Traffic</li>
                        <li>Physical Memory Available</li>
                        <li>Pool Utilization: Compute</li>
                        <li>Pool Utilization: Fiber Channel Initiator</li>
                        <li>Pool Utilization: IP Address</li>
                        <li>Pool Utilization: MAC Address</li>
                        <li>Power Supply Current</li>
                        <li>Power Supply Draw</li>
                        <li>Power Supply Voltage</li>
                        <li>Processor Status</li>
                        <li>PSU Status</li>
                        <li>RAID Battery Status</li>
                        <li>Resource Pool Utilization</li>
                        <li>Storage Controller Status</li>
                        <li>System Uptime</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Citrix Xen</strong></p>
                    <ul>
                        <li>Host: CPU Utilization</li>
                        <li>Host: Network Traffic Volume</li>
                        <li>Host: Physical Memory Utilization</li>
                        <li>Host: Storage Resource Utilization</li>
                        <li>Host: System Uptime</li>
                        <li>VM: CPU Utilization</li>
                        <li>VM: Disk I/O Volume</li>
                        <li>VM: Network Traffic Volume</li>
                        <li>VM: Physical Memory Utilization</li>
                    </ul>
                    <p><strong>&nbsp; &nbsp;&nbsp;CloudStack</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Physical Memory Utilization</li>
                        <li>Primary Storage Allocated</li>
                        <li>Primary Storage Used</li>
                        <li>Private IPs</li>
                        <li>Public IPs</li>
                        <li>Secondary Storage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; HyperV</strong></p>
                    <ul>
                        <li>VM Health status</li>
                        <li>Hypervisor Logical Processor Count</li>
                        <li>Hypervisor Virtual Processor Count</li>
                        <li>Hypervisor Partition count</li>
                        <li>Hypervisor Total Pages</li>
                        <li>Hypervisor Processor Guest Run Time</li>
                        <li>Hypervisor Processor Idle Time</li>
                        <li>Hypervisor Root Processor Run Time</li>
                        <li>Hypervisor Root Processor Idle Time</li>
                        <li>Hypervisor Partition GPA Page count</li>
                        <li>Hypervisor Partition Deposited Pages</li>
                        <li>VM Vid Partition Physical Page allocation</li>
                        <li>Legacy Network Adapter Bytes Sent</li>
                        <li>Legacy Network Adapter Bytes Received</li>
                        <li>Legacy Network Adapter Bytes Dropped</li>
                        <li>Virtual Network Adapter Bytes Sent</li>
                        <li>Virtual Network Adapter Bytes Received</li>
                        <li>Virtual Network Adapter Bytes Dropped</li>
                        <li>VM Processors</li>
                        <li>VM CPU Utilization</li>
                        <li>VM Pages</li>
                        <li>VM Traffic</li>
                        <li>VM Memory</li>
                        <li>VM Context Switches</li>
                        <li>VM File IO</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS Virtual Server</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Disk I/O</li>
                        <li>Heartbeats Received</li>
                        <li>Network I/O</li>
                        <li>Memory Utilization</li>
                        <li>System Uptime</li>
                        <li>Virtual Network: Traffic Dropped</li>
                        <li>Virtual Network: Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; VMware</strong></p>
                    <ul>
                        <li>Datastore Space Available</li>
                        <li>NIC Status</li>
                        <li>Overall CPU Utilization</li>
                        <li>Overall Datacenter Health Status</li>
                        <li>VM HBA disk Data Transfer In</li>
                        <li>VM HBA disk Data Transfer out</li>
                        <li>VM Network Traffic In</li>
                        <li>VM Network Traffic Out</li>
                        <li>Host: Battery</li>
                        <li>Host: CPU Idle Time</li>
                        <li>Host: CPU Utilization</li>
                        <li>Host: Cables/Interconnect</li>
                        <li>Host: Chassis</li>
                        <li>Host: Cooling Device</li>
                        <li>Host: Cooling Device Status</li>
                        <li>Host: Datastore Total Read Latency</li>
                        <li>Host: Datastore Total Write Latency</li>
                        <li>Host: Disk Space Utilization</li>
                        <li>Host: Overall System Health Status</li>
                        <li>Host: Physical Memory Utilization</li>
                        <li>Host: Physical NIC Status</li>
                        <li>Host: Power</li>
                        <li>Host: Processors</li>
                        <li>Host: Storage</li>
                        <li>Host: Swap Space Activity Rate</li>
                        <li>Host: System Uptime</li>
                        <li>Host: Temperature Sensor</li>
                        <li>Host: Temperature Sensor Status</li>
                        <li>Host: Traffic Volume</li>
                        <li>Host: Virtual Memory Available</li>
                        <li>Host: Virtual Memory Utilization</li>
                        <li>Host: Voltage</li>
                        <li>Host: Watchdog</li>
                        <li>Virtual Machine: CPU Ready Time</li>
                        <li>Virtual Machine: CPU Utilization</li>
                        <li>Virtual Machine: Disk Space Available</li>
                        <li>Virtual Machine: Guest Uptime</li>
                        <li>Virtual Machine: Memory Balloon</li>
                        <li>Virtual Machine: Operational Status</li>
                        <li>Virtual Machine: Physical Memory Utilization</li>
                        <li>Virtual Network: Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Vmware ESX</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Memory Utilization</li>
                        <li>VM HBA Data Transfer</li>
                        <li>VM Network Traffic</li>
                    </ul>
                    <h1>&nbsp;</h1>
                    <h3>2.&nbsp;&nbsp; Applications</h3>
                    <p>Application monitoring using Traverse's intelligence is much more than legacy application monitoring. While monitoring things like CPU speed, response times, and a number of threads are all important measurement components - taken in isolation, none of these metrics show management the difference between an 80% slowdown resulting in a 20% reduction in the revenue generation rate of an eCommerce system, versus a 20% slowdown resulting in an 80% drop in revenue generation! This is a type of business-impacting information that is easy to obtain using Traverse for proactive application monitoring. Some of the applications Traverse supports include:</p>
                    <ul>
                        <li>AD, DHCP, DNS, Exchange, IIS, SQL Server</li>
                        <li>Active MQ, JMX, JBoss, Jetty, Tomcat, Weblogic</li>
                        <li>Hadoop, Oracle, Lotus Notes</li>
                        <li>Blackberry BES, Citrix Metraframe, Remedy</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; Active Directory</strong></p>
                    <ul>
                        <li>Address Book Searches</li>
                        <li>Address Book Sessions</li>
                        <li>Authentications (Kerberos)</li>
                        <li>Authentications (NTLM)</li>
                        <li>Client Binds</li>
                        <li>Intra-DC Binds</li>
                        <li>LDAP Active Connections</li>
                        <li>LDAP New Connections</li>
                        <li>LDAP Searches</li>
                        <li>LDAP Threads</li>
                        <li>LDAP Writes</li>
                        <li>Membership Evaluations (Domain Local)</li>
                        <li>Membership Evaluations (Transitive)</li>
                        <li>Membership Evaluations (Universal)</li>
                        <li>Notify Queue</li>
                        <li>Password Changes</li>
                        <li>Reads</li>
                        <li>Replication (Total) Objects In</li>
                        <li>Replication (Total) Objects Out</li>
                        <li>Replication Objects Applied</li>
                        <li>Replication Queue</li>
                        <li>Replication Traffic In</li>
                        <li>Replication Traffic Out</li>
                        <li>Total Searches</li>
                        <li>User Create Attempts</li>
                        <li>Users Created</li>
                        <li>Writes</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; ActiveMQ</strong></p>
                    <ul>
                        <li>Broker Consumers</li>
                        <li>Broker Dequeues</li>
                        <li>Broker Enqueues</li>
                        <li>Broker Memory Util</li>
                        <li>Broker Messages</li>
                        <li>Broker Store Util</li>
                        <li>Broker Temp Util</li>
                        <li>Broker Memory Util</li>
                        <li>Broker Message Counter</li>
                        <li>Broker Store Util</li>
                        <li>Broker Temp Util</li>
                        <li>Memory Util</li>
                        <li>Queue Cursor Memory</li>
                        <li>Queue Dequeue Rate</li>
                        <li>Queue Dispatch Rate</li>
                        <li>Queue Enqueue Rate</li>
                        <li>Queue Expired Rate</li>
                        <li>Queue In Flight Rate</li>
                        <li>Queue Memory Util</li>
                        <li>Queue Size</li>
                        <li>Queue Cursor Memory</li>
                        <li>Queue Cursor Memory Util</li>
                        <li>Queue Rate</li>
                        <li>Queue Size</li>
                        <li>Topic Dequeue Rate</li>
                        <li>Topic Dispatch Rate</li>
                        <li>Topic Enqueue Rate</li>
                        <li>Topic Expire Rate</li>
                        <li>Topic Memory Util</li>
                        <li>Topic Size</li>
                        <li>Topic Rate</li>
                        <li>Topic Size</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Blackberry Enterprise Server</strong></p>
                    <ul>
                        <li>Number Of Calendar Events</li>
                        <li>Number Of Email Reconcile Events</li>
                        <li>Number Of Handheld Messages</li>
                        <li>Number Of Handheld More Requests</li>
                        <li>Number Of Messages Expired</li>
                        <li>Number Of Messages Filtered (Global)</li>
                        <li>Number Of Messages Filtered (User)</li>
                        <li>Number Of Messages Non-Deliverable</li>
                        <li>Number Of Messages Pending Delivery</li>
                        <li>SRP Host Connection Status</li>
                        <li>SRP Host Disconnect Duration</li>
                        <li>SRP Host Reconnect Attempts (Failed)</li>
                        <li>SRP Host Reconnect Attempts (Successful)</li>
                        <li>Total Message Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Citrix MetaFrame XP</strong></p>
                    <ul>
                        <li>Citrix Application Enumarations</li>
                        <li>Citrix Application Resolution Time</li>
                        <li>Citrix Application Resolutions</li>
                        <li>Citrix Metaframe Server LoadLevel</li>
                        <li>Citrix Store/Cache Operations</li>
                        <li>Citrix Store/Cache Traffic</li>
                        <li>Citrix Zone Elections</li>
                        <li>MetaFrame Application Resolution Time</li>
                        <li>MetaFrame DataStore Reads</li>
                        <li>MetaFrame DataStore Traffic</li>
                        <li>MetaFrame DataStore Writes</li>
                        <li>MetaFrame DynStore Reads</li>
                        <li>MetaFrame DynStore Writes</li>
                        <li>MetaFrame Local Cache Reads</li>
                        <li>MetaFrame Local Cache Traffic</li>
                        <li>MetaFrame Local Cache Writes</li>
                        <li>MetaFrame Server LoadLevel</li>
                        <li>MetaFrame XP DynStore Traffic</li>
                        <li>Number Of MetaFrame Application Enumerations</li>
                        <li>Number Of MetaFrame Application Resolutions</li>
                        <li>Number of MetaFrame Zone Elections</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Coradiant Web Application</strong></p>
                    <ul>
                        <li>Average End-to-End Latency</li>
                        <li>Average Host Latency</li>
                        <li>Average Network Latency</li>
                        <li>Average SSL Latency</li>
                        <li>Average TCP Round Trip</li>
                        <li>Average Throughput</li>
                        <li>Coradiant WatchPoint - Latency</li>
                        <li>Coradiant WatchPoint - Throughput</li>
                        <li>Normalized Average Throughput</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; DHCP</strong></p>
                    <ul>
                        <li>DHCP ACK Response</li>
                        <li>DHCP Discover Requests</li>
                        <li>DHCP Lease Offer</li>
                        <li>DHCP Lease Release</li>
                        <li>DHCP Lease Request</li>
                        <li>DHCP NACK Response</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Database Generic</strong></p>
                    <ul>
                        <li>RDBMS Disk Reads</li>
                        <li>RDBMS Disk Utilization</li>
                        <li>RDBMS Disk Writes</li>
                        <li>RDBMS OutOfSpace Errors</li>
                        <li>RDBMS Page Reads</li>
                        <li>RDBMS Page Writes</li>
                        <li>RDBMS Requests</li>
                        <li>RDBMS Status</li>
                        <li>RDBMS Transaction Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Exchange Server 2007</strong></p>
                    <ul>
                        <li>Availability Requests Serviced</li>
                        <li>Average Information Store Message Delivery Time</li>
                        <li>Average Information Store RPC Latency</li>
                        <li>Average Process Time for Free/Busy Requests</li>
                        <li>Average Public Store Message Delivery Time</li>
                        <li>Number of Active Information Store Connections</li>
                        <li>Number of Active Information Store Users</li>
                        <li>Number of IMAP4 Connections In</li>
                        <li>Number of Information Store Messages In Receive Queue</li>
                        <li>Number of Information Store Messages Received</li>
                        <li>Number of Information Store Messages Sent</li>
                        <li>Number of Information Store Messages in Send Queue</li>
                        <li>Number of Information Store Slow QP Threads</li>
                        <li>Number of Information Store Slow RPC Packets</li>
                        <li>Number of Information Store Slow Search Threads</li>
                        <li>Number of Messages in Delivery Queue (Mailbox)</li>
                        <li>Number of Messages in Delivery Queue (Non-SMTP)</li>
                        <li>Number of Messages in Delivery Queue (Remote)</li>
                        <li>Number of Messages in Poison Queue</li>
                        <li>Number of Messages in Retry Queue (Mailbox)</li>
                        <li>Number of Messages in Retry Queue (Non-Smtp)</li>
                        <li>Number of Messages in Retry Queue (Remote)</li>
                        <li>Number of Messages in Submission Queue</li>
                        <li>Number of Messages in Unreachable Queue</li>
                        <li>Number of POP3 Connections In</li>
                        <li>Number of Public Store Clients Logged On</li>
                        <li>Number of Public Store Messages In Receive Queue</li>
                        <li>Number of Public Store Messages Received</li>
                        <li>Number of Public Store Messages Sent</li>
                        <li>Number of Public Store Messages in Send Queue</li>
                        <li>Number of SMTP Connections In</li>
                        <li>Number of SMTP Connections Out</li>
                        <li>Number of SMTP Messages Received</li>
                        <li>Number of SMTP Messages Sent</li>
                        <li>Outlook Web Access Average Response Time</li>
                        <li>Outlook Web Access Average Search Time</li>
                        <li>Outlook Web Access Current Unique Users</li>
                        <li>Outlook Web Access Requests</li>
                        <li>SMTP Message Traffic In</li>
                        <li>SMTP Message Traffic Out</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Exchange Server</strong></p>
                    <ul>
                        <li>Exchange Address Book Sessions</li>
                        <li>Exchange Directory Service Sessions</li>
                        <li>Exchange Directory Service Threads</li>
                        <li>Exchange IMC Active Connections</li>
                        <li>Exchange IMC Failed Connections</li>
                        <li>Exchange IMC Failed Coversions</li>
                        <li>Exchange IMC Messages</li>
                        <li>Exchange IMC Outbound Queue</li>
                        <li>Exchange IMC Rejected Connections</li>
                        <li>Exchange IMC Traffic</li>
                        <li>Exchange Information Store Connections</li>
                        <li>Exchange Information Store RPC Requests</li>
                        <li>Exchange Information Store Users</li>
                        <li>Exchange MTA Deferred Queue</li>
                        <li>Exchange MTA Messages</li>
                        <li>Exchange MTA Traffic</li>
                        <li>Exchange Private Store Message Delivery</li>
                        <li>Exchange Private Store Recv Queue</li>
                        <li>Exchange Private Store Send Queue</li>
                        <li>Exchange Public Store Message Delivery</li>
                        <li>Exchange Public Store Recv Queue</li>
                        <li>Exchange Public Store Send Queue</li>
                        <li>Exchange Server Connections</li>
                        <li>Exchange Server Traffic</li>
                        <li>Exchange Server Disk I/O</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Hadoop</strong></p>
                    <ul>
                        <li>Add Block Operations</li>
                        <li>Average Block Report Time</li>
                        <li>Average Sync Time</li>
                        <li>Block Checksum Average Time</li>
                        <li>Block Checksum Maximum Time</li>
                        <li>Block Checksum Minimum Time</li>
                        <li>Block Checksum Number Of Operations</li>
                        <li>Block Reads</li>
                        <li>Block Removes</li>
                        <li>Block Replications</li>
                        <li>Block Report Operations</li>
                        <li>Block Reports Average Time</li>
                        <li>Block Reports Maximum Time</li>
                        <li>Block Reports Minimum Time</li>
                        <li>Block Reports Number Of Operations</li>
                        <li>Block Verification Failures</li>
                        <li>Block Verifications</li>
                        <li>Block Writes</li>
                        <li>Blocks Under Replicated</li>
                        <li>Bytes Read</li>
                        <li>Bytes Written</li>
                        <li>Copy Block Average Time</li>
                        <li>Copy Block Maximum Time</li>
                        <li>Copy Block Minimum Time</li>
                        <li>Copy Block Operations Time</li>
                        <li>Corrupted Blocks</li>
                        <li>Create File Operations</li>
                        <li>DFS Disk Util</li>
                        <li>DFS Disk Util</li>
                        <li>Data Node Operations</li>
                        <li>Delete File Operations</li>
                        <li>File System Capacity Used</li>
                        <li>Files Appended</li>
                        <li>Files Created</li>
                        <li>Files Renamed</li>
                        <li>Get Block Locations</li>
                        <li>Get Listing Ops</li>
                        <li>Heart Beats Average Time</li>
                        <li>Heart Beats Maximum Time</li>
                        <li>Heart Beats Minimum Time</li>
                        <li>Heart Beats Number Of Operations</li>
                        <li>Info File Operations</li>
                        <li>Journal Transactions Batched in Sync</li>
                        <li>Max Block Report Time</li>
                        <li>Max Sync Time</li>
                        <li>Min Block Report Time</li>
                        <li>Min Sync Time</li>
                        <li>Named Node Operations</li>
                        <li>Read Block Operation Average Time</li>
                        <li>Read Block Operation Maximum Time</li>
                        <li>Read Block Operation Minimum Time</li>
                        <li>Read Block Operation Number Of Operations</li>
                        <li>Read Metadata Operation Average Time</li>
                        <li>Read Metadata Operation Maximum Time</li>
                        <li>Read Metadata Operation Minimum Time</li>
                        <li>Read Metadata Operation Number Of Operations</li>
                        <li>Reads From Local Client</li>
                        <li>Reads From Remote Client</li>
                        <li>Replace Block Operation Average Time</li>
                        <li>Replace Block Operation Maximum Time</li>
                        <li>Replace Block Operation Minimum Time</li>
                        <li>Replace Block Operation Number Of Operations</li>
                        <li>Replication Blocks Pending</li>
                        <li>Replication Blocks Scheduled</li>
                        <li>Safemode Time</li>
                        <li>Sync Operations</li>
                        <li>Total Files</li>
                        <li>Total Load</li>
                        <li>Transaction Average Time</li>
                        <li>Transaction Max Time</li>
                        <li>Transaction Min Time</li>
                        <li>Transaction Operations</li>
                        <li>Write Block Operations Average Time</li>
                        <li>Write Block Operations Maximum Time</li>
                        <li>Write Block Operations Minimum Time</li>
                        <li>Write Block Operations Number Of Operations</li>
                        <li>Writes From Local Client</li>
                        <li>Writes From Remote Client</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; IIS</strong></p>
                    <ul>
                        <li>Active Connections</li>
                        <li>Anonymous Users</li>
                        <li>Authenticated Users</li>
                        <li>File Transfer</li>
                        <li>HTTP (CGI) Operations</li>
                        <li>HTTP (GET) Operations</li>
                        <li>HTTP (HEAD) Operations</li>
                        <li>HTTP (POST) Operations</li>
                        <li>HTTP (PUT) Operations</li>
                        <li>Not Found (404) Errors</li>
                        <li>Rejected Requests</li>
                        <li>Throttled Requests</li>
                        <li>Total Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; JMX OC4</strong></p>
                    <ul>
                        <li>Fixed Wait TimeOut</li>
                        <li>Max Connections</li>
                        <li>Task Manager</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Java JVM</strong></p>
                    <ul>
                        <li>JVM Classes Loaded</li>
                        <li>JVM Garbage Collection</li>
                        <li>JVM Heap Memory Utilization</li>
                        <li>JVM Memory Pool Utilization</li>
                        <li>JVM Non-Heap Memory Utilization</li>
                        <li>JVM Threads Running</li>
                        <li>JVM Uptime</li>
                        <li>JVM Thread Intialization Rate</li>
                        <li>JVM Number of Daemon Threads</li>
                        <li>JVM Number of Live Threads</li>
                        <li>JVM Number of New Threads</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Jboss</strong></p>
                    <ul>
                        <li>In Use Connections</li>
                        <li>JBoss Cache Accesses for</li>
                        <li>JBoss Cache Hits for</li>
                        <li>JBoss Cache Util for</li>
                        <li>JBoss: Cache Operations Count</li>
                        <li>JBoss: Cache Utilization</li>
                        <li>JBoss: Connection Manager In Use Connections</li>
                        <li>JBoss: JSP Monitor Rate</li>
                        <li>JBoss: Transaction Manager Commit Rate</li>
                        <li>JBoss: Transaction Manager RollBack Rate</li>
                        <li>JBoss: Transaction Manager Transaction Rate</li>                        
                        <li>Transaction Commit Rate</li>
                        <li>Transaction Rate</li>
                        <li>Transaction RollBack Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Jetty</strong></p>
                    <ul>
                        <li>Connection Duration Rate</li>
                        <li>Connection Statistics</li>
                        <li>Connection Utilization</li>
                        <li>Number of Connection Requests</li>
                        <li>Number of Connections Accepted</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Lotus Notes</strong></p>
                    <ul>
                        <li>Active User Sessions</li>
                        <li>Active Web Sessions</li>
                        <li>Average Mail Delivery Time</li>
                        <li>Avg Mail Delivery</li>
                        <li>Buffer Pool I/O</li>
                        <li>Buffer/NSF Pool</li>
                        <li>Concurrent User Sessions</li>
                        <li>Database Buffer Control Pool</li>
                        <li>Database Buffer Pool</li>
                        <li>Database Buffer Pool</li>
                        <li>Database Cache Size</li>
                        <li>Database NIF Pool</li>
                        <li>Database NSF Pool</li>
                        <li>Databases In Cache</li>
                        <li>Databases Not In Cache</li>
                        <li>Dead (Undeliverable) Mail Messages</li>
                        <li>Disk : Util</li>
                        <li>Dropped Sessions</li>
                        <li>Initial Database Open</li>
                        <li>Mail Message Traffic</li>
                        <li>Mail Queue Size</li>
                        <li>Mail Traffic</li>
                        <li>Maximum User Limit Reached</li>
                        <li>Physical Memory Util</li>
                        <li>Queued Mail</li>
                        <li>Rejected User Sessions</li>
                        <li>Server-Side Dropped Sessions</li>
                        <li>Server-Side Transactions</li>
                        <li>Transaction Rate</li>
                        <li>Undeliverable Mail</li>
                        <li>Virtual Memory Util</li>
                        <li>Web Process Util</li>
                        <li>Web Server Sessions</li>
                        <li>Web Server Traffic</li>
                        <li>Web Server URL Failure</li>
                        <li>Web Server Utilization</li>
                        <li>Web Traffic</li>
                        <li>Web URL Failure</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS BizTalk</strong></p>
                    <ul>
                        <li>Active receive locations</li>
                        <li>Active receive threads</li>
                        <li>Active send messages</li>
                        <li>Active send threads</li>
                        <li>Documents processed</li>
                        <li>Documents processed/Sec</li>
                        <li>Documents received</li>
                        <li>Documents received/Sec</li>
                        <li>Documents resubmitted</li>
                        <li>Documents submitted/Batch</li>
                        <li>Documents suspended</li>
                        <li>Documents suspended/Sec</li>
                        <li>Documents transmitted/Batch</li>
                        <li>ID Process</li>
                        <li>Pending receive batches</li>
                        <li>Pending transmitted messages</li>
                        <li>Request/Response timeouts</li>
                        <li>Throttled receive batches</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS DNS</strong></p>
                    <ul>
                        <li>DNS Dynamic Updates Rcvd</li>
                        <li>DNS Dynamic Updates Rejected</li>
                        <li>DNS Dynamic Updates Written</li>
                        <li>DNS Notify Requests Rcvd</li>
                        <li>DNS Notify Requests Sent</li>
                        <li>DNS Recursive Queries Failed</li>
                        <li>DNS Recursive Queries Rcvd</li>
                        <li>DNS Secure Updates Failed</li>
                        <li>DNS Secure Updates Rcvd</li>
                        <li>DNS TCP Queries Rcvd</li>
                        <li>DNS TCP Queries Sent</li>
                        <li>DNS UDP Queries Rcvd</li>
                        <li>DNS UDP Queries Sent</li>
                        <li>DNS WINS Lookup/Response Rcvd</li>
                        <li>DNS WINS Lookup/Response Sent</li>
                        <li>DNS Zone Transfer Failure</li>
                        <li>DNS Zone Transfer Requests</li>
                        <li>DNS Zone Transfer Success</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS Media Service</strong></p>
                    <ul>
                        <li>AAA Errors</li>
                        <li>AAA Requests</li>
                        <li>Active Clients</li>
                        <li>Active Streams</li>
                        <li>Authentication Denied</li>
                        <li>Authentication Requests</li>
                        <li>Authorization Refused</li>
                        <li>Authorization Requests</li>
                        <li>Client Count</li>
                        <li>Live Streams</li>
                        <li>Pending Clients</li>
                        <li>Stream Errors</li>
                        <li>TCP Streams</li>
                        <li>UDP Resends</li>
                        <li>UDP Streams</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS Message Queuing</strong></p>
                    <ul>
                        <li>MSMQ Data In Journal Queue</li>
                        <li>MSMQ Data In Queue</li>
                        <li>MSMQ Messages In Journal Queue</li>
                        <li>MSMQ Messages In Queue</li>
                        <li>MSMQ Overall Messages</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS Terminal Service</strong></p>
                    <ul>
                        <li>Terminal Service Active Sessions</li>
                        <li>Terminal Service: Total Sessions</li>
                        <li>Total Sessions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MTA Generic</strong></p>
                    <ul>
                        <li>MTA Active Network Connections</li>
                        <li>MTA Disk I/O Utilization</li>
                        <li>MTA Disk Read/Write Operations</li>
                        <li>MTA Failed Connections/Conversions</li>
                        <li>MTA Load Average</li>
                        <li>MTA Messages Loops Detected</li>
                        <li>MTA Messages Transferred</li>
                        <li>MTA Messages Volume</li>
                        <li>MTA Process/Thread Count</li>
                        <li>MTA Queue Size</li>
                        <li>MTA Queue Utilization</li>
                        <li>MTA Rejected Connections</li>
                        <li>MTA Rejected Messages</li>
                        <li>MTA Group Active Connections</li>
                        <li>MTA Group Connection Errors</li>
                        <li>MTA Group Message Traffic</li>
                        <li>MTA Group Messages Rcvd</li>
                        <li>MTA Group Messages Rejected</li>
                        <li>MTA Group Messages Sent</li>
                        <li>MTA Group Queue Size</li>
                        <li>MTA Group Rejected Connections</li>
                        <li>MTA System-Wide Loops</li>
                        <li>MTA System-Wide Message Traffic</li>
                        <li>MTA System-Wide Messages Rcvd</li>
                        <li>MTA System-Wide Messages Sent</li>
                        <li>MTA System-Wide Queue Size</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; OCS</strong></p>
                    <ul>
                        <li>Average Outgoing Queue Delay</li>
                        <li>Latest DNS Query Latency</li>
                        <li>MCU Health State (Audio/Video)</li>
                        <li>MCU Health State (Web)</li>
                        <li>MCU Health State (Web)</li>
                        <li>Number of Active SIP Sessions</li>
                        <li>Number of Audio/Video Conference Sessions</li>
                        <li>Number of Audio/Video Conference Users</li>
                        <li>Number of IM Conference Sessions</li>
                        <li>Number of IM Conference Users</li>
                        <li>Number of SIP 400 Responses</li>
                        <li>Number of SIP 404 Responses</li>
                        <li>Number of SIP 482 Responses</li>
                        <li>Number of SIP 483 Responses</li>
                        <li>Number of SIP 500 Responses</li>
                        <li>Number of SIP 503 Responses</li>
                        <li>Number of SIP 504 Responses</li>
                        <li>Number of SIP Connections Refused (Server Overload)</li>
                        <li>Number of SIP Transaction Timeout</li>
                        <li>Number of SIP Transactions Processed</li>
                        <li>Number of Web Conference Sessions</li>
                        <li>Number of Web Conference Users</li>
                        <li>OCS Global Health</li>
                        <li>OCS Health State</li>
                        <li>OCS: Average SIP Operation Latency</li>
                        <li>OCS: MCU Health State</li>
                        <li>OCS: Number Of Active Conferences</li>
                        <li>OCS: Number Of Active Users/Connections</li>
                        <li>OCS: Number of SIP Errors</li>
                        <li>OCS: Number of SIP Operations</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Oracle</strong></p>
                    <ul>
                        <li>Database Aborted Transactions</li>
                        <li>Database Committed Transactions</li>
                        <li>Datafile Reads</li>
                        <li>Datafile Writes</li>
                        <li>Listener Status</li>
                        <li>Listener Status</li>
                        <li>Performance Counter</li>
                        <li>Replication Status</li>
                        <li>Replication Schema Status</li>
                        <li>SID Connections</li>
                        <li>SID Client Connections</li>
                        <li>Table Space Util</li>
                        <li>Table Status</li>
                        <li>Table Space Utilization</li>
                        <li>Table Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; PGP</strong></p>
                    <ul>
                        <li>Number Of Messages Decrypted</li>
                        <li>Number Of Messages Encrypted</li>
                        <li>Number Of Messages In Queue</li>
                        <li>Number Of Messages Processed</li>
                        <li>PGP: Messages In Queue</li>
                        <li>PGP: Messages Processed</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Printer Spooler</strong></p>
                    <ul>
                        <li>Generic Counter (WMI)</li>
                        <li>Not Ready Errors</li>
                        <li>Number Of Errors (WMI)</li>
                        <li>Number Of Jobs Active (WMI)</li>
                        <li>Number Of Jobs Printed (WMI)</li>
                        <li>Number Of Jobs Spooled (WMI)</li>
                        <li>Number Of Pages Printed</li>
                        <li>Number Of Pages Printed (WMI)</li>
                        <li>Out of Paper Errors</li>
                        <li>Volume Of Data (WMI)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Proxy Server</strong></p>
                    <ul>
                        <li>CPU Utiization</li>
                        <li>CPU Utilization</li>
                        <li>Cached Objects</li>
                        <li>Cached Objects</li>
                        <li>Client HTTP Hits</li>
                        <li>Client Requests</li>
                        <li>Client Traffic</li>
                        <li>HTTP Errors</li>
                        <li>HTTP Errors</li>
                        <li>HTTP Hits</li>
                        <li>HTTP Requests</li>
                        <li>HTTP Traffic Volume</li>
                        <li>Remote Requests</li>
                        <li>Remote Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Remedy ARS</strong></p>
                    <ul>
                        <li>API Requests Received</li>
                        <li>ARsystem Status</li>
                        <li>Active Users (Fixed Write)</li>
                        <li>Active Users (Floating Write)</li>
                        <li>Active Users (Full Fixed)</li>
                        <li>Active Users (Full Floating)</li>
                        <li>Active Users (No Write)</li>
                        <li>Active Users (Total)</li>
                        <li>CPU Usage for API Functions</li>
                        <li>CPU Usage for AR System</li>
                        <li>CPU Usage for Escalation Actions</li>
                        <li>CPU Usage for Filter Actions</li>
                        <li>CPU Usage for Internal Cache</li>
                        <li>CPU Usage for SQL Query</li>
                        <li>Escalation Actions (Log)</li>
                        <li>Escalation Actions (Notify)</li>
                        <li>Escalation Actions (Run Process)</li>
                        <li>Escalation Actions (Set Fields)</li>
                        <li>Filter Actions (Log)</li>
                        <li>Filter Actions (Message)</li>
                        <li>Filter Actions (Notify)</li>
                        <li>Filter Actions (Run Process)</li>
                        <li>Filter Actions (Set Fields)</li>
                        <li>Filters Matched</li>
                        <li>Filters Skipped</li>
                        <li>Login Failure (Incorrect Password)</li>
                        <li>Login Failure (No FT Token)</li>
                        <li>Login Failure (No Write Token)</li>
                        <li>Remedy AR System Active Users</li>
                        <li>Remedy AR System Activity</li>
                        <li>Remedy AR System CPU Utilization</li>
                        <li>Remedy AR System Login Failure</li>
                        <li>Remedy AR System Status</li>
                        <li>SQL Commands Executed</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sendmail</strong></p>
                    <ul>
                        <li>MTA Status (Sendmail)</li>
                        <li>Sendmail Load Average</li>
                        <li>Sendmail MTA Status</li>
                        <li>Sendmail Process Count</li>
                        <li>Sendmail Queue Size</li>
                        <li>Sendmail Queue Size (Total)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sophos Anti-Spam</strong></p>
                    <ul>
                        <li>Certificates Valid</li>
                        <li>Cluster Connection Status</li>
                        <li>Cluster Status</li>
                        <li>Cluster Sync Status</li>
                        <li>Cross Wired Connection</li>
                        <li>Delivery Queue Status</li>
                        <li>Incoming Queue Status</li>
                        <li>Last FTP Conf Backup Status</li>
                        <li>Last FTP Quarantine Backup Status</li>
                        <li>Last Sys Log Backup Status</li>
                        <li>Latest Update Downloaded</li>
                        <li>Latest Update Installed</li>
                        <li>License Valid</li>
                        <li>Mail Disk Usage Status</li>
                        <li>Mail LDAP Sync Status</li>
                        <li>Mail Message Store Status</li>
                        <li>Message Counts</li>
                        <li>Message Processing</li>
                        <li>Message Rate</li>
                        <li>Message delay</li>
                        <li>Message in Quarantine</li>
                        <li>Message rate</li>
                        <li>Messages Queued Rate</li>
                        <li>Node Status</li>
                        <li>Pending Reboot</li>
                        <li>Processes Status</li>
                        <li>Quarantine Summaries Status</li>
                        <li>Reboot Status</li>
                        <li>SMTP Connection Status</li>
                        <li>SMTP Host Status</li>
                        <li>SOPHOS: Email Status</li>
                        <li>SPX Encryption Status</li>
                        <li>SPX Failure Queue</li>
                        <li>SPX Queue</li>
                        <li>SPX Trial License Valid</li>
                        <li>System Load</li>
                        <li>TLS Connection Status</li>
                        <li>Update Available</li>
                        <li>Update Connection Status</li>
                        <li>Update Install Status</li>
                        <li>Upgrade Connection Status</li>
                        <li>VM Trial License Valid</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Swift MQ</strong></p>
                    <ul>
                        <li>SwiftMQ Queue Size</li>
                        <li>SwiftMQ: Queue Size</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Symantec Brightmail</strong></p>
                    <ul>
                        <li>Queue - Data Rate</li>
                        <li>Queue - Deferred Messages</li>
                        <li>Queue - Number of Connections</li>
                        <li>Queue - Number of Queued Messages</li>
                        <li>Queue - Queue Size</li>
                        <li>Redundant System Status</li>
                        <li>Blower Fan Status</li>
                        <li>CPU Internal Temperature Status</li>
                        <li>Fan Redundancy Status</li>
                        <li>Hardware Status</li>
                        <li>Internal Temperature Status</li>
                        <li>Memory Fan Status</li>
                        <li>PCI Fan Status</li>
                        <li>Power Supply Redundancy Status</li>
                        <li>Symantec Brightmail: Instance Metrics</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Tomcat JDBC</strong></p>
                    <ul>
                        <li>JDBC Pool Active Connections</li>
                        <li>JDBC Pool: Number Of Idle Connections</li>
                        <li>JDBC Pool: Number Of Pending Connections</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Tomcat</strong></p>
                    <ul>
                        <li>Apache Tomcat: New Sessions Established</li>
                        <li>Apache Tomcat: Number Of Connection Errors</li>
                        <li>Apache Tomcat: Number Of Connection Requests</li>
                        <li>Apache Tomcat: Number Of Servlet Errors</li>
                        <li>Apache Tomcat: Number Of Servlet Requests</li>
                        <li>Apache Tomcat: Thread Pool Utilization</li>
                        <li>New Sessions Established</li>
                        <li>Number Of Connection Requests</li>
                        <li>Number Of Servlet Errors</li>
                        <li>Number Of Servlet Requests</li>
                        <li>Number of Connection Errors</li>
                        <li>Thread Pool Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Traverse DGE</strong></p>
                    <ul>
                        <li>Monitor Queue Size</li>
                        <li>Number Of Actions Triggered</li>
                        <li>Number Of Actions Triggered (SLA)</li>
                        <li>Number Of Actions Triggered (Shadow)</li>
                        <li>Number Of Message Events Recorded</li>
                        <li>Number Of Pending Notifications</li>
                        <li>Number Of Tests Configured</li>
                        <li>Number Of Threshold Violations</li>
                        <li>Number Of Threshold Violations (SLA)</li>
                        <li>Number Of Threshold Violations (Shadow)</li>
                        <li>Result Queue Size</li>
                        <li>Time Since Result From DGE Extension</li>
                        <li>Traverse: [DGE] Number Of Actions Triggered</li>
                        <li>Traverse: [DGE] Number Of Items in Queue</li>
                        <li>Traverse: [DGE] Number Of Messages Recorded</li>
                        <li>Traverse: [DGE] Number Of Tests Configured</li>
                        <li>Traverse: [DGE] Number Of Threshold Violations</li>
                        <li>Traverse: [DGE] Number Of Work Units Processed</li>
                        <li>Traverse: [DGE] Time Since Result From DGE Extension</li>
                        <li>Work Units Processed</li>
                        <li>Writer Queue Size</li>
                        <li>Writer Queue Size (MessageMonitor)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Traverse MsgServer</strong></p>
                    <ul>
                        <li>Number Of Message Publish Failure</li>
                        <li>Number Of Messages Discarded (By Rule)</li>
                        <li>Number Of Messages Discarded (No Match)</li>
                        <li>Number Of Messages Discarded (Unknown Device)</li>
                        <li>Number Of Messages Published</li>
                        <li>Number Of Messages Published (Total)</li>
                        <li>Number Of Messages Received</li>
                        <li>Number Of Messages Received (Total)</li>
                        <li>Thread Status (Input Processor)</li>
                        <li>Thread Status (Message Filter)</li>
                        <li>Traverse: [MSGSVR] Number Of Messages Discarded</li>
                        <li>Traverse: [MSGSVR] Number Of Messages Published</li>
                        <li>Traverse: [MSGSVR] Number Of Messages Received</li>
                        <li>Traverse: [MSGSVR] Number Of Publishing Failure</li>
                        <li>Traverse: [MSGSVR] Thread Operational Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Traverse WebApp</strong></p>
                    <ul>
                        <li>Number Of Events De-Duplicated</li>
                        <li>Number Of Events In De-Duplication Queue</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Weblogic</strong></p>
                    <ul>
                        <li>Active EJB Instances</li>
                        <li>Active JDBC Connections (Source:xxx)</li>
                        <li>Active JMS Messages (Dest:xxx)</li>
                        <li>Active JMS Messages (Server:xxx)</li>
                        <li>Average JDBC Connection Delay</li>
                        <li>Average JDBC Connection Delay</li>
                        <li>Average Servlet Execution Time</li>
                        <li>Number Of Active EJB Instances</li>
                        <li>Number Of Active JDBC Connections</li>
                        <li>Number Of EJB Transactions</li>
                        <li>Number Of Execute Requests Pending</li>
                        <li>Number Of Execute Requests Serviced</li>
                        <li>Number Of JMS Consumers</li>
                        <li>Number Of JMS Destinations</li>
                        <li>Number Of JMS Messages Pageable</li>
                        <li>Number Of JMS Messages Pending</li>
                        <li>Number Of JMS Messages Stored</li>
                        <li>Number Of JMS Session Pools</li>
                        <li>Number Of Pending EJB Instances</li>
                        <li>Number Of Pending JDBC Connections</li>
                        <li>Number Of Servlet Invoked</li>
                        <li>Number Of Times Servlet Reloaded</li>
                        <li>EJB Transactions Committed</li>
                        <li>EJB Transactions Rolled Back</li>
                        <li>EJB Transactions Timed Out</li>
                        <li>Number Of JMS Consumers</li>
                        <li>Number Of JMS Destinations</li>
                        <li>Number Of JMS Session Pools</li>
                        <li>Pageable JMS Messages</li>
                        <li>Pending EJB Instances</li>
                        <li>Pending Execute Queue Requests</li>
                        <li>Pending JDBC Connections</li>
                        <li>Pending JMS Messages</li>
                        <li>Pending JMS Messages</li>
                        <li>Serviced Execute Queue Requests</li>
                        <li>Servlet Execution Time</li>
                        <li>Servlet Invocation</li>
                        <li>Servlet Reloaded</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Windows Security</strong></p>
                    <ul>
                        <li>Definitions</li>
                        <li>On-Access Scan</li>
                        <li>Security Center: Anti-Virus On-Access Scan</li>
                        <li>Security Center: Protection Status</li>
                        <li>Status</li>
                    </ul>
                    <h3 id="h_01FBSVJPSQHP3A706SQVNJM6ZB">3.&nbsp;&nbsp; Databases</h3>
                    <p>The database is at the core of today's critical business applications. Traverse takes the guesswork out of degraded database response times by presenting and correlating the underlying servers, disks, and database metrics in a consolidated view. Additionally, it measures the actual query response times to simulate real transactions.</p>
                    <p>While it is important to monitor the database for high availability and peak performance, it is equally important (if not more important) to monitor the impact of database performance on the supported services, ultimately ensuring that the database is always available and running at peak performance so it does not compromise the business process it supports. The key databases Traverse supports include:</p>
                    <ul>
                        <li>Microsoft SQL Server, Oracle, Sybase, DB2, MySQL Database, Postgres, Hadoop</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; MySQL</strong></p>
                    <ul>
                        <li>Commit Request</li>
                        <li>Connected Threads</li>
                        <li>Insert Request</li>
                        <li>Key Buffer Efficient</li>
                        <li>Open Files</li>
                        <li>Open Tables</li>
                        <li>Select Request</li>
                        <li>Slow Queries</li>
                        <li>Table Lock Efficiency</li>
                        <li>Total Request</li>
                        <li>Traffic In</li>
                        <li>Traffic Out</li>
                        <li>Update Request</li>
                        <li>Write Buffer Efficiency</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Oracle</strong></p>
                    <ul>
                        <li>Data File filename Physical Reads</li>
                        <li>Buffer Cache Hit Ratio</li>
                        <li>Data Dictionary Cache Hit Ratio</li>
                        <li>Data File Read Operations</li>
                        <li>Data File Write Operations</li>
                        <li>Library Cache Hit Ratio</li>
                        <li>Number Of Log Space Requests</li>
                        <li>Number Of Logged In Users</li>
                        <li>Number Of Open Cursors</li>
                        <li>Number Of Sort Operations</li>
                        <li>Number Of Table Scan Operations</li>
                        <li>Ratio of Sort Operations</li>
                        <li>Tablespace Status</li>
                        <li>Tablespace Usage</li>
                        <li>Hadoop</li>
                        <li>Add Block Operations</li>
                        <li>Average Block Report Time</li>
                        <li>Average Sync Time</li>
                        <li>Block Checksum Average Time</li>
                        <li>Block Checksum Maximum Time</li>
                        <li>Block Checksum Minimum Time</li>
                        <li>Block Checksum Number Of Operations</li>
                        <li>Block Reads</li>
                        <li>Block Removes</li>
                        <li>Block Replications</li>
                        <li>Block Report Operations</li>
                        <li>Block Reports Average Time</li>
                        <li>Block Reports Maximum Time</li>
                        <li>Block Reports Minimum Time</li>
                        <li>Block Reports Number Of Operations</li>
                        <li>Block Verification Failures</li>
                        <li>Block Verifications</li>
                        <li>Block Writes</li>
                        <li>Blocks Under Replicated</li>
                        <li>Bytes Read</li>
                        <li>Bytes Written</li>
                        <li>Copy Block Average Time</li>
                        <li>Copy Block Maximum Time</li>
                        <li>Copy Block Minimum Time</li>
                        <li>Copy Block Operations Time</li>
                        <li>Corrupted Blocks</li>
                        <li>Create File Operations</li>
                        <li>DFS Disk Util</li>
                        <li>DFS Disk Util</li>
                        <li>Data Node Operations</li>
                        <li>Delete File Operations</li>
                        <li>File System Capacity Used</li>
                        <li>Files Appended</li>
                        <li>Files Created</li>
                        <li>Files Renamed</li>
                        <li>Get Block Locations</li>
                        <li>Get Listing Ops</li>
                        <li>Heart Beats Average Time</li>
                        <li>Heart Beats Maximum Time</li>
                        <li>Heart Beats Minimum Time</li>
                        <li>Heart Beats Number Of Operations</li>
                        <li>Info File Operations</li>
                        <li>Journal Transactions Batched in Sync</li>
                        <li>Max Block Report Time</li>
                        <li>Max Sync Time</li>
                        <li>Min Block Report Time</li>
                        <li>Min Sync Time</li>
                        <li>Named Node Operations</li>
                        <li>Read Block Operation Average Time</li>
                        <li>Read Block Operation Maximum Time</li>
                        <li>Read Block Operation Minimum Time</li>
                        <li>Read Block Operation Number Of Operations</li>
                        <li>Read Metadata Operation Average Time</li>
                        <li>Read Metadata Operation Maximum Time</li>
                        <li>Read Metadata Operation Minimum Time</li>
                        <li>Read Metadata Operation Number Of Operations</li>
                        <li>Reads From Local Client</li>
                        <li>Reads From Remote Client</li>
                        <li>Replace Block Operation Average Time</li>
                        <li>Replace Block Operation Maximum Time</li>
                        <li>Replace Block Operation Minimum Time</li>
                        <li>Replace Block Operation Number Of Operations</li>
                        <li>Replication Blocks Pending</li>
                        <li>Replication Blocks Scheduled</li>
                        <li>Safemode Time</li>
                        <li>Sync Operations</li>
                        <li>Total Files</li>
                        <li>Total Load</li>
                        <li>Transaction Average Time</li>
                        <li>Transaction Max Time</li>
                        <li>Transaction Min Time</li>
                        <li>Transaction Operations</li>
                        <li>Write Block Operations Average Time</li>
                        <li>Write Block Operations Maximum Time</li>
                        <li>Write Block Operations Minimum Time</li>
                        <li>Write Block Operations Number Of Operations</li>
                        <li>Writes From Local Client</li>
                        <li>Writes From Remote Client</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Postgres</strong></p>
                    <ul>
                        <li>Database Size</li>
                        <li>Heap Blocks (Read/Hit)</li>
                        <li>Longest Running SQL Query</li>
                        <li>Maximum Idle Transaction Time</li>
                        <li>Maximum Transaction Time</li>
                        <li>Number of Active SQL Queries</li>
                        <li>Number of Connections</li>
                        <li>Number of DELETE Requests</li>
                        <li>Number of INSERT Requests</li>
                        <li>Number of Index Scans</li>
                        <li>Number of Locks</li>
                        <li>Number of Transaction Commits</li>
                        <li>Number of Transaction Rollbacks</li>
                        <li>Number of UPDATE Requests</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; SQL Server</strong></p>
                    <ul>
                        <li>Cache Hit Ratio</li>
                        <li>Lock Average Wait</li>
                        <li>Lock Deadlocks</li>
                        <li>Lock Requests</li>
                        <li>Auto Param (Failed)</li>
                        <li>Auto Param (Safe)</li>
                        <li>Auto Param (Total)</li>
                        <li>Auto Param (Unsafe)</li>
                        <li>Batch Requests</li>
                        <li>Buffer Cache Hit Ratio</li>
                        <li>Compilations</li>
                        <li>DB Log File Size</li>
                        <li>DB Log Flush Waits</li>
                        <li>DB Active Transactions</li>
                        <li>DB File Size</li>
                        <li>DB Log Cache Hit Ratio</li>
                        <li>DB Log Space Util</li>
                        <li>DB Transaction Rate</li>
                        <li>Lazy Writes</li>
                        <li>Longest Running Txn Time</li>
                        <li>Page Life Expectancy</li>
                        <li>Page Reads</li>
                        <li>Page Writes</li>
                        <li>Processes Blocked</li>
                        <li>Re-Compilations</li>
                        <li>Target Server Memory</li>
                        <li>Total Server Memory</li>
                        <li>Users Connected</li>
                        <li>Users Logging In</li>
                        <li>Users Logging Out</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sybase DB2 Ingres</strong></p>
                    <ul>
                        <li>DB Disk Reads</li>
                        <li>DB Disk Util</li>
                        <li>DB Disk Writes</li>
                        <li>DB OutOfSpace Errors</li>
                        <li>DB Page Reads</li>
                        <li>DB Page Writes</li>
                        <li>DB Query</li>
                        <li>DB Status</li>
                        <li>DB Transaction Rate</li>
                    </ul>
                    <h3>4.&nbsp;&nbsp; Networks</h3>
                    <p>Traverse is an easy-to-use network management product with integrated fault and performance monitoring capabilities. Traverse has a scalable and extensible architecture, can be deployed within an hour and requires minimal training to use. Traverse can monitor all elements of your heterogeneous network infrastructure, and some of the supported network devices include:</p>
                    <ul>
                        <li>Routers, Switches, Load balancers, Content Management Switches, WLAN Hubs, ATM &amp; Frame Relay, Nexus</li>
                        <li>Cisco, Juniper, Netscreen, Foundry, Nortel, Enterasys, Linksys, Aventail, Arrowpoint, Netrake, Alteon, Peribit</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; A10 ADC</strong></p>
                    <ul>
                        <li>Multicast Packets</li>
                        <li>A10 AX: Fan Speed</li>
                        <li>A10 AX: Number of Current Connections</li>
                        <li>A10 AX: Power Supply Status</li>
                        <li>A10 AX: Server Port Status</li>
                        <li>A10 AX: Server Status</li>
                        <li>A10 AX: Service Group Member Status</li>
                        <li>A10 AX: Service Group Status</li>
                        <li>A10 AX: System Temperature</li>
                        <li>A10 AX: Virtual Server Status</li>
                        <li>Average CPU Utilization</li>
                        <li>Average Control CPU Utilization</li>
                        <li>Average Data CPU Utilization</li>
                        <li>CPU Load</li>
                        <li>Disk Free Space</li>
                        <li>Disk Space Available</li>
                        <li>Disk Space Utilization</li>
                        <li>Lower Power Supply Status</li>
                        <li>Physical Memory Usage</li>
                        <li>Server Current Connections</li>
                        <li>Server Packets</li>
                        <li>Server Port Current Connections</li>
                        <li>Server Port Packets</li>
                        <li>Server Port Status</li>
                        <li>Server Port Traffic</li>
                        <li>Server Status</li>
                        <li>Server Traffic</li>
                        <li>Svc Grp Current Connections</li>
                        <li>Svc Grp Member Port Current Connections</li>
                        <li>Svc Grp Member Port Packets</li>
                        <li>Svc Grp Member Port Status</li>
                        <li>Svc Grp Member Port Traffic</li>
                        <li>Svc Grp Packets</li>
                        <li>Svc Grp Status</li>
                        <li>Svc Grp Traffic</li>
                        <li>System Temperature</li>
                        <li>Total Current Connections</li>
                        <li>Upper Power Supply Status</li>
                        <li>Virtual Server Current Connections</li>
                        <li>Virtual Server Packets</li>
                        <li>Virtual Server Status</li>
                        <li>Virtual Server Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Adtran NetVanta</strong></p>
                    <ul>
                        <li>Device Status</li>
                        <li>CPU Utilization</li>
                        <li>Memory Utilization</li>
                        <li>Media-Gateway Utilization</li>
                        <li>Interface QoS Map State</li>
                        <li>RTP Session Counters (Individual)</li>
                        <li>RTP Session Counters (Total)</li>
                        <li>RTP Channel Counters (Total)</li>
                        <li>ICMPTS Responder Packets In/Out</li>
                        <li>Invalid LLDP Frames Recieved</li>
                        <li>VQM Packets Lost</li>
                        <li>VQM in Poor MOS Range</li>
                        <li>Network Monitor Probe Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Alteon Load Balancer</strong></p>
                    <ul>
                        <li>Alteon Bandwidth Contract Drops</li>
                        <li>Alteon Bandwidth Contract Traffic</li>
                        <li>Alteon Chassis Monitor</li>
                        <li>Alteon Real/Virt Server Connections</li>
                        <li>Alteon Real/Virt Server Failure</li>
                        <li>Alteon Real/Virt Server Traffic</li>
                        <li>Overall CPU-A Load</li>
                        <li>Overall CPU-B Load</li>
                        <li>Physical Memory Usage</li>
                        <li>Port- CPU-A Load</li>
                        <li>Port- CPU-A Load</li>
                        <li>Port- CPU-B Load</li>
                        <li>Power Supply (Primary) Status</li>
                        <li>Power Supply (Redundant) Status</li>
                        <li>Real Addr Connections</li>
                        <li>Temperature Probe: Front Middle</li>
                        <li>Temperature Probe: Front Right</li>
                        <li>Temperature Probe: Rear Left</li>
                        <li>Temperature Probe: Rear Middle</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Altiga VPN</strong></p>
                    <ul>
                        <li>Traffic</li>
                        <li>Active Sessions (LAN-To-LAN)</li>
                        <li>Active Sessions (Management)</li>
                        <li>Active Sessions (Remote Access)</li>
                        <li>CPU- Load</li>
                        <li>Cisco VPN Session Count</li>
                        <li>Cisco VPN Session Utilization</li>
                        <li>Overall Session Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Atrica Metro Ethernet</strong></p>
                    <ul>
                        <li>Below CIR Forward Traffic Ingress</li>
                        <li>Discards Above CIR Ingress</li>
                        <li>Discards Above EIR Egress</li>
                        <li>Discards Above EIR Ingress</li>
                        <li>Discards Egress</li>
                        <li>Discards Ingress</li>
                        <li>Forwards Above CIR Egress</li>
                        <li>Forwards Above CIR Ingress</li>
                        <li>Forwards Below CIR Egress</li>
                        <li>Forwards Egress</li>
                        <li>Forwards Ingress</li>
                        <li>Power Supply /&gt;</li>
                        <li>Atrica Power Supply Status</li>
                        <li>Atrica Quality of Service Discards</li>
                        <li>Atrica Quality of Service Traffic</li>
                        <li>Atrica System Alarm</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Aventail SSL VPN</strong></p>
                    <ul>
                        <li>Authenticated Users</li>
                        <li>Aventail Authenticated Users</li>
                        <li>Aventail Log Space Utilization</li>
                        <li>Aventail Number Of Tunnel</li>
                        <li>CPU Util</li>
                        <li>Log File Util</li>
                        <li>Number Of SSL Tunnels</li>
                        <li>Physical Memory Util</li>
                        <li>Virtual Memory Util</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Barracuda Load Balancer</strong></p>
                    <ul>
                        <li>Service Count</li>
                        <li>System Load</li>
                        <li>Connections</li>
                        <li>Requests</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; BGP Routing</strong></p>
                    <ul>
                        <li>BGP FSM Transitions</li>
                        <li>BGP Peer Status</li>
                        <li>BGP Route Update Rate</li>
                        <li>BGP nbr- (AS) FSM Transitions</li>
                        <li>BGP nbr- (AS) Status</li>
                        <li>BGP nbr- (AS) Updates Rcvd</li>
                        <li>BGP nbr- (AS) Updates Sent</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco AWC</strong></p>
                    <ul>
                        <li>Wireless Associations</li>
                        <li>Wireless Clients</li>
                        <li>Wireless Clients</li>
                        <li>Wireless Decapsulation Errors</li>
                        <li>Wireless Disassociations</li>
                        <li>Wireless Duplicate Sequence</li>
                        <li>Wireless Encapsulation Errors</li>
                        <li>Wireless Neighbor AP</li>
                        <li>Wireless Nodes Roamed</li>
                        <li>Wireless Reassociations</li>
                        <li>Wireless SSID Broadcast</li>
                        <li>Wireless SSID Mismatch</li>
                        <li>Wireless WEP Key Mismatch</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Catalyst</strong></p>
                    <ul>
                        <li>CRC</li>
                        <li>Status</li>
                        <li>Traffic</li>
                        <li>Util</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco DSP</strong></p>
                    <ul>
                        <li>Active Channels</li>
                        <li>Channels In Use</li>
                        <li>Alarms</li>
                        <li>Active Channels</li>
                        <li>Card Video Resource Pool Utilization</li>
                        <li>Card Codec Template Support</li>
                        <li>Card Current State</li>
                        <li>Card DSPs Status</li>
                        <li>Card High Water Mark Resource Utilization</li>
                        <li>Card Last Reset Time (SNMP)</li>
                        <li>Card Resource Utilization</li>
                        <li>Channels In Use</li>
                        <li>Current Operational State</li>
                        <li>Last Alarm Cause</li>
                        <li>Last Alarm Time</li>
                        <li>Farm Transcode Sessions</li>
                        <li>Codec Template</li>
                        <li>Congested DSPs</li>
                        <li>DSPs for Nx64 calls</li>
                        <li>DSPs in Failed State</li>
                        <li>Last Alarm Cause</li>
                        <li>Last Alarm Time</li>
                        <li>Maximum channels</li>
                        <li>No of DSP Alarms</li>
                        <li>Normal DSPs</li>
                        <li>Total DSPs</li>
                        <li>Available Global transcode sessions</li>
                        <li>Maximum Global transcode sessions</li>
                        <li>Operational state</li>
                        <li>Available MTP sessions</li>
                        <li>Transcode sessions available</li>
                        <li>Unused MTP sessions</li>
                        <li>Unused transcode sessions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco FRU Power</strong></p>
                    <ul>
                        <li>Cisco FRU Fan Tray Status</li>
                        <li>Cisco FRU Module Status</li>
                        <li>Cisco FRU Power Status</li>
                        <li>Temperature</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Generic</strong></p>
                    <ul>
                        <li>CRC Errors</li>
                        <li>Cisco Interface Error Counter</li>
                        <li>Giants</li>
                        <li>Input Queue Drops</li>
                        <li>Multiple Collisions</li>
                        <li>Output Queue Drops</li>
                        <li>Runts</li>
                        <li>Single Collisions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Ipsec VPN</strong></p>
                    <ul>
                        <li>Cisco IPSec Tunnel Active Sessions</li>
                        <li>Cisco IPSec Tunnel Packets Dropped</li>
                        <li>Cisco IPSec Tunnel Packets Transmitted</li>
                        <li>Cisco IPSec Tunnel Status</li>
                        <li>Cisco IPSec Tunnel Traffic Volume</li>
                        <li>IPSec Phase-1 IKE Tunnel Status/&gt;</li>
                        <li>IPSec Phase-1 IKE Tunnel Traffic</li>
                        <li>IPSec Phase-1 IKE Tunnel Count</li>
                        <li>IPSec Phase-1 IKE Tunnel Packet Drops</li>
                        <li>IPSec Phase-1 IKE Tunnel Packets</li>
                        <li>IPSec Phase-1 IKE Tunnel Traffic</li>
                        <li>IPSec Phase-2 Tunnel Count</li>
                        <li>IPSec Phase-2 Tunnel Packet Drops</li>
                        <li>IPSec Phase-2 Tunnel Packets</li>
                        <li>IPSec Phase-2 Tunnel Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco LW Access Point</strong></p>
                    <ul>
                        <li>Admin State</li>
                        <li>CPU Usage</li>
                        <li>Access Point Admin State</li>
                        <li>Access Point Coverage monitor state</li>
                        <li>Access Point Interference monitor state</li>
                        <li>Access Point LOAD monitor state</li>
                        <li>Access Point Noise monitor state</li>
                        <li>Access Point Operation Status</li>
                        <li>Access Point Receiver Load</li>
                        <li>Access Point Transmitter Load</li>
                        <li>Access Point User Count</li>
                        <li>Access Point Ethernet interface status</li>
                        <li>CPU Usage</li>
                        <li>Current Internal Temperature</li>
                        <li>Free Memory</li>
                        <li>Number of Users on SSID</li>
                        <li>Power Supply Status</li>
                        <li>Current Temperature</li>
                        <li>Free Memory</li>
                        <li>Interface Status</li>
                        <li>Operation Status</li>
                        <li>Power Status</li>
                        <li>Power Supply 1 Status</li>
                        <li>SSID User Count</li>
                        <li>Slot ID 0 Coverage State</li>
                        <li>Slot ID 0 Interference State</li>
                        <li>Slot ID 0 Load State</li>
                        <li>Slot ID 0 Noise State</li>
                        <li>Slot ID 0 Receiver Load</li>
                        <li>Slot ID 0 Transmitter Load</li>
                        <li>Slot ID 0 User Count</li>
                        <li>Uptime</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Load Balancer</strong></p>
                    <ul>
                        <li>Active Server Connections</li>
                        <li>Cisco SLB Connection Errors</li>
                        <li>Cisco SLB Failover State</li>
                        <li>Cisco SLB Forwarded Packets</li>
                        <li>Cisco SLB Real Server Connections</li>
                        <li>Cisco SLB Real Server Failure</li>
                        <li>Cisco SLB Real Server Status</li>
                        <li>Cisco SLB Server Farm Member Count</li>
                        <li>Cisco SLB Server Farm Predicator</li>
                        <li>Cisco SLB Total Open Connections</li>
                        <li>Cisco SLB Virtual Server Connections</li>
                        <li>Cisco SLB Virtual Server Failure</li>
                        <li>Cisco SLB Virtual Server Status</li>
                        <li>Connections Rejected: aclDeny</li>
                        <li>Connections Rejected: badSslFormat</li>
                        <li>Connections Rejected: l7ParseError</li>
                        <li>Connections Rejected: maxParseLen</li>
                        <li>Connections Rejected: noActiveServer</li>
                        <li>Connections Rejected: noPolicyMatch</li>
                        <li>Connections Rejected: noVirtPolicy</li>
                        <li>Connections Rejected: outOfMemory</li>
                        <li>Failed Server Connections</li>
                        <li>Failover State</li>
                        <li>Farm- Predicator</li>
                        <li>Farm- Real Servers</li>
                        <li>Idle Connections Closed</li>
                        <li>Layer-4 Connections Dropped</li>
                        <li>Layer-7 Connections Dropped</li>
                        <li>Load Balanced Packets (Assisted)</li>
                        <li>Load Balanced Packets (Unassisted)</li>
                        <li>Real- Connections</li>
                        <li>Real- Failure</li>
                        <li>Real- Status</li>
                        <li>Real- Status</li>
                        <li>Virt- Connections</li>
                        <li>Virt- Drops</li>
                        <li>Virt- Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Nexus</strong></p>
                    <ul>
                        <li>RTO</li>
                        <li>EIGRP: Retransmission Timeout</li>
                        <li>Number Of DS0s In Use</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco QOS</strong></p>
                    <ul>
                        <li>QoS Packets Dropped</li>
                        <li>QoS Packets Enqueued</li>
                        <li>QoS Dropped Packets</li>
                        <li>QoS Dropped Traffic</li>
                        <li>QoS Post-Policy Traffic</li>
                        <li>QoS Pre-Policy Packets</li>
                        <li>QoS Pre-Policy Traffic</li>
                        <li>QoS: SRAM Buffer Exhaustion</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Routers</strong></p>
                    <ul>
                        <li>Active Supervisor CPU Load</li>
                        <li>Active Supervisor Memory Util</li>
                        <li>Buffer Allocation Failure</li>
                        <li>Cisco Generic Error Counter</li>
                        <li>Cisco Memory Pool Utilization</li>
                        <li>Cisco Physical Chassis Health</li>
                        <li>Fan</li>
                        <li>Overall CPU Load</li>
                        <li>Overall Memory Util</li>
                        <li>Temp</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Stack</strong></p>
                    <ul>
                        <li>Backplane Utilization</li>
                        <li>Cisco Backplane Utilization</li>
                        <li>Cisco Module Status</li>
                        <li>Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Citrix Cloudbridge</strong></p>
                    <ul>
                        <li>App Discards</li>
                        <li>Link Discards</li>
                        <li>Accelerated Connections</li>
                        <li>Acceleration Status</li>
                        <li>Average CPU Usage</li>
                        <li>App Acceleration: Accelerated Connections</li>
                        <li>App Acceleration: Accelerated Traffic Status</li>
                        <li>App Acceleration: Bandwidth Utilization</li>
                        <li>App Acceleration: Operational Status</li>
                        <li>App Acceleration: Packets Discarded</li>
                        <li>App Acceleration: System Load</li>
                        <li>App Acceleration: System Uptime</li>
                        <li>App Acceleration: Traffic Processing Status</li>
                        <li>App Acceleration: Traffic Shaping Status</li>
                        <li>App Acceleration: Traffic Volume</li>
                        <li>LAN Traffic</li>
                        <li>LAN Util</li>
                        <li>Operating Status</li>
                        <li>QoS Link Discards</li>
                        <li>System Load</li>
                        <li>System Uptime</li>
                        <li>Traffic Mode</li>
                        <li>Traffic Shaping Status</li>
                        <li>WAN Traffic</li>
                        <li>WAN Util</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Citrix NetScaler</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Disk Usage</li>
                        <li>Load Balanced Connections</li>
                        <li>Load Balanced Response</li>
                        <li>NetScaler: Number Of Connection Errors</li>
                        <li>NetScaler: Number Of Established Connections</li>
                        <li>Number Of Established Client Connections</li>
                        <li>Number Of Established Server Connections</li>
                        <li>Physical Memory Usage</li>
                        <li>Physical Server Status</li>
                        <li>TCP Connections Rejected (MSS Mismatch)</li>
                        <li>TCP Connections Rejected (Sequence Mismatch)</li>
                        <li>TCP Connections Rejected (Sequence Mismatch)</li>
                        <li>TCP Connections Rejected (Signature Mismatch)</li>
                        <li>TCP Connections Rejected (Signature Mismatch)</li>
                        <li>TCP Connections Rejected (Window Seq Mismatch)</li>
                        <li>Virtual Service Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; F5 BigIP</strong></p>
                    <ul>
                        <li>Chassis Fan Speed</li>
                        <li>Chassis Temperature</li>
                        <li>Client Side Packets Transmitted</li>
                        <li>Client Side Traffic Volume</li>
                        <li>Configuration Sync Status</li>
                        <li>Data Center Connections</li>
                        <li>Data Center Link Traffic Volume</li>
                        <li>Data Center Packets Transmitted</li>
                        <li>Data Center Traffic Volume</li>
                        <li>Environmental Status</li>
                        <li>Fail over status</li>
                        <li>Fail over status of system</li>
                        <li>Global Virtual Server Connections</li>
                        <li>Global Virtual Server Packets Transmitted</li>
                        <li>Global Virtual Server Traffic Volume</li>
                        <li>Number Of Client Side Connections</li>
                        <li>Number Of Server Side Connections</li>
                        <li>Overall Client Side Connections</li>
                        <li>Overall Client Side Packets</li>
                        <li>Overall Client Side Traffic</li>
                        <li>Overall Server Side Connections</li>
                        <li>Overall Server Side Packets</li>
                        <li>Overall Server Side Traffic</li>
                        <li>Pool Connections</li>
                        <li>Pool Packets Transmitted</li>
                        <li>Pool Status</li>
                        <li>Pool Traffic Volume</li>
                        <li>Server Side Packets Transmitted</li>
                        <li>Server Side Traffic Volume</li>
                        <li>Speed</li>
                        <li>Status</li>
                        <li>Sync status of system</li>
                        <li>Temperature</li>
                        <li>Virtual Server Connections</li>
                        <li>Virtual Server Packets Transmitted</li>
                        <li>Virtual Server Status</li>
                        <li>Virtual Server Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Fiber Channel</strong></p>
                    <ul>
                        <li>Invalid CRC</li>
                        <li>Link Failures</li>
                        <li>Link Resets</li>
                        <li>Signal Losses</li>
                        <li>Status</li>
                        <li>Sync Losses</li>
                        <li>Fiber Channel: Physical Port Status</li>
                        <li>Fiber Channel: Port Discard Counters</li>
                        <li>Fiber Channel: Port Error Counters</li>
                        <li>Fiber Channel: Port Frame Counters</li>
                        <li>Fiber Channel: Port Traffic Counters</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Force10</strong></p>
                    <ul>
                        <li>Memory Util</li>
                        <li>CPU- Load</li>
                        <li>CRC In</li>
                        <li>Card Fault Status</li>
                        <li>Card Status</li>
                        <li>Card Temperature</li>
                        <li>Environmental Status</li>
                        <li>Line Card CPU- Load</li>
                        <li>Line Card- Memory Util</li>
                        <li>Power Supply Operational Status</li>
                        <li>SFM Operational Status</li>
                        <li>SFM Slot Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Foundry</strong></p>
                    <ul>
                        <li>(and additional Router metrics)</li>
                        <li>CPU Load</li>
                        <li>Chassis Temperature</li>
                        <li>Foundry Chassis Monitor</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Frame Relay</strong></p>
                    <ul>
                        <li>Frame-Relay DLCI BECN</li>
                        <li>Frame-Relay DLCI DE</li>
                        <li>Frame-Relay DLCI Discards</li>
                        <li>Frame-Relay DLCI FECN</li>
                        <li>Frame-Relay DLCI Status</li>
                        <li>Frame-Relay DLCI Traffic</li>
                        <li>Frame-Relay DLCI Util</li>
                        <li>Frame-Relay VC Bandwidth Utilization</li>
                        <li>Frame-Relay VC Errors</li>
                        <li>Frame-Relay VC Status</li>
                        <li>Frame-Relay VC Traffic Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Interface Generic</strong></p>
                    <ul>
                        <li>Network Interface: Align/FCS Errors (SNMP)</li>
                        <li>Network Interface: Availability (SNMP)</li>
                        <li>Network Interface: Availability (WMI)</li>
                        <li>Network Interface: Bandwidth Utilization (SNMP)</li>
                        <li>Network Interface: Bandwidth Utilization (WMI)</li>
                        <li>Network Interface: CRC Errors (WMI)</li>
                        <li>Network Interface: Collisions (SNMP)</li>
                        <li>Network Interface: Frames Transmitted (SNMP)</li>
                        <li>Network Interface: Packets Discarded (SNMP)</li>
                        <li>Network Interface: Packets Discarded (WMI)</li>
                        <li>Network Interface: Packets Transmitted (SNMP)</li>
                        <li>Network Interface: Packets Transmitted (WMI)</li>
                        <li>Network Interface: Traffic Volume (SNMP)</li>
                        <li>Network Interface: Traffic Volume (WMI)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Juniper COS</strong></p>
                    <ul>
                        <li>Packets Dropped</li>
                        <li>Packets Transmit Rate</li>
                        <li>Packets Transmitted</li>
                        <li>Traffic Rate</li>
                        <li>Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Juniper Perebit</strong></p>
                    <ul>
                        <li>Application Traffic</li>
                        <li>Application Traffic Ratio</li>
                        <li>Peribit Application Traffic Percentage</li>
                        <li>Peribit Application Traffic Rate</li>
                        <li>Peribit Sequence Reducer Average Throughput</li>
                        <li>Peribit Sequence Reducer Burst Pass-Through</li>
                        <li>Peribit Sequence Reducer Burst Rate</li>
                        <li>Peribit Sequence Reducer Data Reduction</li>
                        <li>Sequence Reducer Burst Pass-Through</li>
                        <li>Sequence Reducer Burst Traffic</li>
                        <li>Sequence Reducer Data Reduction</li>
                        <li>Sequence Reducer Throughput</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Juniper Routers</strong></p>
                    <ul>
                        <li>CPU Load</li>
                        <li>Err</li>
                        <li>Packets</li>
                        <li>Physical Memory Usage</li>
                        <li>Status</li>
                        <li>Traffic</li>
                        <li>Util</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Juniper SRX</strong></p>
                    <ul>
                        <li>CP Sessions</li>
                        <li>Chassis Average Sessions Created</li>
                        <li>Chassis Current Sessions</li>
                        <li>Flow Sessions</li>
                        <li>SPU Active Sessions</li>
                        <li>SPU Average Sessions Created</li>
                        <li>SPU Memory Usage</li>
                        <li>SPU Utilization</li>
                        <li>Memory Usage</li>
                        <li>Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Kemp Load Balancer</strong></p>
                    <ul>
                        <li>VS Packets In/Out</li>
                        <li>RS Packets In/Out</li>
                        <li>VS Active Connections</li>
                        <li>RS Active Connections</li>
                        <li>VS Traffic In/Out</li>
                        <li>RS Traffic In/Out</li>
                        <li>Total Transactions</li>
                        <li>SSL Transactions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; LAN Manager</strong></p>
                    <ul>
                        <li>Failed Login Attempts</li>
                        <li>File Permission Errors</li>
                        <li>File Permission Errors</li>
                        <li>Remote Connections</li>
                        <li>Remote Network Connections</li>
                        <li>System Errors</li>
                        <li>System Errors</li>
                        <li>Windows Login Errors</li>
                        <li>Workstation I/O Response</li>
                        <li>Workstation I/O Response</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MPLS Tunnel</strong></p>
                    <ul>
                        <li>Tunnel Errors</li>
                        <li>Tunnel Local Repair Mechanism</li>
                        <li>Tunnel Path Changes</li>
                        <li>Tunnel State Transitions</li>
                        <li>Tunnel Status</li>
                        <li>Tunnel Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; McData Switch</strong></p>
                    <ul>
                        <li>Switch Address ID Errors</li>
                        <li>Switch CRC In</li>
                        <li>Switch LFx Transitions</li>
                        <li>Switch Loss of Signal</li>
                        <li>Switch Loss of Sync Timeouts</li>
                        <li>Switch Physical State</li>
                        <li>Switch Protocol Errors</li>
                        <li>Switch Status</li>
                        <li>Switch Traffic</li>
                        <li>Switch Overall Health</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Meraki</strong></p>
                    <ul>
                        <li>Cisco Meraki Associated Client Count</li>
                        <li>Cisco Meraki Device Mesh Status</li>
                        <li>Cisco Meraki Device Status</li>
                        <li>Device Status</li>
                        <li>Device associated Clients</li>
                        <li>Device mesh status</li>
                        <li>Interface Bytes</li>
                        <li>Interface Packets</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Netscreen SA</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Disk Space Utilization</li>
                        <li>NetScreen Number Of Hits</li>
                        <li>NetScreen Number of Connections</li>
                        <li>Number Of Connections (Total)</li>
                        <li>Number of Connections (Applet)</li>
                        <li>Number of Connections (File)</li>
                        <li>Number of Connections (Network Connect)</li>
                        <li>Number of Connections (Secure Application Manager)</li>
                        <li>Number of Connections (Terminal)</li>
                        <li>Number of Connections (Web)</li>
                        <li>Number of Logged-In Users</li>
                        <li>Number of Mail Users</li>
                        <li>Number of Web Users</li>
                        <li>Physical Memory Utilization</li>
                        <li>Swap Space Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; OSPF Routing</strong></p>
                    <ul>
                        <li>OSPF External LSA Count</li>
                        <li>OSPF LSA Rate</li>
                        <li>OSPF LSA Rcvd</li>
                        <li>OSPF LSA Sent</li>
                        <li>OSPF Neighbor Errors</li>
                        <li>OSPF Neighbor Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Packeteer</strong></p>
                    <ul>
                        <li>Errors</li>
                        <li>Packets</li>
                        <li>Traffic</li>
                        <li>Chassis Fan 1 Status</li>
                        <li>Class Packets</li>
                        <li>Class Traffic</li>
                        <li>Partition Packets</li>
                        <li>Partition Traffic</li>
                        <li>Power Supply 1 Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Port</strong></p>
                    <ul>
                        <li>Discards</li>
                        <li>Errors</li>
                        <li>Packets</li>
                        <li>Status</li>
                        <li>Traffic</li>
                        <li>Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; RIP Routing</strong></p>
                    <ul>
                        <li>RIP IF- Updates Sent</li>
                        <li>RIP Route Changes</li>
                        <li>RIP Route Updates</li>
                        <li>RIP nbr- Bad Routes</li>
                        <li>RIPv2 Neighbor Errors</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; RMON2</strong></p>
                    <ul>
                        <li>TCP/ Traffic</li>
                        <li>Total Traffic</li>
                        <li>UDP/ Traffic</li>
                        <li>RMON2: Layer 2 Traffic Rate</li>
                        <li>RMON2: Layer 3 Traffic Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Redline</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Cluster Active Sessions</li>
                        <li>Cluster Connections Refused</li>
                        <li>Cluster GET Requests</li>
                        <li>Cluster Health Status</li>
                        <li>Cluster POST Requests</li>
                        <li>Cluster Traffic</li>
                        <li>Physical Memory Utilization</li>
                        <li>RedLine Active Sessions/Requests</li>
                        <li>RedLine Cluster HTTP Operations</li>
                        <li>RedLine Cluster Health Status</li>
                        <li>RedLine Cluster Network Traffic</li>
                        <li>RedLine Connections Refused</li>
                        <li>RedLine Connections/Requests Processed</li>
                        <li>Total Incoming Connections Accepted</li>
                        <li>Total Incoming Connections Refused</li>
                        <li>Total Incoming Requests Processed</li>
                        <li>Total Number of Active Requests</li>
                        <li>Total Number of Active Sessions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Riverbed Steelhead</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Number Of Active Connections</li>
                        <li>Number Of Established Connections</li>
                        <li>Number Of Half-Closed Connections</li>
                        <li>Number Of Half-Opened Connections</li>
                        <li>Number Of Optimized Connections</li>
                        <li>Number Of Pass-Through Connections</li>
                        <li>Optimization Service Status</li>
                        <li>Optimized Traffic (Port- from LAN side)</li>
                        <li>Optimized Traffic (Port- from WAN side)</li>
                        <li>Optimized Traffic (Total from LAN side)</li>
                        <li>Optimized Traffic (Total from WAN side)</li>
                        <li>Pass-Through Traffic</li>
                        <li>Pass-Through Traffic (Total)</li>
                        <li>System Chassis Temperature</li>
                        <li>System Health Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; SingleWire Informacast</strong></p>
                    <ul>
                        <li>JVM: Current thread count</li>
                        <li>JVM: Memory Utilization in MB</li>
                        <li>JVM: Memory Utilization in percentage</li>
                        <li>Number of Phones Retrieved</li>
                        <li>Number of Speakers Registered</li>
                        <li>SingleWire InformaCast: JVM Status</li>
                        <li>SingleWire InformaCast: Registered Speakers</li>
                        <li>SingleWire InformaCast: Retrieved Phones</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sycamore DNX</strong></p>
                    <ul>
                        <li>Sycamore DNX Port Error Seconds</li>
                        <li>Sycamore DNX Port Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; WAN DSx</strong></p>
                    <ul>
                        <li>DSx: Number Of Bursty Errored Seconds</li>
                        <li>DSx: Number Of Controlled Slip Seconds</li>
                        <li>DSx: Number Of Degraded Minutes</li>
                        <li>DSx: Number Of Errored Seconds</li>
                        <li>DSx: Number Of Line Code Violations</li>
                        <li>DSx: Number Of Line Errored Seconds</li>
                        <li>DSx: Number Of Path Coding Violations</li>
                        <li>DSx: Number Of Severely Errored Framing Seconds</li>
                        <li>DSx: Number Of Severely Errored Seconds</li>
                        <li>DSx: Number Of Unavailable Seconds</li>
                    </ul>
                    <h3 id="h_01FBSVK50XY1JFERXH792ARGQ5">5.&nbsp;&nbsp; Wireless</h3>
                    <p>Traverse is an easy-to-use network management product with integrated fault and performance monitoring capabilities. Traverse has a scalable and extensible architecture, can be deployed within an hour and requires minimal training to use. Traverse can monitor your wireless infrastructure, and some of the supported devices include:</p>
                    <ul>
                        <li>Cisco, Linksys, Redline, DragonWave, Cyclades, Tasman, Nextnet</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; Redline AN-80</strong></p>
                    <ul>
                        <li>Wireless Link Status</li>
                        <li>Wireless Noise to Signal Ratio</li>
                        <li>Wireless Signal Strength</li>
                        <li>Wireless Uncoded Burst Rate</li>
                        <li>Wireless Discards</li>
                        <li>Wireless Link Status</li>
                        <li>Wireless Noise to Signal Ratio</li>
                        <li>Wireless Packets</li>
                        <li>Wireless Retranmissions</li>
                        <li>Wireless Signal Strength</li>
                        <li>Wireless Uncoded Burst Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; NextNet</strong></p>
                    <ul>
                        <li>GPS Clock Status</li>
                        <li>NextNet GPS Clock Status</li>
                        <li>One-Second-Pulse Status</li>
                        <li>NextNet Operational Status</li>
                        <li>AirLink Status</li>
                        <li>Chassis Temperature</li>
                        <li>Base Station Transmit Power</li>
                        <li>NextNet Base Station Transmit Power</li>
                        <li>Base Station Downlink Saturation</li>
                        <li>NextNet Base Station Link Saturation</li>
                        <li>Base Station Uplink Saturation</li>
                        <li>Number of Registered CPE</li>
                        <li>NextNet Registered CPE/Hosts</li>
                        <li>Number of Registered Hosts</li>
                        <li>VLAN Uplink Traffic</li>
                        <li>VLAN Downlink Traffic</li>
                        <li>VLAN Uplink Unicast Packets</li>
                        <li>VLAN Uplink Broadcast Packets</li>
                        <li>VLAN Downlink Unicast Packets</li>
                        <li>VLAN Downlink Broadcast Packets</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Wireless Access Point</strong></p>
                    <ul>
                        <li>Wireless: SSID Broadcast Setting</li>
                        <li>Wireless: Successful Associations</li>
                        <li>Wireless: Successful Dessociations</li>
                        <li>Wireless: Successful Ressociations</li>
                        <li>Wireless: Nodes Roamed</li>
                        <li>Wireless: Encapsulation Error</li>
                        <li>Wireless: Decapsulation Error</li>
                        <li>Wireless: Duplicate Sequence</li>
                        <li>Wireless: WEP Key Mismatch</li>
                        <li>Wireless: SSID Mismatch</li>
                        <li>Wireless: Associated Clients</li>
                        <li>Disk Space Utilization</li>
                        <li>CPU Utilization</li>
                        <li>Physical Memory Utilization</li>
                        <li>Virtual Memory Utilization</li>
                        <li>System Uptime</li>
                        <li>Network Interface: Availability</li>
                        <li>Network Interface: Bandwidth Utilization</li>
                        <li>Network Interface: Packets Transmitted</li>
                        <li>Network Interface: Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; DragonWave</strong></p>
                    <ul>
                        <li>System Operational Status</li>
                        <li>DragonWave AirPair System Status</li>
                        <li>Peer Authentication Status</li>
                        <li>DragonWave AirPair Peer Authentication Status</li>
                        <li>NIC Frames</li>
                        <li>NIC Receive Errors</li>
                        <li>NIC Undersized Frames</li>
                        <li>NIC Oversized Frames</li>
                        <li>Modem Operational Status</li>
                        <li>Radio Operational Status</li>
                        <li>Radio RX Signal Level</li>
                        <li>DragonWave AirPair RX/TX Signal Strength</li>
                        <li>Radio TX Gain</li>
                        <li>DragonWave AirPair RX/TX Gain</li>
                        <li>Radio RX Gain</li>
                        <li>Radio TX Power</li>
                        <li>Modem Loss Of Signal Lock Alarm</li>
                        <li>DragonWave AirPair Modem Alarms</li>
                        <li>Modem BER Threshold Exceeded Alarm</li>
                        <li>Modem PLL Lock Failure Alarm</li>
                        <li>Modem Loss Of Sync Bytes Alarm</li>
                        <li>Modem FIFO Over/Underrun Alarm</li>
                        <li>Modem SNR Threshold Dropped Alarm</li>
                        <li>Radio PLDRO/LO Lock Lost Alarm</li>
                        <li>DragonWave AirPair Radio Alarms</li>
                        <li>Radio RS232 Communication Lost Alarm</li>
                        <li>Radio Frequency Mismatch Alarm</li>
                        <li>Radio Power Amplifier Alarm</li>
                        <li>Radio RSL Threshold Dropped Alarm</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Ruckus ZoneDirector</strong></p>
                    <ul>
                        <li>Global Associated AP count</li>
                        <li>Global Authorized Client Devices count</li>
                        <li>Global Rogue Device count</li>
                        <li>AP Status</li>
                        <li>AP Authenticated Clients</li>
                        <li>AP Rogue Device count</li>
                        <li>AP Total Concurrent Users</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Tasman Networks</strong></p>
                    <ul>
                        <li>Traffic</li>
                        <li>Packets</li>
                        <li>Chassis Temperature</li>
                        <li>Fan Status</li>
                        <li>Environmental Status</li>
                        <li>Power Supply</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cyclades</strong></p>
                    <ul>
                        <li>Port Alarm</li>
                        <li>Cyclades ACS Port Alarm Condition</li>
                        <li>Port Traffic</li>
                        <li>Port Parity Errors</li>
                        <li>Port Overrun Errors</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Wireless LAN (WLAN)</strong></p>
                    <ul>
                        <li>Wireless SSID Broadcast</li>
                        <li>Wireless Associations</li>
                        <li>Wireless Disassociations</li>
                        <li>Wireless Reassociations</li>
                        <li>Wireless Nodes Roamed</li>
                        <li>Wireless Encapsulation Errors</li>
                        <li>Wireless Decapsulation Errors</li>
                        <li>Wireless Duplicate Sequence</li>
                        <li>Wireless WEP Key Mismatch</li>
                        <li>Wireless SSID Mismatch</li>
                        <li>Wireless Clients</li>
                        <li>Wireless Neighbor AP</li>
                    </ul>
                    <h3 id="h_01FBSVKDT61DRF30ANN3YWGFAS">6.&nbsp;&nbsp; Servers</h3>
                    <p>Most enterprises today have a variety of server platforms deployed over time. Traverse offers a unified platform to monitor and manage all your Unix, Windows, and mainframe servers. Traverse can discover and start monitoring servers, disks, and networks within an hour in an existing network. Some of the server infrastructure Traverse supports includes:</p>
                    <ul>
                        <li>Windows 2000, Windows 2003, Windows NT, Windows XP, Windows Vista</li>
                        <li>Solaris, HPUX, AIX, RedHat, Suse, Debian</li>
                        <li>Dell OpenManage, IBM mainframes, Compaq InsightManager, Compaq VMS</li>
                        <li>VMware ESX 3, Microsoft Virtual Server, Java Virtual Machine</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; AIX</strong></p>
                    <ul>
                        <li>Average CPU Utilization</li>
                        <li>Disk Utilization</li>
                        <li>FileSystem Utilization</li>
                        <li>Paging Space</li>
                        <li>Process Created</li>
                        <li>Volume Group Utilization</li>
                        <li>Blocks</li>
                        <li>Context Switches</li>
                        <li>Disk I/O</li>
                        <li>Disk Volume</li>
                        <li>IO Wait CPU Time</li>
                        <li>Idle CPU Time</li>
                        <li>Memory Swapped</li>
                        <li>Physical Memory Usage</li>
                        <li>Process Availability/Count</li>
                        <li>Process Count</li>
                        <li>Swap Space Usage</li>
                        <li>System CPU Time</li>
                        <li>System Interrupts</li>
                        <li>System Load Avg</li>
                        <li>User CPU Time</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Compaq Rack</strong></p>
                    <ul>
                        <li>Blade Server Status</li>
                        <li>Enclosure Fan Status</li>
                        <li>Enclosure Power Supply Exhaust Temperature</li>
                        <li>Enclosure Power Supply Intake Temperature</li>
                        <li>Enclosure Power Supply Status</li>
                        <li>Enclosure Temperature Sensor</li>
                        <li>Enclosure Temperature Status</li>
                        <li>Overall Health</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Compaq w/ Insight Manager</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>CPU- Status</li>
                        <li>CPU-Load</li>
                        <li>Compaq Standard Equipment Status</li>
                        <li>Disk Space Util</li>
                        <li>Disk Space Util</li>
                        <li>NIC Err</li>
                        <li>NIC Packets</li>
                        <li>NIC Status</li>
                        <li>Number of Processes</li>
                        <li>Overall CPU Fan Status</li>
                        <li>Overall System Fan Status</li>
                        <li>Physical Memory Usage</li>
                        <li>System Thermal Sensor</li>
                        <li>Virtual Memory Usage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Dell Array</strong></p>
                    <ul>
                        <li>ArrayManager Global Status</li>
                        <li>Dell ArrayManager Controller Status</li>
                        <li>Dell ArrayManager Global Status</li>
                        <li>Dell ArrayManager Physical Disk Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Dell w/ OpenManage</strong></p>
                    <ul>
                        <li>BIOS Status</li>
                        <li>CPU Load</li>
                        <li>Cooling Device Redundancy</li>
                        <li>Cooling Device Status</li>
                        <li>Dell Server Component Redundancy</li>
                        <li>Dell Server Component Status</li>
                        <li>Intrusion Sensor Status</li>
                        <li>Operating System Status</li>
                        <li>Overall Amperage Status</li>
                        <li>Overall Server Status</li>
                        <li>Physical Memory Usage</li>
                        <li>Power Supply Redundancy</li>
                        <li>Speed</li>
                        <li>Temperature</li>
                        <li>Virtual Memory Usage</li>
                        <li>Voltage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Generic</strong></p>
                    <ul>
                        <li>Blocks (To Device) Sent/Rcvd (SNMP)</li>
                        <li>CPU Idle Time (SNMP)</li>
                        <li>CPU Utilization (SNMP)</li>
                        <li>CPU Utilization (WMI)</li>
                        <li>Disk Space Utilization (SNMP)</li>
                        <li>Disk Space Utilization (WMI)</li>
                        <li>File I/O Operations (WMI)</li>
                        <li>Memory Swapped To/From Disk (SNMP)</li>
                        <li>Number of Processes (SNMP)</li>
                        <li>Number of Processes (WMI)</li>
                        <li>Number of Threads (WMI)</li>
                        <li>Number of Users (SNMP)</li>
                        <li>Number of Users (WMI)</li>
                        <li>Physical Memory Free (SNMP)</li>
                        <li>Physical Memory Utilization (SNMP)</li>
                        <li>Physical Memory Utilization (WMI)</li>
                        <li>System Context Switches (SNMP)</li>
                        <li>System Context Switches (WMI)</li>
                        <li>System Interrupts (SNMP)</li>
                        <li>System Load Average (SNMP)</li>
                        <li>Virtual Memory Free (SNMP)</li>
                        <li>Virtual Memory Utilization (SNMP)</li>
                        <li>Virtual Memory Utilization (WMI)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; HP Blade Enclosures</strong></p>
                    <ul>
                        <li>Enclosure Overall Health</li>
                        <li>Enclosure Temperature Status</li>
                        <li>Enclosure Temperature Sensor</li>
                        <li>Enclosure Fan Status</li>
                        <li>Blade Server Status</li>
                        <li>Enclosure Power Supply Status</li>
                        <li>Enclosure Power Supply Temperature</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; HPUX</strong></p>
                    <ul>
                        <li>Application/Process Count</li>
                        <li>CPU Load</li>
                        <li>Disk Space Util</li>
                        <li>Logged In Users</li>
                        <li>Physical Memory Usage</li>
                        <li>Swap Space Usage</li>
                        <li>System CPU Time</li>
                        <li>User CPU Time</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; IBM xSeries</strong></p>
                    <ul>
                        <li>ASM system health</li>
                        <li>Power on Hours</li>
                        <li>Restart Count</li>
                        <li>System Power Status</li>
                        <li>System State</li>
                        <li>User Days left for password expiry</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Netware</strong></p>
                    <ul>
                        <li>File R/W Amount</li>
                        <li>File R/W Operations</li>
                        <li>NetWare File Read/Write Amount</li>
                        <li>NetWare File Read/Write Operations</li>
                        <li>NetWare FileSystem Open Files</li>
                        <li>NetWare FileSystem Record Locks</li>
                        <li>NetWare Queue Entries</li>
                        <li>NetWare Queue Size</li>
                        <li>NetWare Server Login State</li>
                        <li>NetWare Volume Directory Table</li>
                        <li>NetWare Volume Freeable Space</li>
                        <li>Number Of Open Files</li>
                        <li>Number Of Record Locks</li>
                        <li>Number Of Users</li>
                        <li>System Login State</li>
                        <li>Volume Directory Table</li>
                        <li>Volume Freeable</li>
                        <li>Volume Space Util</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Printers</strong></p>
                    <ul>
                        <li>Capacity</li>
                        <li>Capacity</li>
                        <li>Status</li>
                        <li>Printer Status</li>
                        <li>Printer Cover Status</li>
                        <li>Printer Status</li>
                        <li>Printer Supply Capacity</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; RFC1514</strong></p>
                    <ul>
                        <li>CPU-Load</li>
                        <li>Disk Space Util</li>
                        <li>Number Of Users</li>
                        <li>Number of Processes</li>
                        <li>Physical Memory Usage</li>
                        <li>Swap Space Usage</li>
                        <li>Swap Space Usage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Redhat Cluster</strong></p>
                    <ul>
                        <li>Cluster Failed Services</li>
                        <li>Cluster Unavailable Nodes</li>
                        <li>Cluster Node Status</li>
                        <li>Cluster Overall Status</li>
                        <li>Cluster Service Status</li>
                        <li>RedHat Cluster: Node Status</li>
                        <li>RedHat Cluster: Number Of Failed Services</li>
                        <li>RedHat Cluster: Number Of Unavailable Nodes</li>
                        <li>RedHat Cluster: Overall Status</li>
                        <li>RedHat Cluster: Service Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Ricoh Printers</strong></p>
                    <ul>
                        <li>Ricoh Page Counters</li>
                        <li>Ricoh Paper Level</li>
                        <li>Ricoh Toner Level</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sun Sparc</strong></p>
                    <ul>
                        <li>Disk Space Utilization</li>
                        <li>CPU Utilization</li>
                        <li>CPU Idle Time</li>
                        <li>Physical Memory Utilization</li>
                        <li>Virtual Memory Utilization</li>
                        <li>Physical Memory Free</li>
                        <li>Virtual Memory Free</li>
                        <li>Number of Users</li>
                        <li>Number of Processes</li>
                        <li>System Load Average</li>
                        <li>Blocks (To Device) Sent/Rcvd</li>
                        <li>Memory Swapped To/From Disk</li>
                        <li>System Interrupts</li>
                        <li>System Context Switches</li>
                        <li>System Uptime</li>
                        <li>Network Interface: Availability</li>
                        <li>Network Interface: Bandwidth Utilization</li>
                        <li>Network Interface: Packets Discarded</li>
                        <li>Network Interface: Align/FCS Errors</li>
                        <li>Network Interface: Collisions</li>
                        <li>Network Interface: Packets Transmitted</li>
                        <li>Network Interface: Traffic Volume</li>
                        <li>Network Interface: Availability</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Linux Servers</strong></p>
                    <ul>
                        <li>Blocks</li>
                        <li>Context Switches</li>
                        <li>Disk I/O</li>
                        <li>Disk Operations</li>
                        <li>Disk Read/Write Operations (SNMP)</li>
                        <li>Disk Volume</li>
                        <li>IO Wait CPU Time</li>
                        <li>Idle CPU Time</li>
                        <li>Memory Swapped</li>
                        <li>Physical Memory Usage</li>
                        <li>Process Availability/Count</li>
                        <li>Process Count</li>
                        <li>Swap Space Usage</li>
                        <li>System CPU Time</li>
                        <li>System Interrupts</li>
                        <li>System Load Avg</li>
                        <li>User CPU Time</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Windows OS</strong></p>
                    <ul>
                        <li>Context Switches</li>
                        <li>Disk Space Utilization</li>
                        <li>File R/W Operations</li>
                        <li>Load</li>
                        <li>Number Of Processes</li>
                        <li>Number Of Threads</li>
                        <li>Number Of User Sessions</li>
                        <li>Physical Memory Usage</li>
                        <li>Service Status</li>
                        <li>System Uptime</li>
                        <li>Virtual Memory Usage</li>
                    </ul>
                    <h3>7.&nbsp;&nbsp; Storage (SAN, NAS)</h3>
                    <p>SAN networks and storage systems consist of many complexes distributed components such as the fiber channel network, the physical storage, and the servers. Isolating the cause for slow performance requires being able to correlate all the different elements and review historical and current performance. Traverse can monitor your storage systems and generate trend reports that predict the days to reach thresholds for disk volumes and capacity utilization across the fiber channel switches. Some of the storage infrastructure that Traverse supports includes:</p>
                    <ul>
                        <li>Brocade, Network Appliance Filers, Celerra, EMC/Clariion, Hitachi, BlueArc SAN</li>
                        <li>HP Surestore, Sun StorEdge, Dell, IBM Storage, Symmetrix, ADIC, McData</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; ADIC Tape Library</strong></p>
                    <ul>
                        <li>ADIC Tape Library Global Status</li>
                        <li>ADIC Tape Library Mover Door Status</li>
                        <li>ADIC Tape Library Mover Operations</li>
                        <li>ADIC Tape Library SCSI State</li>
                        <li>ADIC Tape Library Start-Up Status</li>
                        <li>Drive SCSI State</li>
                        <li>Library Global Status</li>
                        <li>Library Start-Up Status</li>
                        <li>Mover # Door Status</li>
                        <li>Mover # Load Retries</li>
                        <li>Mover # Pick Retries</li>
                        <li>Mover # Place Retries</li>
                        <li>Mover # SCSI State</li>
                        <li>Mover # Scan Retries</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; AMI Megaraid</strong></p>
                    <ul>
                        <li>I/O Reads</li>
                        <li>I/O Rate</li>
                        <li>Adapter I/O Writes</li>
                        <li>Adapter Data</li>
                        <li>Traffic</li>
                        <li>Adapter Read Failure</li>
                        <li>I/O Failure</li>
                        <li>Adapter Write Failure</li>
                        <li>Physical Disk Status</li>
                        <li>Logical Disk Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; BlueArc</strong></p>
                    <ul>
                        <li>CRC Errors</li>
                        <li>Packets</li>
                        <li>Traffic</li>
                        <li>BlueArc CIFS Protocol Statistics</li>
                        <li>BlueArc Cluster Physical Node Status</li>
                        <li>BlueArc Cluster Virtual Node Status</li>
                        <li>BlueArc Fan Speed Status</li>
                        <li>BlueArc File System Resource Load</li>
                        <li>BlueArc I/O Statistics</li>
                        <li>BlueArc NFS Protocol Statistics</li>
                        <li>BlueArc Share Usage</li>
                        <li>BlueArc System Drive Status</li>
                        <li>BlueArc Temperature Sensor Status</li>
                        <li>CIFS Close Operations</li>
                        <li>CIFS Create Operations</li>
                        <li>CIFS Flush Operations</li>
                        <li>CIFS Getatr Operations</li>
                        <li>CIFS Lseek Operations</li>
                        <li>CIFS Mkdir Operations</li>
                        <li>CIFS Mknew Operations</li>
                        <li>CIFS Open Operations</li>
                        <li>CIFS Read Operations</li>
                        <li>CIFS ReadBraw Operations</li>
                        <li>CIFS Rename Operations</li>
                        <li>CIFS Rmdir Operations</li>
                        <li>CIFS Setatr Operations</li>
                        <li>CIFS Share Usage</li>
                        <li>CIFS Unlink Operations</li>
                        <li>CIFS Write Operations</li>
                        <li>CIFS WriteBraw Operations</li>
                        <li>Fan Status</li>
                        <li>File System Load (Client)</li>
                        <li>File System Load (System)</li>
                        <li>Filesystem I/O Operations</li>
                        <li>NFSv2 Create Operations</li>
                        <li>NFSv2 GetAttr Operations</li>
                        <li>NFSv2 MkDir Operations</li>
                        <li>NFSv2 Read Operations</li>
                        <li>NFSv2 Remove Operations</li>
                        <li>NFSv2 Rename Operations</li>
                        <li>NFSv2 RmDir Operations</li>
                        <li>NFSv2 SetAttr Operations</li>
                        <li>NFSv2 Write Operations</li>
                        <li>NFSv2 statFS Operations</li>
                        <li>NFSv3 Commit Operations</li>
                        <li>NFSv3 Create Operations</li>
                        <li>NFSv3 FSStat Operations</li>
                        <li>NFSv3 GetAttr Operations</li>
                        <li>NFSv3 MkDir Operations</li>
                        <li>NFSv3 MkNod Operations</li>
                        <li>NFSv3 Read Operations</li>
                        <li>NFSv3 Remove Operations</li>
                        <li>NFSv3 Rename Operations</li>
                        <li>NFSv3 RmDir Operations</li>
                        <li>NFSv3 SetAttr Operations</li>
                        <li>NFSv3 Write Operations</li>
                        <li>System Drive Status</li>
                        <li>Temperature Sensor Status</li>
                        <li>Volume Space Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Brocade</strong></p>
                    <ul>
                        <li>CRC In</li>
                        <li>RDYs</li>
                        <li>Fibre-Channel Switch Status</li>
                        <li>Port Errors</li>
                        <li>Port Operational Status</li>
                        <li>Port Physical State</li>
                        <li>Port Traffic</li>
                        <li>Self-Test Status</li>
                        <li>Sensor Status</li>
                        <li>Truncated Frames In</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Compaq Array</strong></p>
                    <ul>
                        <li>Compaq Array Accelerator Board Bad Data</li>
                        <li>Compaq Array Accelerator Board Battery Status</li>
                        <li>Compaq Array Accelerator Board Error Code</li>
                        <li>Compaq Array Accelerator Board I/O Errors</li>
                        <li>Compaq Array Accelerator Board Status</li>
                        <li>Compaq Array Controller Board Health</li>
                        <li>Disk Array/Controller Overall Health</li>
                        <li>Partner Board Health</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Dell Compellent</strong></p>
                    <ul>
                        <li>Cache Status</li>
                        <li>Controller Fan Status</li>
                        <li>Controller Power Status</li>
                        <li>Controller Sensor Temperature</li>
                        <li>Controller Status</li>
                        <li>Controller Temperature Status</li>
                        <li>Controller Voltage Status</li>
                        <li>Disk Folder Status</li>
                        <li>Disk Status</li>
                        <li>Enclosure Alarm Status</li>
                        <li>Enclosure Fan Status</li>
                        <li>Enclosure IO Module Status</li>
                        <li>Enclosure Power Status</li>
                        <li>Enclosure Status</li>
                        <li>Enclosure Temperature</li>
                        <li>Enclosure Temperature Status</li>
                        <li>Server Status</li>
                        <li>Storage Center Status</li>
                        <li>UPS Status</li>
                        <li>Volume Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Dell Equallogic</strong></p>
                    <ul>
                        <li>Average latency</li>
                        <li>Data Volume</li>
                        <li>EqualLogic: Controller Uptime</li>
                        <li>EqualLogic: Disk Errors</li>
                        <li>EqualLogic: Disk Status</li>
                        <li>EqualLogic: I/O Data Volume</li>
                        <li>EqualLogic: I/O Latency</li>
                        <li>EqualLogic: Number Of Read/Write Operations</li>
                        <li>EqualLogic: Number Of iSCSI Initiators</li>
                        <li>EqualLogic: Power Supply Status</li>
                        <li>EqualLogic: Standard Equipment Status</li>
                        <li>EqualLogic: Storage Space Usage</li>
                        <li>Member Volume Average latency</li>
                        <li>Member Volume Data Volume</li>
                        <li>Member Volume Read Operations</li>
                        <li>Member Volume Write Operations</li>
                        <li>Overall Status</li>
                        <li>Read Operations</li>
                        <li>Replication Space Usage</li>
                        <li>Reserved Space Usage</li>
                        <li>Temperature</li>
                        <li>Total Space Usage</li>
                        <li>Write Operations</li>
                        <li>iSCSI Initiators</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Dell Storage Array</strong></p>
                    <ul>
                        <li>RAID Controller Status</li>
                        <li>RAID Channel Status</li>
                        <li>Enclosure Status</li>
                        <li>Disk Status</li>
                        <li>Battery Rollup Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; EMC Avamar</strong></p>
                    <ul>
                        <li>Avamar Server Status</li>
                        <li>Avamar: Active Sessions</li>
                        <li>Avamar: Checkpoint Time</li>
                        <li>Avamar: Server Status</li>
                        <li>Number Of Avtar Sessions</li>
                        <li>Storage Space Utilization</li>
                        <li>Time Since Last Validated Checkpoint</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; EMC Connectrix Switch</strong></p>
                    <ul>
                        <li>Device Uptime</li>
                        <li>Overall Health Status</li>
                        <li>Sensor (fan, power, etc) Status</li>
                        <li>Switch Port Status</li>
                        <li>Switch Port Errors (link reset)</li>
                        <li>Switch Port Errors (offline seq)</li>
                        <li>Switch Port Errors (invalid crc)</li>
                        <li>Switch Port Errors (loss of signal)</li>
                        <li>Switch Port Errors (frames too long)</li>
                        <li>Switch Port Errors (invalid ordered sets)</li>
                        <li>Switch Port Traffic In/Out</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; EMC Data Domain</strong></p>
                    <ul>
                        <li>CPU Load</li>
                        <li>Data Domain: Chassis Fan Level</li>
                        <li>Data Domain: Chassis Fan Status</li>
                        <li>Data Domain: NVRAM Battery Status</li>
                        <li>Data Domain: Physical Disk Status</li>
                        <li>Data Domain: Power Module Status</li>
                        <li>Data Domain: RAID Disk Status</li>
                        <li>Data Domain: Replication Status</li>
                        <li>Data Domain: System I/O Operations</li>
                        <li>Data Domain: System Resource Utilization</li>
                        <li>Data Domain: Temperature Sensor Reading</li>
                        <li>Disk Activity Ratio (Overall)</li>
                        <li>Disk Data Volume (Overall)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; EMC Isilion NAS</strong></p>
                    <ul>
                        <li>CPU Usage</li>
                        <li>Fan Status</li>
                        <li>IFS Volume Usage</li>
                        <li>Isilon: Cluster/Node Health</li>
                        <li>Isilon: IFS Traffic Volume</li>
                        <li>Isilon: Network Traffic Volume</li>
                        <li>Isilon: Protocol Performance</li>
                        <li>Node Latency</li>
                        <li>Node Traffic</li>
                        <li>Speed</li>
                        <li>Temperature</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Fiber Channel Switch</strong></p>
                    <ul>
                        <li>SAN Switch Overall Health (McData)</li>
                        <li>SAN Switch Overall Health (Standard)</li>
                        <li>SAN Switch Port Errors</li>
                        <li>SAN Switch Port Operational Status</li>
                        <li>SAN Switch Port Physical State (McData)</li>
                        <li>SAN Switch Port Traffic</li>
                        <li>SAN Switch Sensor Status (Brocade)</li>
                        <li>SAN Switch Sensor Status (McData)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Hitachi Array</strong></p>
                    <ul>
                        <li>DKC Battery Status</li>
                        <li>DKC Cache Status</li>
                        <li>DKC Environment Status</li>
                        <li>DKC Fan Status</li>
                        <li>DKC Internal Bus Status</li>
                        <li>DKC Processor Status</li>
                        <li>DKC Shared Memory Status</li>
                        <li>DKC [$objectIndex]]: Power Supply Status</li>
                        <li>DKU Drive Status</li>
                        <li>DKU Environment Status</li>
                        <li>DKU Fan Status</li>
                        <li>DKU Power Supply Status</li>
                        <li>Hitachi Storage Array Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Lefthand SAN</strong></p>
                    <ul>
                        <li>Cluster Disk Space Total</li>
                        <li>Cluster Disk Space Used</li>
                        <li>Cluster Disk Usage Percentage</li>
                        <li>Cluster KBytes Read</li>
                        <li>Cluster KBytes Written</li>
                        <li>Cluster Provisioned Space</li>
                        <li>Cluster Queued Read Operations</li>
                        <li>Cluster Queued Write Operations</li>
                        <li>Cluster Read Latency</li>
                        <li>Cluster Read Operations</li>
                        <li>Cluster Total Space</li>
                        <li>Cluster Write Latency</li>
                        <li>Cluster Write Operations</li>
                        <li>Disk Space Available</li>
                        <li>Disk Usage</li>
                        <li>Fan Status</li>
                        <li>Health</li>
                        <li>Power Supply Status</li>
                        <li>Provisioned Space</li>
                        <li>RAID Health</li>
                        <li>RAID KBytes Read from NSM</li>
                        <li>RAID KBytes Written to NSM</li>
                        <li>RAID Queued Read and Write Operations</li>
                        <li>RAID Read Latency</li>
                        <li>RAID Read Operations</li>
                        <li>RAID Write Latency</li>
                        <li>RAID Write Operations</li>
                        <li>Raid Device Health Status</li>
                        <li>Read Cache and Read Ahead Hits</li>
                        <li>Snapshot Count</li>
                        <li>Storage Device Health Status</li>
                        <li>Temperature</li>
                        <li>Temperature</li>
                        <li>Volume Cache Hits</li>
                        <li>Volume Disk Usage Percentage</li>
                        <li>Volume KB Read</li>
                        <li>Volume KB Written</li>
                        <li>Volume Provisioned Space</li>
                        <li>Volume Queued Read Operations</li>
                        <li>Volume Queued Write Operations</li>
                        <li>Volume Read Latency</li>
                        <li>Volume Read Operations</li>
                        <li>Volume Snapshot Count</li>
                        <li>Volume Usage on Cluster</li>
                        <li>Volume Usage on Cluster</li>
                        <li>Volume Write Latency</li>
                        <li>Volume Write Operations</li>
                        <li>Volume/RAID/Cluster I/O Latency</li>
                        <li>Volume/RAID/Cluster I/O Operations</li>
                        <li>Volume/RAID/Cluster KBytes Read/Written</li>
                        <li>Volume/RAID/Cluster Queue Depth Stats</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; NetApp</strong></p>
                    <ul>
                        <li>ICMP Traffic</li>
                        <li>Packets Accepted</li>
                        <li>Packets Blocked</li>
                        <li>TCP Connections</li>
                        <li>TCP Traffic</li>
                        <li>Throughput</li>
                        <li>UDP Connections</li>
                        <li>UDP Traffic</li>
                        <li>NetASQ Active Connections</li>
                        <li>NetApp Status</li>
                        <li>NetApp CIFS Operations</li>
                        <li>NetApp CPU Util</li>
                        <li>NetApp Chassis Temperature</li>
                        <li>NetApp Disk I/O</li>
                        <li>NetApp FS: Space Used</li>
                        <li>NetApp FS: Space Util</li>
                        <li>NetApp File System Status</li>
                        <li>NetApp Global Status</li>
                        <li>NetApp HTTP Operations</li>
                        <li>NetApp IP Traffic</li>
                        <li>NetApp NFS Call Rejects</li>
                        <li>NetApp NFS Calls</li>
                        <li>NetApp NFS Operations</li>
                        <li>NetApp RPC Call Rejects</li>
                        <li>NetApp RPC Calls</li>
                        <li>NetApp VOL: Space Util</li>
                        <li>Netapp QT:path Files Used</li>
                        <li>Netapp QT:path Space Used</li>
                        <li>Network Appliance Disk I/O</li>
                        <li>Network Appliance Disk Space Used (SNMP)</li>
                        <li>Network Appliance Disk Space Utilization (SNMP)</li>
                        <li>Network Appliance Disk Status</li>
                        <li>Network Appliance Environmental Status</li>
                        <li>Network Appliance FileSystem Status</li>
                        <li>Network Appliance Files Used</li>
                        <li>Network Appliance Global Status</li>
                        <li>Network Appliance IP Traffic</li>
                        <li>Network Appliance Protocol Errors</li>
                        <li>Network Appliance Protocol Operations</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Nimble SAN</strong></p>
                    <ul>
                        <li>Combined Data Traffic</li>
                        <li>Combined Read</li>
                        <li>Combined Write</li>
                        <li>I/O Data Traffic</li>
                        <li>I/O Latency</li>
                        <li>Number Of Read/Write Operations</li>
                        <li>Number of iSCSI Connections</li>
                        <li>Total Cache Hits (Non-Sequential Read I/O)</li>
                        <li>Sequential Data Traffic</li>
                        <li>Sequential Read</li>
                        <li>Sequential Write</li>
                        <li>Total Cache Hits</li>
                        <li>Total Latency</li>
                        <li>Volume Space Utilization</li>
                        <li>Volume iSCSI Connections</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Pure</strong></p>
                    <ul>
                        <li>Array Bandwidth</li>
                        <li>Array IOPS</li>
                        <li>Array Latency</li>
                        <li>Pure Storage: Bandwidth</li>
                        <li>Pure Storage: IOPS</li>
                        <li>Pure Storage: Latency</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Spectra nTier</strong></p>
                    <ul>
                        <li>Drive Read Latency</li>
                        <li>Drive Read Statistics</li>
                        <li>Drive Write Latency</li>
                        <li>Drive Write Statistics</li>
                        <li>Network Interface Data Traffic</li>
                        <li>Network Interface Inbound Packet drops</li>
                        <li>Network Interface Packet Traffic</li>
                        <li>Network Interface errors</li>
                        <li>Pool Read IOPS</li>
                        <li>Pool Read Statistics</li>
                        <li>Pool Write IOPS</li>
                        <li>Pool Write Statistics</li>
                        <li>CPU Utilization</li>
                        <li>Network Interface Packet Collisions</li>
                        <li>Pool Storage Usage</li>
                        <li>Power Supply</li>
                        <li>Spectra nTier Verde: Network Statistics</li>
                        <li>Spectra nTier Verde: Sensors</li>
                        <li>Storage Utilization</li>
                        <li>Volume Pool Storage Usage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sun T300</strong></p>
                    <ul>
                        <li>FRU Removal Shutdown</li>
                        <li>Total Read Requests</li>
                        <li>Total Write Requests</li>
                        <li>Total Blocks Read</li>
                        <li>Total Blocks Written</li>
                        <li>Total Cache Hits On Read</li>
                        <li>Total Cache Hits On Write</li>
                        <li>Total Cache Misses On Read</li>
                        <li>Total Cache Misses On Write</li>
                        <li>Total Read-Modify-Write Operations</li>
                        <li>Total Reconstruct-Write Operations</li>
                        <li>Total Stripe-Write Operations</li>
                        <li>Status</li>
                        <li>State</li>
                        <li>Errors</li>
                        <li>Disk Drive Role</li>
                        <li>Disk Drive Port-1 State</li>
                        <li>Disk Drive Port-2 State</li>
                        <li>Disk Drive Temperature</li>
                        <li>Operation</li>
                        <li>Temperature</li>
                        <li>Output</li>
                        <li>Source</li>
                        <li>Temperature</li>
                        <li>Fan-1 State</li>
                        <li>Fan-2 State</li>
                        <li>Battery State</li>
                        <li>Battery Life</li>
                        <li>Battery Used</li>
                        <li>Volume- Read Requests</li>
                        <li>Volume- Write Requests</li>
                        <li>Volume- Blocks Read</li>
                        <li>Volume- Blocks Written</li>
                        <li>Volume- Soft Errors</li>
                        <li>Volume- Firm Errors</li>
                        <li>Volume- Hard Errors</li>
                        <li>Volume- Cache Hits on Read</li>
                        <li>Volume- Cache Hits on Write</li>
                        <li>Volume- Cache Misses on Read</li>
                        <li>Volume- Cache Misses on Write</li>
                        <li>Volume- Read-Modify-Write Operations</li>
                        <li>Volume- Reconstruct-Write Operations</li>
                        <li>Volume- Stripe-Write Operations</li>
                        <li>Volume- Current Operation</li>
                        <li>Volume- Operation Progress</li>
                        <li>Controller Port Read Requests</li>
                        <li>Controller Port Write Requests</li>
                        <li>Controller Port Blocks Read</li>
                        <li>Controller Port Blocks Written</li>
                        <li>Controller Port Status</li>
                        <li>Controller Port Errors</li>
                    </ul>
                    <h3>8.&nbsp;&nbsp; Firewalls</h3>
                    <p>It is not uncommon to deploy firewalls in a network and yet have no real day-to-day reporting or visibility into their operation. Unfortunately, this lack of visibility translates into costly security break-ins. Traverse can monitor critical metrics and alerts on your firewalls and provide a centralized management center for the entire network. Some of the firewalls and security infrastructure that Traverse supports include:</p>
                    <ul>
                        <li>Checkpoint, Cisco PIX, Juniper Netscreen</li>
                        <li>Blue Coat, Fortinet, IronPort, NetASQ</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; Bluecoat</strong></p>
                    <ul>
                        <li>Sensor Status</li>
                        <li>Utilization</li>
                        <li>Blue Coat Device Utilization</li>
                        <li>Blue Coat Environmental Sensor Status</li>
                        <li>Blue Coat Physical Disk Status</li>
                        <li>Disk Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Checkpoint</strong></p>
                    <ul>
                        <li>CPU Utilization</li>
                        <li>Current Connections</li>
                        <li>Dropped Packets</li>
                        <li>Real Memory Usage</li>
                        <li>Rejected Packets</li>
                        <li>Total Packets Accepted</li>
                        <li>Total Packets Dropped</li>
                        <li>Total Packets Logged</li>
                        <li>Total Packets Rejected</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco ASA/PIX</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>Active CIFS Connections</li>
                        <li>Active DNS Connections</li>
                        <li>Active FTP Connections</li>
                        <li>Active H.323 Connections</li>
                        <li>Active HTTP Connections</li>
                        <li>Active HTTPS Connections</li>
                        <li>Active IP Connections</li>
                        <li>Active NFS Connections</li>
                        <li>Active Oracle Connections</li>
                        <li>Active RPC (Microsoft) Connections</li>
                        <li>Active RPC (Sun/ONC) Connections</li>
                        <li>Active RTSP Connections</li>
                        <li>Active Real A/V Connections</li>
                        <li>Active SMTP Connections</li>
                        <li>Active SQL Server Connections</li>
                        <li>Active TCP Connections</li>
                        <li>Active Telnet Connections</li>
                        <li>Active UDP Connections</li>
                        <li>Active WINS Connections</li>
                        <li>Cisco Firewall (ASA/PIX) Active Connections</li>
                        <li>Cisco Firewall (ASA/PIX) Cluster Status</li>
                        <li>Cisco Firewall (ASA/PIX) Hardware Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Forcepoint Stonesoft</strong></p>
                    <ul>
                        <li>CPU Load</li>
                        <li>Firewall Connection rate</li>
                        <li>Global/Interface Accepted Packets</li>
                        <li>Global/Interface Dropped Packets</li>
                        <li>Global/Interface Accounted Packets</li>
                        <li>Global/Interface Rejected Packets</li>
                        <li>Global/Interface Logged Packets</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Fortinet</strong></p>
                    <ul>
                        <li>Fortinet A/V Detections for domain</li>
                        <li>Fortinet Established Sessions</li>
                        <li>Fortinet IPS Detections for domain</li>
                        <li>Fortinet Intrusion/Anti-Virus Events</li>
                        <li>Fortinet Session Creation Rate</li>
                        <li>Fortinet System-Wide Active Sessions</li>
                        <li>Fortinet System-Wide Active Sessions</li>
                        <li>Fortinet Unit # AV Events</li>
                        <li>Fortinet Unit # CPU Util</li>
                        <li>Fortinet Unit # IDS Events</li>
                        <li>Fortinet Unit # Memory Util</li>
                        <li>Fortinet Unit # Network Util</li>
                        <li>Fortinet Unit # Sessions</li>
                        <li>Fortinet Unit # Traffic</li>
                        <li>Overall CPU Utilization</li>
                        <li>Overall CPU Utilization</li>
                        <li>Overall Memory Utilization</li>
                        <li>Overall Memory Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Ironport</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>Status</li>
                        <li>Temperature</li>
                        <li>Active File/Network Connections</li>
                        <li>CPU Utilization</li>
                        <li>Disk I/O Utilization</li>
                        <li>IronPort Pending DNS Requests</li>
                        <li>IronPort Power Supply Status</li>
                        <li>IronPort Queued Message Age</li>
                        <li>IronPort RAID Status</li>
                        <li>IronPort Resource Conservation Reason</li>
                        <li>MTA Group Active Connections</li>
                        <li>MTA Group Connection Errors</li>
                        <li>MTA Group Message Traffic</li>
                        <li>MTA Group Messages Rcvd</li>
                        <li>MTA Group Messages Rejected</li>
                        <li>MTA Group Messages Sent</li>
                        <li>MTA Group Queue Size</li>
                        <li>MTA Group Rejected Connections</li>
                        <li>MTA System-Wide Loops</li>
                        <li>MTA System-Wide Message Traffic</li>
                        <li>MTA System-Wide Messages Rcvd</li>
                        <li>MTA System-Wide Messages Sent</li>
                        <li>MTA System-Wide Queue Size</li>
                        <li>Mail Transfer Threads</li>
                        <li>Messages In Queue</li>
                        <li>Oldest Queued Message Age</li>
                        <li>Pending DNS Requests</li>
                        <li>Physical Memory Utilization</li>
                        <li>Resource Conservation Status</li>
                        <li>Total Queue Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Neoteris</strong></p>
                    <ul>
                        <li>Applet Access</li>
                        <li>CPU Util</li>
                        <li>File Access</li>
                        <li>Log File Util</li>
                        <li>Meeting Access</li>
                        <li>Neoteris Connection Rate</li>
                        <li>Neoteris Log Space Utilization</li>
                        <li>Neoteris Signed In Users</li>
                        <li>Network Connect Access</li>
                        <li>Physical Memory Util</li>
                        <li>Secure Application Manager Access</li>
                        <li>Signed In Mail Users</li>
                        <li>Signed In Web Users</li>
                        <li>Terminal Access</li>
                        <li>Web Application Access</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Netscreen</strong></p>
                    <ul>
                        <li>Interface Traffic</li>
                        <li>CPU Load</li>
                        <li>Interface Packets</li>
                        <li>NetScreen Interface Packets Dropped</li>
                        <li>NetScreen Interface Status</li>
                        <li>NetScreen Policy Matches (Packets)</li>
                        <li>NetScreen Policy Matches (Sessions)</li>
                        <li>NetScreen Policy Matches (Traffic)</li>
                        <li>NetScreen Session Count</li>
                        <li>NetScreen VPN Tunnel Availability</li>
                        <li>NetScreen VPN Tunnel Latency</li>
                        <li>NetScreen VPN Tunnel Status</li>
                        <li>Number of Active Sessions</li>
                        <li>Packets Dropped On (Auth Failure)</li>
                        <li>Packets Dropped On (No SA For SPI)</li>
                        <li>Packets Dropped On (Policy Reject)</li>
                        <li>Packets Dropped On (SA Inactive)</li>
                        <li>Packets Dropped On (SA Policy Deny)</li>
                        <li>Packets Dropped On (SA Policy Mismatch)</li>
                        <li>Packets Dropped On (Traffic Mgmt)</li>
                        <li>Packets Dropped On (URL Blocked)</li>
                        <li>Physical Memory Usage</li>
                        <li>Policy Packets</li>
                        <li>Policy Sessions</li>
                        <li>Policy Traffic</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Palo Alto Networks</strong></p>
                    <ul>
                        <li>Number of Active ICMP Sessions</li>
                        <li>Number of Active Sessions</li>
                        <li>Number of Active TCP Sessions</li>
                        <li>Number of Active UDP Sessions</li>
                        <li>Palo Alto Active ICMP Sessions</li>
                        <li>Palo Alto Active TCP Sessions</li>
                        <li>Palo Alto Active UDP Sessions</li>
                        <li>Palo Alto Sensor Status</li>
                        <li>Palo Alto Session Count</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; SonicWall SSL</strong></p>
                    <ul>
                        <li>Active NetExtender Connections</li>
                        <li>Active User Licenses</li>
                        <li>Active User Sessions</li>
                        <li>Active Virtual Assist Technicians</li>
                        <li>CPU Utilization</li>
                        <li>Physical Memory Utilization</li>
                        <li>SonicWall: Active NetExtender Connections</li>
                        <li>SonicWall: Active User Licenses</li>
                        <li>SonicWall: Active Virtual Assist Technicians</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; SonicWall</strong></p>
                    <ul>
                        <li>Active Connection Cache Entries</li>
                        <li>CPU Utilization</li>
                        <li>Physical Memory Utilization</li>
                        <li>SA Decrypted Packets</li>
                        <li>SA Encrypted Packets</li>
                        <li>SA Encrypted Traffic</li>
                        <li>SA Fragmented Packets</li>
                        <li>SA Decrypted Traffic</li>
                        <li>SonicWall: Active Connection Cache</li>
                        <li>SonicWall: SA Fragmented Packets</li>
                        <li>SonicWall: SA Packets</li>
                        <li>SonicWall: SA Traffic</li>
                    </ul>
                    <h3>9.&nbsp;&nbsp; Datacenter Power &amp; Cooling</h3>
                    <p>Traverse is ready to be deployed in today's data centers and can easily monitor core infrastructures such as UPS's, Generators, HVACs, and tape libraries. Some of the Datacenter infrastructure that Traverse supports includes:</p>
                    <ul>
                        <li>Liebert UPS, Liebert HVAC, Amperion Broadband over PowerLine</li>
                        <li>APC UPS, Omnitronix, Valere DC Generators, ADIC Tape Library</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; APC PDU</strong></p>
                    <ul>
                        <li>Current Draw Status</li>
                        <li>Humidity Sensor</li>
                        <li>Load Status</li>
                        <li>Power Draw Status</li>
                        <li>Temperature Sensor</li>
                        <li>PDU Load Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; APC Powernet</strong></p>
                    <ul>
                        <li>NetBotz Humidity</li>
                        <li>NetBotz Comm Status</li>
                        <li>NetBotz Probe: Communication Status</li>
                        <li>NetBotz Probe: Outlet Status</li>
                        <li>NetBotz Status</li>
                        <li>NetBotz Temperature</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; APC UPS</strong></p>
                    <ul>
                        <li>Humidity Sensor</li>
                        <li>Temperature Sensor</li>
                        <li>APC Temperature/Humidity Sensor</li>
                        <li>APC UPS Battery Capacity</li>
                        <li>APC UPS Battery Needs Replacement</li>
                        <li>APC UPS Battery Runtime</li>
                        <li>APC UPS Battery Status</li>
                        <li>APC UPS Battery Temp</li>
                        <li>APC UPS I/O Voltage</li>
                        <li>APC UPS Output Status</li>
                        <li>UPS Battery Capacity</li>
                        <li>UPS Battery Needs Replacement</li>
                        <li>UPS Battery Runtime</li>
                        <li>UPS Battery Status</li>
                        <li>UPS Battery Temp</li>
                        <li>UPS Output Status</li>
                        <li>UPS Voltage</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Amperion over PowerLine</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>12v Power Supply Voltage</li>
                        <li>AC Line (Power) Status</li>
                        <li>AFE Extractor Receive Gain</li>
                        <li>AFE Extractor Transmit Gain</li>
                        <li>AFE Injector Receive Gain</li>
                        <li>AFE Injector Transmit Gain</li>
                        <li>Amperion AC Line Status</li>
                        <li>Amperion Cooling Fan Status</li>
                        <li>Amperion Digital Board Temperature</li>
                        <li>Amperion Extractor/Injector Gain</li>
                        <li>Amperion PLC Specific Errors</li>
                        <li>Amperion PowerLine Card Status</li>
                        <li>Amperion Regulated Voltage</li>
                        <li>Cooling Fan Status</li>
                        <li>Digital Board Temperature</li>
                        <li>PLC Extractor Receive Gain</li>
                        <li>PLC Extractor Transmit Gain</li>
                        <li>PLC Injector Receive Gain</li>
                        <li>PLC Injector Transmit Gain</li>
                        <li>PLC Loss Rate</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Avocent PDU</strong></p>
                    <ul>
                        <li>Current Sensor</li>
                        <li>Humidity Sensor</li>
                        <li>Outlet Status</li>
                        <li>Temperature Sensor</li>
                        <li>Voltage Sensor</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Avtech Environment</strong></p>
                    <ul>
                        <li>Digital Sensor</li>
                        <li>Internal Flood Cable Fault Sensor</li>
                        <li>Internal Flood Sensor</li>
                        <li>Internal Power Sensor</li>
                        <li>Internal Relative Humidity</li>
                        <li>Internal Temperature</li>
                        <li>Switch Sensor</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Avtech Sensor</strong></p>
                    <ul>
                        <li>AVTech Digital Sensor</li>
                        <li>AVTech Flood Cable Fault Sensor</li>
                        <li>AVTech Flood Sensor</li>
                        <li>AVTech Internal Temperature</li>
                        <li>AVTech Power Sensor</li>
                        <li>AVTech Relative Humidity</li>
                        <li>AVTech Switch Sensor</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Liebert HVAC</strong></p>
                    <ul>
                        <li>Cooling Capacity in Use</li>
                        <li>Cooling Sub-system State</li>
                        <li>Cooling System State</li>
                        <li>Dehumidifier Sub-system State</li>
                        <li>Dehumidifying System State</li>
                        <li>Econ-o-Cycle System State</li>
                        <li>Econ-o-cycle Sub-system State</li>
                        <li>Fan System State</li>
                        <li>Heating Capacity in Use</li>
                        <li>Heating Sub-system State</li>
                        <li>Heating System State</li>
                        <li>Heating System State</li>
                        <li>Humidifier Sub-system State</li>
                        <li>Humidity Probe # (High)</li>
                        <li>Humidity Probe # (Low)</li>
                        <li>Liebert Environmental Conditions</li>
                        <li>Liebert Environmental State</li>
                        <li>Liebert Humidity Probe</li>
                        <li>Liebert Subsystem Capacity</li>
                        <li>Liebert Subsystem State</li>
                        <li>Liebert Temperature Probe</li>
                        <li>Number of Conditions</li>
                        <li>Overall System State</li>
                        <li>Temperature Probe # (High)</li>
                        <li>Temperature Probe # (Low)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Liebert PDU</strong></p>
                    <ul>
                        <li>Capacity</li>
                        <li>PDU Apparent Power</li>
                        <li>PDU Capacity Phase X</li>
                        <li>PDU Capacity Phase Y</li>
                        <li>PDU Capacity Phase Z</li>
                        <li>PDU Frequency</li>
                        <li>PDU Ground Current</li>
                        <li>PDU Input A-B</li>
                        <li>PDU Input B-C</li>
                        <li>PDU Input C-A</li>
                        <li>PDU Neutral Current</li>
                        <li>PDU Output Current Phase X</li>
                        <li>PDU Output Current Phase Y</li>
                        <li>PDU Output Current Phase Z</li>
                        <li>PDU Output X-N</li>
                        <li>PDU Output X-Y</li>
                        <li>PDU Output Y-N</li>
                        <li>PDU Output Y-Z</li>
                        <li>PDU Output Z-N</li>
                        <li>PDU Output Z-X</li>
                        <li>PDU Power</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Liebert UPS</strong></p>
                    <ul>
                        <li>Liebert Power Load</li>
                        <li>Line: AC Power Load</li>
                        <li>UPS Alarm Conditions</li>
                        <li>UPS Battery Capacity</li>
                        <li>UPS Battery Current</li>
                        <li>UPS Battery Remaining Time</li>
                        <li>UPS Battery Temp</li>
                        <li>UPS Battery Voltage</li>
                        <li>UPS BlackOuts</li>
                        <li>UPS BrownOuts</li>
                        <li>UPS Bypass (Line #) Current</li>
                        <li>UPS Bypass (Line #) Voltage</li>
                        <li>UPS Bypass Status</li>
                        <li>UPS Input (Line #) Current</li>
                        <li>UPS Input (Line #) Over Voltage</li>
                        <li>UPS Input (Line #) Under Voltage</li>
                        <li>UPS Input Frequency</li>
                        <li>UPS Inverter Status</li>
                        <li>UPS Line Draw</li>
                        <li>UPS Output (Line #) Current</li>
                        <li>UPS Output (Line #) Draw</li>
                        <li>UPS Output (Line #) Voltage</li>
                        <li>UPS Output Frequency</li>
                        <li>UPS Output Load</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; NetBotz</strong></p>
                    <ul>
                        <li>AirFlow</li>
                        <li>Amperage</li>
                        <li>Camera Motion</li>
                        <li>Door Sensor</li>
                        <li>Generic Sensor Status</li>
                        <li>Humidity</li>
                        <li>Temperature</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; PowerWare UPS</strong></p>
                    <ul>
                        <li>Remaining Time on Battery</li>
                        <li>Remaining Charge on Battery</li>
                        <li>Output Load</li>
                        <li>Ambient Temperature</li>
                        <li>Remote Temperature</li>
                        <li>Ambient Humidity</li>
                        <li>Remote Humidity</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Sentry CDU</strong></p>
                    <ul>
                        <li>Humidity Sensor</li>
                        <li>Input Feed Load</li>
                        <li>Input Feed Power Consumption</li>
                        <li>Input Feed Status</li>
                        <li>Input Feed Voltage</li>
                        <li>Temperature Sensor</li>
                        <li>Tower Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Tripplite UPS</strong></p>
                    <ul>
                        <li>EnviroSense Ambient Temperature</li>
                        <li>EnviroSense Humidity</li>
                        <li>EnviroSense Temperature</li>
                        <li>Envirosense Humidity</li>
                        <li>On Battery Time Elapsed</li>
                        <li>UPS Battery Current</li>
                        <li>UPS Output Load</li>
                        <li>UPS: On Battery Time Elapsed</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; UPS Generic</strong></p>
                    <ul>
                        <li>UPS Alarm Conditions</li>
                        <li>UPS Battery Capacity</li>
                        <li>UPS Battery Current</li>
                        <li>UPS Battery Remaining Time</li>
                        <li>UPS Battery Status</li>
                        <li>UPS Battery Temperature</li>
                        <li>UPS Battery Voltage</li>
                        <li>UPS Bypass Condition</li>
                        <li>UPS Inverter Status</li>
                        <li>UPS Line BlackOuts</li>
                        <li>UPS Line BrownOuts</li>
                        <li>UPS Line Current</li>
                        <li>UPS Line Frequency</li>
                        <li>UPS Line Load</li>
                        <li>UPS Line Status</li>
                        <li>UPS Line Voltage (Over)</li>
                        <li>UPS Line Voltage (Under)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Valere DC Generator</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>Battery Temperature</li>
                        <li>Controller Internal Temperature</li>
                        <li>System Current</li>
                        <li>System Voltage</li>
                        <li>Valere Power Battery Temperature</li>
                        <li>Valere Power Module Status</li>
                        <li>Valere Power System Current</li>
                        <li>Valere Power System Voltage</li>
                    </ul>
                    <h3>10.&nbsp;&nbsp;&nbsp; Voice Over IP</h3>
                    <p>Traverse allows you to monitor the performance of your Voice over IP infrastructure and services in converged networks by measuring key metrics such as critical delay and jitter. Some of the VoIP infrastructures that Traverse supports include:</p>
                    <ul>
                        <li>Broadworks, Cisco IP-SLA (SAA), Cisco Call Manager</li>
                        <li>Cisco ITP, Kagoor, Cisco Meeting Place</li>
                    </ul>
                    <p><strong>Supported Metrics</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; Broadworks</strong></p>
                    <ul>
                        <li>TimesTen Backdoor updates</li>
                        <li>Network Originations</li>
                        <li>Network Terminations</li>
                        <li>Network Terminations Answered</li>
                        <li>User Originations</li>
                        <li>User Terminations</li>
                        <li>User Terminations Answered</li>
                        <li>SIP Transaction Rate</li>
                        <li>SIP INVITE</li>
                        <li>SIP ACK</li>
                        <li>SIP BYE</li>
                        <li>SIP CANCEL</li>
                        <li>SIP OPTIONS</li>
                        <li>SIP REGISTER</li>
                        <li>SIP INFO</li>
                        <li>SIP NOTIFY</li>
                        <li>SIP PRACK</li>
                        <li>SIP Other</li>
                        <li>SIP retry invites</li>
                        <li>SIP retry byes</li>
                        <li>SIP retry cancels</li>
                        <li>SIP retry responses</li>
                        <li>SIP subscribe ins</li>
                        <li>SIP message</li>
                        <li>SIP update</li>
                        <li>SIP setup delay</li>
                        <li>SIP answer signal delay</li>
                        <li>SIP registrations rate</li>
                        <li>Number of Users</li>
                        <li>Number of Groups</li>
                        <li>Number of Service Providers</li>
                        <li>Phone Numbers Configured</li>
                        <li>Phone Numbers Assigned</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco CMR</strong></p>
                    <ul>
                        <li>Jitter</li>
                        <li>K-Factor Conceal Secs</li>
                        <li>K-Factor Cumulative Conceal Ratio</li>
                        <li>K-Factor Interval Conceal Ratio</li>
                        <li>K-Factor Interval Conceal Ratio Mx</li>
                        <li>K-Factor Severely Conceal Secs</li>
                        <li>Latency</li>
                        <li>MOS K-Factor</li>
                        <li>MOS K-Factor Av</li>
                        <li>MOS K-Factor Mn</li>
                        <li>MOS K-Factor Mx</li>
                        <li>MOS K-Factor Vr</li>
                        <li>Packets Transmitted</li>
                        <li>Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco CUCM</strong></p>
                    <ul>
                        <li>CPU Idle Time</li>
                        <li>CPU Utilization</li>
                        <li>Database Replication State</li>
                        <li>Network Packets Transmitted</li>
                        <li>Network Traffic Volume</li>
                        <li>Number Of Calls Active</li>
                        <li>Number Of Calls Completed</li>
                        <li>Number Of Calls In Progress</li>
                        <li>Number Of Conferences Active</li>
                        <li>Number Of Conferences Completed</li>
                        <li>Number Of Ports Active</li>
                        <li>Number of Processes</li>
                        <li>Number of Threads</li>
                        <li>Physical Memory Utilization</li>
                        <li>Process CPU Utilization</li>
                        <li>Process Memory Utilization</li>
                        <li>Process Status</li>
                        <li>Registered Phone Count</li>
                        <li>Virtual Memory Utilization</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Call Manager Express</strong></p>
                    <ul>
                        <li>Answered Calls</li>
                        <li>Calls disconnected during call setup</li>
                        <li>Calls not answered due to Busy Line</li>
                        <li>placed calls disconnected during alerting stage</li>
                        <li>placed calls disconnected during call put on Hold</li>
                        <li>received calls disconnected during alerting stage</li>
                        <li>Active Number of Calls</li>
                        <li>Cisco CCME Ephone Statistics</li>
                        <li>Cisco CCME IP Phone Counts</li>
                        <li>Cisco CCME active calls</li>
                        <li>Total Cisco IP Extension Mobility Phones Registered</li>
                        <li>Total Cisco IP Extension Mobility Phones Seen</li>
                        <li>Total Cisco IP Phones Registered</li>
                        <li>Total Cisco IP Phones Seen</li>
                        <li>Total Cisco Key IP Phones Configured</li>
                        <li>Total Cisco Key IP Phones Registered</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Call Manager</strong></p>
                    <ul>
                        <li>Active Annunciator Resource</li>
                        <li>Active H.323 Calls</li>
                        <li>Active Hardware Conference</li>
                        <li>Active MOH (Multicast) Resource</li>
                        <li>Active MOH (Unicast) Resource</li>
                        <li>Active MTP Resource</li>
                        <li>Active PRI Channels</li>
                        <li>Active Phone Calls</li>
                        <li>Active Software Conference</li>
                        <li>Active T1 Channels</li>
                        <li>Active Transcoder Resource</li>
                        <li>Call Activity</li>
                        <li>Resource Activity</li>
                        <li>Device Registration</li>
                        <li>Device Status</li>
                        <li>Registration Failure</li>
                        <li>Status</li>
                        <li>Registered Gateways</li>
                        <li>Registered IP Phones</li>
                        <li>Registered Media Devices</li>
                        <li>Registered Voice Mail Devices</li>
                        <li>Registration Failure (Gateway)</li>
                        <li>Registration Failure (Media Device)</li>
                        <li>Registration Failure (Phone)</li>
                        <li>Registration Failure (Voice Mail Devices)</li>
                        <li>Unregistered Gateways</li>
                        <li>Unregistered Media Devices</li>
                        <li>Unregistered Phones</li>
                        <li>Unregistered Voice Mail Devices</li>
                        <li>Voice Mail Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Contact Center</strong></p>
                    <ul>
                        <li>CCCA: Agents Logged On</li>
                        <li>CCCA: Agents Logged On</li>
                        <li>CCCA: Call Queued (All network VRUs)</li>
                        <li>CCCA: Calls Queued (All network VRUs)</li>
                        <li>CCCA: Inbound Calls per Second</li>
                        <li>CCCA: Inbound Calls per Second</li>
                        <li>CCCA: Voice Calls in Progress</li>
                        <li>CCCA: Voice Calls in Progress</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco IP-SLA</strong></p>
                    <ul>
                        <li>Cisco IP-SLA DHCP Lease Response</li>
                        <li>Cisco IP-SLA DNS Query Response</li>
                        <li>Cisco IP-SLA FTP Query Response</li>
                        <li>Cisco IP-SLA HTTP Query Response</li>
                        <li>Cisco IP-SLA IcmpEcho Response</li>
                        <li>Cisco IP-SLA Jitter Analysis</li>
                        <li>Cisco IP-SLA Mean Opinion Score</li>
                        <li>Cisco IP-SLA One Way Latency</li>
                        <li>Cisco IP-SLA Planning Impairment Factor</li>
                        <li>Cisco IP-SLA Response Test Status</li>
                        <li>Cisco IP-SLA TCP Connect Response</li>
                        <li>Cisco IP-SLA Traffic Error</li>
                        <li>Cisco IP-SLA UdpEcho Response</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco MeetingPlace</strong></p>
                    <ul>
                        <li>Active Conference Participants</li>
                        <li>Cisco Meeting Place Call Activity</li>
                        <li>Cisco Meeting Place Call Activity</li>
                        <li>Disk Space Utilization</li>
                        <li>CPU Utilization</li>
                        <li>Physical Memory Utilization</li>
                        <li>Virtual Memory Utilization</li>
                        <li>Number of Users</li>
                        <li>Number of Processes</li>
                        <li>Number of Threads</li>
                        <li>System Context Switches</li>
                        <li>File I/O Operations</li>
                        <li>Windows Service Status</li>
                        <li>Network Interface: Availability</li>
                        <li>Network Interface: Bandwidth Utilization</li>
                        <li>Network Interface: Packets Discarded</li>
                        <li>Network Interface: CRC Errors</li>
                        <li>Network Interface: Packets Transmitted</li>
                        <li>Network Interface: Traffic Volume</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco SysApp</strong></p>
                    <ul>
                        <li>Cisco System Application</li>
                        <li>Max rows in Element Past Run table</li>
                        <li>Max rows in Past Run table</li>
                        <li>Max age of entry in Element Past Run table</li>
                        <li>Max age of entry in Past Run table</li>
                        <li>Min interval to poll status</li>
                        <li>No of entries removed beyond Past Run table</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Telepresence</strong></p>
                    <ul>
                        <li>Number Of Calls</li>
                        <li>Ethernet Peripheral Operational Status</li>
                        <li>HDMI Peripheral Operational Status</li>
                        <li>HDMI Peripheral Power Status</li>
                        <li>HDMI Peripheral Cable Status</li>
                        <li>DVI Peripheral Operational Status</li>
                        <li>DVI Peripheral Cable Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Unity Express</strong></p>
                    <ul>
                        <li>Enabled</li>
                        <li>Expires In</li>
                        <li>New Messages</li>
                        <li>Saved Messages</li>
                        <li>Time % Usage</li>
                        <li>Time Usage</li>
                        <li>Total Messages</li>
                        <li>Calls in Progress</li>
                        <li>Cisco Unity Express Active Calls</li>
                        <li>Cisco Unity Express Default Sizes</li>
                        <li>Cisco Unity Express Mailbox Usages</li>
                        <li>Cisco Unity Express Ports</li>
                        <li>Cisco Unity GUI Login Stats</li>
                        <li>Cisco Unity Greetings Usages</li>
                        <li>Cisco Unity Greetings and Messages Usages</li>
                        <li>Cisco Unity MailBox Message Expiry Time</li>
                        <li>Cisco Unity MailBox State</li>
                        <li>Cisco Unity Mailbox Messages</li>
                        <li>Cisco Unity Mailbox Time Usage</li>
                        <li>Cisco Unity Mailboxes 90% Full</li>
                        <li>Cisco Unity Messages Usages</li>
                        <li>Cisco Unity Voicemail PIN Stats</li>
                        <li>Default Greeting Size</li>
                        <li>Default Mailbox Size</li>
                        <li>Default Message Expiration</li>
                        <li>Default Message Length</li>
                        <li>Failed Voicemail PIN Attempts - Bad Password</li>
                        <li>Failed Voicemail PIN Attempts - Bad UserID</li>
                        <li>GUI Login Incorrect Password rejected attempts</li>
                        <li>GUI Login Unknown Username rejected attempts</li>
                        <li>General Delivery Mailboxes</li>
                        <li>Greeting Count Total</li>
                        <li>Greeting Time Total</li>
                        <li>Greetings and Messages Total</li>
                        <li>Greetings and Messages Total Usage</li>
                        <li>Maximum Licensed Ports</li>
                        <li>Messages Count Total</li>
                        <li>Messages Time Total</li>
                        <li>Number of Mailboxes 90% Full</li>
                        <li>Orphaned Mailboxes</li>
                        <li>Personal Mailboxes</li>
                        <li>Total GUI Login Attempts</li>
                        <li>Voicemail PIN password access attempts</li>
                        <li>Voicemail PIN password resets</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Unity</strong></p>
                    <ul>
                        <li>Status</li>
                        <li>Cisco Unity License Usage</li>
                        <li>Cisco Unity Port Usage</li>
                        <li>Cisco Unity Server Status</li>
                        <li>Number Of Licensed Subscribers</li>
                        <li>Number Of Ports In Use (Inbound)</li>
                        <li>Number Of Ports In Use (Outbound)</li>
                        <li>Number Of Ports In Use (Total)</li>
                        <li>Unity Server Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Cisco Voice Portal</strong></p>
                    <ul>
                        <li>Active Calls (Real Time)</li>
                        <li>Active H.323 Calls (Real Time)</li>
                        <li>Active ICM Calls (Real Time)</li>
                        <li>Active IVR Calls (Real Time)</li>
                        <li>Active SIP Calls (Real Time)</li>
                        <li>323 to ICM Latency (ms)</li>
                        <li>IVR New Call Latency (ms)</li>
                        <li>New Call Latency (ms)</li>
                        <li>New IVR Calls (Aggregate)</li>
                        <li>Port License Requests Denied</li>
                        <li>Port License State</li>
                        <li>Port Licenses Available</li>
                        <li>Port Licenses State</li>
                        <li>Service Status</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Convedia</strong></p>
                    <ul>
                        <li>Convedia: ACS Usage</li>
                        <li>Convedia: Active Announcements</li>
                        <li>Convedia: Active Conferences</li>
                        <li>Convedia: Active Media Ports</li>
                        <li>Convedia: Active Recordings</li>
                        <li>Convedia: Card CPU Utilization</li>
                        <li>Convedia: DSP CPU Utilization</li>
                        <li>Convedia: DSP Interarrival Jitter</li>
                        <li>Convedia: DSP Statistics</li>
                        <li>Convedia: Media Ports</li>
                        <li>Convedia: SIP Command Rate</li>
                        <li>Convedia: SIP Errors</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; MS Lync</strong></p>
                    <ul>
                        <li>Operations - MEDIA Global health</li>
                        <li>Planning - MEDIA Number of audio channels started</li>
                        <li>Planning - MEDIA Number of conferences started</li>
                        <li>Planning - MEDIA Number of conferences with NORMAL health</li>
                        <li>Planning - MEDIA Number of conferences with OVERLOADED health</li>
                        <li>Planning - MEDIA Number of data channels started</li>
                        <li>Planning - MEDIA Number of video channels started</li>
                        <li>Private - MEDIA Average Conference Processing Time</li>
                        <li>Private - MEDIA Average Transport Processing Time</li>
                        <li>Private - MEDIA Number of packets dropped in transport</li>
                        <li>Sip Dialogs - SipEps Active Dialogs</li>
                        <li>Sip Dialogs - SipEps Dialogs Created</li>
                        <li>SipEps Connections - SipEps Outgoing Connections</li>
                        <li>SipEps Transactions - SipEps Active Transactions</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Mitel Ipera</strong></p>
                    <ul>
                        <li>Category Alarm Level</li>
                        <li>Category Resources Available</li>
                        <li>Category Resources Unavailable</li>
                        <li>Last Known Reset Cause</li>
                        <li>Mitel Ipera: Alarm</li>
                        <li>Mitel Ipera: Alarm Category Count</li>
                        <li>Mitel Ipera: Category Resources</li>
                        <li>Mitel Ipera: Licences</li>
                        <li>Mitel Ipera: Reset Cause</li>
                        <li>Mitel Ipera: Resiliency State</li>
                        <li>Number of Alarm Categories</li>
                        <li>Overall Alarm Level</li>
                        <li>Resiliency Status</li>
                        <li>User Licenses Purchased</li>
                        <li>User Licenses Used</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; Kagoor</strong></p>
                    <ul>
                        <li>Active Calls</li>
                        <li>CPU Usage</li>
                        <li>Average Call Duration</li>
                        <li>Ended Calls (last 15m)</li>
                        <li>Packets Thru Classifier</li>
                        <li>License Rejected Calls</li>
                        <li>Rejected Calls</li>
                        <li>Bandwidth Rejected Calls</li>
                        <li>VoIP Packets</li>
                        <li>VoIP Packets Exchanged</li>
                        <li>VoIP Traffic</li>
                        <li>VoIP Traffic Volume</li>
                    </ul>
                    <h3>11.&nbsp;&nbsp;&nbsp; NCM Support</h3>
                    <p>Traverse provides a pre-integrated Network Configuration Management module that enables backup, restore, and tracking of changes in network device configurations across the enterprise network. Included below are devices for which Traverse provides out-of-the-box support.</p>
                    <p><strong>Supported Devices</strong></p>
                    <p><strong>&nbsp;&nbsp;&nbsp; 3COM</strong></p>
                    <ul>
                        <li>SuperStack 3 Switch 3300</li>
                        <li>SuperStack II</li>
                        <li>CoreBuilder</li>
                        <li>SuperStack II Switch 4400</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; CISCO</strong></p>
                    <ul>
                        <li>Cisco 3000 Series VPN Concentrators</li>
                        <li>Cisco Aironet with VxWorks</li>
                        <li>Cisco ACNS devices</li>
                        <li>Cisco Catalyst Switches running CatOS</li>
                        <li>Cisco Content Services Switches</li>
                        <li>ArrowPoint Load Balancers</li>
                        <li>Cisco CS500 Terminal Servers</li>
                        <li>Cisco Linksys RV042 VPN Routers</li>
                        <li>Cisco LocalDirector Load Balancers</li>
                        <li>Cisco Security Appliances (PIX, ASA, FWSM)</li>
                        <li>Cisco WideArea Application Services devices</li>
                        <li>Cisco Ironports</li>
                        <li>Cisco SF-300</li>
                        <li>Cisco Wireless LAN controller</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; DELL</strong></p>
                    <ul>
                        <li>Dell PowerConnect Switches</li>
                        <li>Dell Force 10</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; ENTERASYS</strong></p>
                    <ul>
                        <li>Enterasys Vertical Horizon Switches</li>
                        <li>Enterasys XPedition Switch Routers</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; EXTREME</strong></p>
                    <ul>
                        <li>Extreme Extremeware</li>
                        <li>Extreme XOS</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; F5</strong></p>
                    <ul>
                        <li>F5 3DNS WideArea Load Balancers</li>
                        <li>BIGIP Local Area Load Balancers</li>
                        <li>F5 BIGIP Load Balancers</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; FOUNDRY</strong></p>
                    <ul>
                        <li>Foundry FastIron</li>
                        <li>Foundry ServerIron</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; HP</strong></p>
                    <ul>
                        <li>HP ProCurve M Series Switches</li>
                        <li>HP ProCurve switches</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; JUNIPER</strong></p>
                    <ul>
                        <li>Juniper JUNOS</li>
                        <li>Juniper ScreenOS (Netscreen)</li>
                        <li>NORTEL</li>
                        <li>Nortel Accelar device information parser</li>
                        <li>Nortel BayRS Routers</li>
                        <li>Nortel BayStack</li>
                        <li>Nortel Contivity VPN Switches</li>
                        <li>Nortel Passport 1600 Series Switches</li>
                        <li>Nortel Passport Switches</li>
                        <li>Nortel Tiara</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; OTHER</strong></p>
                    <ul>
                        <li>Acme Packet Controllers</li>
                        <li>Adtran NetVanta</li>
                        <li>Alcatel OmniSwitch OS</li>
                        <li>Alteon AD3 Load Balancers</li>
                        <li>APC SmartUPS</li>
                        <li>Aruba Switches</li>
                        <li>Brocade Switches</li>
                        <li>Marconi ATM Switches</li>
                        <li>Riverbed Steelhead</li>
                        <li>Radware Linkproof</li>
                        <li>Radware AppDirector</li>
                        <li>SilverPeak NX</li>
                        <li>Vyatta Open Flexible Routers (OFR)</li>
                    </ul>
                    <p><strong>&nbsp;&nbsp;&nbsp; FIREWALLS</strong></p>
                    <ul>
                        <li>CheckPoint SecurePlatform</li>
                        <li>Cisco IPS</li>
                        <li>Cisco ASA</li>
                        <li>Cisco PIX</li>
                        <li>Cisco FWSM</li>
                        <li>Nokia CheckPoint</li>
                        <li>Juniper NetScreen</li>
                        <li>Palo Alto Networks</li>
                    </ul>
                </div>
                <div className="article__attachments">

                </div>
            </article>

           
        </div>
    )
}