import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import axios from 'axios';

//components
import { API_URL } from '../../constants';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FeaturedCourses from '../GenericComponents/FeaturedCourses';
import Clients from '../GenericComponents/Clients';
import Documentation from '../Pages/Documentation';

class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            campaigns: [],
            isLoading: false
        }
    }

    render() {

        const silde_1 = require('../../assets/images/weoffer/python.png');
        const silde_2 = require('../../assets/images/weoffer/hadoop.jpg');
        const silde_3 = require('../../assets/images/weoffer/machine-learning.jpg');
        const silde_4 = require('../../assets/images/weoffer/aws.jpg');
        const silde_5 = require('../../assets/images/weoffer/dataiku.png');
        const silde_6 = require('../../assets/images/weoffer/snowflake.jpeg');
        const silde_7 = require('../../assets/images/weoffer/azure.png');
        const Slider1 = require('../../assets/images/slider/wepik-photo-mode-2022922-114914.png');
        const Slider2 = require('../../assets/images/slider/h2-sl2.jpg');

        const weofferlist = [
            { title: "Python", description: "You'll learn how to use functions, methods, and packages to efficiently leverage the code that brilliant Python developers have written. The goal is to reduce the amount of code you need to solve challenging problems", src: silde_1 },
            { title: "Hadoop", description: "Hadoop is an open source framework that is used to efficiently store and process large datasets ranging in size from gigabytes to petabytes of data. Instead of using one large computer to store and process the data, Hadoop allows clustering multiple computers to analyze massive datasets in parallel more quickly.", src: silde_2 },
            { title: "Machine Learning", description: "We are a team of experienced professionals who are determined to provide efficient IT solutions. Our team of ML engineers has always got your back. Our engineers are qualified, well-trained, and experienced Machine Learners with a strong sense of heavy data usage.", src: silde_3 },
            { title: "AWS", description: "If you are familiar with Amazon Web Services, well and good. But if you’re still searching for some answers, you’ve landed on the right page. We are here to guide you through and deliver all the latest knowledge. Having extensive exposure to AWS, we can assist you with the most advanced AWS services and guidance. ", src: silde_4 },
            { title: "Dataiku", description: "Dataiku - a contemporary sensation is recognized as a podium that systematizes not only the use of Artificial Intelligence (AI) but also data that bring people together to consign incredible business results. It is an integrated virtual environment where users work as they please.", src: silde_5 },
            { title: "Snowflake", description: "Snowflake is a cloud-backed data warehouse platform that reduces the work of Database Administrators. It boosts your data adoption techniques by providing apt resources and task automation. Maintaining data platforms, especially on the cloud becomes complex. The Snowflake administration does this task for you.", src: silde_6 },
            { title: "Microsoft Azure", description: "Microsoft Azure is a public cloud platform with more than 200 products and many services accessible over the internet.", src: silde_7 },
        ]

        return (
            <>
                <div>

                    {/*Preloader start here*/}
                    <div id="pre-load">
                        <div id="loader" className="loader">
                        <div className="loader-container">
                            <div className="loader-icon"><img src="assets/images/fav.png" alt="VSIT Consulting Business" /></div>
                        </div>
                        </div>              
                    </div>
                    {/*Preloader area end here*/}

                    {/* Slider Start */}
                    <div id="rs-slider" className="rs-slider slider3">
                        <div className="bend niceties">
                            <div id="nivoSlider" className="slides">
                                <img src={Slider1} alt="" title="#slide-1" />
                                <img src={Slider2} alt="" title="#slide-2" />
                            </div>
                            {/* Slide 1 */}
                            <div id="slide-1" className="slider-direction">
                                <div className="content-part">
                                    <div className="container">
                                        {/* <div className="slider-des">
                                            <div className="sl-subtitle">A project that functions </div>
                                            <h1 className="sl-title">VSIT LLC  is equipped with <br/> the latest technology</h1>
                                        </div>
                                        <div className="desc">We fuse innovation and business plans to create <br />excellent business solutions!</div>
                                        <div className="slider-bottom ">
                                            <a className="readon consultant slider">Discover More</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* Slide 2 */}
                            <div id="slide-2" className="slider-direction">
                                <div className="content-part">
                                    <div className="container">
                                        <div className="slider-des">
                                            <div className="sl-subtitle">Our team extensively works to build <br/>data-driven technology for you</div>
                                            <h1 className="sl-title">Trusting a random <br />IT solutions</h1>
                                        </div>
                                        <div className="desc">We take pride in claiming that we are no ordinary firm.</div>
                                        <div className="slider-bottom ">
                                            <a className="readon consultant">Discover More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slider End */}

                     {/* About Section Start */}
                     <div className="rs-about style1 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="row y-middle">
                                <div className="col-lg-6 pr-70 md-pr-15 md-mb-50">
                                    <div className="sec-title2 mb-30">
                                        <div className="sub-text">Who We Are</div>
                                        <h2 className="title mb-23">We take full responsibility for planning and creating <span>data solutions.</span></h2>
                                        <p className="desc mb-0">VSIT LLC  is a team of specialists who endlessly work to deliver the best IT solutions. Our deliverables are closely analyzed and engineered by well-trained data specialists and engineers. Whatever be the requirement, we are ready to take the challenge. </p>
                                    </div>
                                    {/* Skillbar Section Start */}
                                    <div className="rs-skillbar style1">
                                        <div className="cl-skill-bar">
                                            {/* Start Skill Bar */}
                                            <span className="skillbar-title">Business Consulting</span>
                                            <div className="skillbar" data-percent={90}>
                                                <p className="skillbar-bar" />
                                                <span className="skill-bar-percent" />
                                            </div>
                                            {/* Start Skill Bar */}
                                            <span className="skillbar-title">Immigration Advices</span>
                                            <div className="skillbar" data-percent={99}>
                                                <p className="skillbar-bar" />
                                                <span className="skill-bar-percent" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-45">
                                            <div className="btn-part">
                                                <Link className="readon consultant discover" to="/contact-us">Discover More</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Skillbar Section End */}
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-img">
                                        <img src="assets/images/about/about-2.png" alt="images" />
                                        <div className="working-experiance">
                                            <div className="experience-year">
                                                <div className="count-year plus"><span className="sub-text">25+</span></div>
                                                <h4 className="title mb-0">Years Experience</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* About Section Start */}

                    
                    {/* Premium Services Section Start */}
                    <div id="rs-services" className="rs-services style2 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="sec-title2 d-flex align-items-center mb-60 md-mb-40">
                                <div className="first-half">
                                    <div className="sub-text">We Hire</div>
                                    <h2 className="title mb-0 md-pb-20">Award Winning company that <span>excels in hiring process..</span></h2>
                                </div>
                                <div className="last-half">
                                    <p className="desc mb-0 pl-20 md-pl-15">Having experience of over 11 years, VSIT LLC  has served more than 5000 clients. We have high expertise in providing efficient business and IT solutions. We employ the most trusted AI and ML techniques to make the work easy for you.</p>
                                </div>
                            </div>
                            <div className="rs-carousel owl-carousel" data-loop="true" data-items={3} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="true" data-nav="false" data-nav-speed="false" data-md-device={3} data-md-device-nav="false" data-md-device-dots="true" data-center-mode="false" data-ipad-device2={2} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-ipad-device={2} data-ipad-device-nav="false" data-ipad-device-dots="true" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="true">
                                {weofferlist.map(data => {
                                    return (
                                        <div className="service-wrap">
                                            <div className="image-part">
                                                <img src={data.src} alt="" />
                                            </div>
                                            <div className="content-part">
                                                <h3 className="title"><a href="">{data.title}</a></h3>
                                                <div className="desc">{data.description}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* Premium Services Section End */}                    

                   
                    {/* Partner Section Start */}
                    <div className='rs-patter-section pt-50 pb-50'>
                        <Clients/>
                    </div>
                    {/* Partner Section End */}


                    {/* Services Section Start */}
                    <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="sec-title2 text-center md-left mb-40">
                                {/* <div className="sub-text">Why Choose Us</div> */}
                                <h2 className="title">Our Services helps you expand your  &amp; <br /><span>business.</span></h2>
                                <p>Trust our services and boost up your business. We confidently <br />deliver the best services in the market. Our wide range of services <br />helps you expand your business and customer base. </p>
                            </div>
                            <div className="row y-middle">
                                <div className="col-lg-6  md-mb-50 pr-30 md-pr-l5">
                                    <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/ai.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="ai-strategy">AI Strategy</Link></h3>
                                            <p className="services-txt">Our specialists provide you with the best AI strategies that can help build AI-generated decision-making software. This minimizes manpower and delivers effective results in less time</p>
                                        </div>
                                    </div>
                                    <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style3/2.png" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="data-strategy">Data Strategy</Link></h3>
                                            <p className="services-txt"> Get extensively planned data strategies for enhanced results. Our expert Data Strategists take full responsibility for delivering an effective strategy to set up the best data platforms and pipelines.</p>
                                        </div>
                                    </div>
                                    <div className="services-item">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/snowflake.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="snowflake-administration">Snowflake Administration</Link></h3>
                                            <p className="services-txt">We can build cloud-backed data warehouse platforms to minimize the load on Database Administrators. With VSIT LLC , get the best assistance in developing highly proficient and responsive cloud-backed data warehouses.</p>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 pl-40 md-pl-15">
                                    <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/ds.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="data-science">Data Science</Link></h3>
                                            <p className="services-txt"> Whether you need help with Predictive analytics or any other technique of data sciences, we are here to support you. VSIT LLC  is experienced in delivering effective tools and software for rapid business growth. </p>
                                        </div>
                                    </div>
                                    <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/aw.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="home">Award winning</Link></h3>
                                            <p className="services-txt">VSIT LLC  is an award-winning company that excels in customer satisfaction. We prioritize our customers and that is what makes us unique.</p>
                                        </div>
                                    </div>
                                    <div className="services-item">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/de.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="data-engineering">Data Engineering</Link></h3>
                                            <p className="services-txt">Data Engineers at VSIT LLC  manage and transform raw data to that of high value. They assist in helping you build strong and effective cloud platforms.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-50 d-flex justify-content-center">
                                    <div className="rs-videos choose-video">
                                        <div className="images-video">
                                            <img src="assets/images/choose/choose-2.png" alt="images" />
                                        </div>
                                        <div className="animate-border">
                                            <a className="popup-border" href="https://www.youtube.com/watch?v=ad79nYk2keg">
                                                <i className="fa fa-play" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Counter Section End */}
                        <div className="rs-counter style1">
                            <div className="container">
                                <div className="counter-border-top">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                                            <div className="counter-area">
                                                <div className="counter-list mb-20">
                                                    <div className="counter-icon">
                                                        <img src="assets/images/counter/icons/1.png" alt="Counter" />
                                                    </div>
                                                    <div className="counter-number">
                                                        <span className="rs-count">582</span>
                                                    </div>
                                                </div>
                                                <div className="content-part">
                                                    <h5 className="title">Projects completed for our respected clients.</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                                            <div className="counter-area">
                                                <div className="counter-list mb-20">
                                                    <div className="counter-icon">
                                                        <img src="assets/images/counter/icons/2.png" alt="Counter" />
                                                    </div>
                                                    <div className="counter-number">
                                                        <span className="rs-count">215</span>
                                                        <span className="prefix">+</span>
                                                    </div>
                                                </div>
                                                <div className="content-part">
                                                    <h5 className="title">Experienced people serving to clients.</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
                                            <div className="counter-area">
                                                <div className="counter-list mb-20">
                                                    <div className="counter-icon">
                                                        <img src="assets/images/counter/icons/3.png" alt="Counter" />
                                                    </div>
                                                    <div className="counter-number">
                                                        <span className="rs-count">25</span>
                                                        <span className="prefix">+</span>
                                                    </div>
                                                </div>
                                                <div className="content-part">
                                                    <h5 className="title">Years experience in business &amp; consulting.</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="counter-area">
                                                <div className="counter-list mb-20">
                                                    <div className="counter-icon">
                                                        <img src="assets/images/counter/icons/4.png" alt="Counter" />
                                                    </div>
                                                    <div className="counter-number">
                                                        <span className="rs-count">70</span>
                                                        <span className="prefix">+</span>
                                                    </div>
                                                </div>
                                                <div className="content-part">
                                                    <h5 className="title">Business &amp; consulting awards won over world.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Counter Section End */}
                    </div>
                    {/* Services Section End */}
                    {/* Project Section Start */}
                    {/* <div className="rs-project style7 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container custom">
                            <div className="row y-middle">
                                <div className="col-lg-6">
                                    <div className="sec-title2 mb-55 md-mb-30">
                                        <div className="sub-text">Recent Work</div>
                                        <h2 className="title mb-23">We blend business ideas to create something <span>awesome.</span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-60 md-pl-15 mb-30">
                                    <p className="desc mb-0">Having experience of over 11 years, VSIT LLC  has served more than 5000 clients. We have high expertise in providing efficient business and IT solutions. We employ the most trusted AI and ML techniques to make the work easy for you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid pl-30 pr-30">
                            <div className="rs-carousel owl-carousel" data-loop="true" data-items={4} data-margin={30} data-autoplay="false" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={4} data-md-device-nav="false" data-md-device-dots="true" data-center-mode="false" data-ipad-device2={2} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-ipad-device={2} data-ipad-device-nav="false" data-ipad-device-dots="true" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="true">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/1.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Business planning</a></h3>
                                            <span className="category"><a href="">Investment</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/2.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Strength solutions</a></h3>
                                            <span className="category"><a href="">Investment</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/3.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Business analytics</a></h3>
                                            <span className="category"><a href="">Business Strategy</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/4.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Stock market analysis</a></h3>
                                            <span className="category"><a href="">Business Strategy</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/5.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Sales analysis</a></h3>
                                            <span className="category"><a href="">Financial</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/6.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Stock investments</a></h3>
                                            <span className="category"><a href="">Tax Consulting</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/7.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Advertising Technology</a></h3>
                                            <span className="category"><a href="">Business Strategy</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src="assets/images/project/style2/1.jpg" alt="images" />
                                    </div>
                                    <div className="project-content">
                                        <div className="project-inner">
                                            <h3 className="title"><a href="">Business planning</a></h3>
                                            <span className="category"><a href="">Investment</a></span>
                                        </div>
                                        <a className="p-icon" href=""><i className="flaticon-right-arrow" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Project Section End */}
                    {/* Process Section Start */}
                    {/* <div className="rs-process style1 bg2 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="row y-middle">
                                <div className="col-lg-5">
                                    <div className="sec-title2 md-text-center">
                                        <div className="sub-text">Working Process</div>
                                        <h2 className="title mb-23 white-color">How we work for our valued  <span>professionals.</span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="btn-part text-right md-text-center">
                                        <a className="readon consultant discover" href="">View Works</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container custom2">
                            <div className="process-effects-layer">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 md-mb-30">
                                        <div className="rs-addon-number">
                                            <div className="number-part">
                                                <div className="number-image">
                                                    <img src="assets/images/process/style1/1.png" alt="Process" />
                                                </div>
                                                <div className="number-text">
                                                    <div className="number-area"> <span className="number-prefix"> 1 </span></div>
                                                    <div className="number-title"><h3 className="title"> Discovery</h3></div>
                                                    <div className="number-txt">
                                                    We thoroughly analyze your business workflow and determine the requirements. Keeping all the necessary factors in mind, we discover the aspects that need to be worked on. 

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 md-mb-30">
                                        <div className="rs-addon-number">
                                            <div className="number-part">
                                                <div className="number-image">
                                                    <img src="assets/images/process/style1/2.png" alt="Process" />
                                                </div>
                                                <div className="number-text">
                                                    <div className="number-area"> <span className="number-prefix"> 2 </span></div>
                                                    <div className="number-title"><h3 className="title">Planning</h3></div>
                                                    <div className="number-txt">
                                                    Once we are done with the analysis, the experts at VSIT LLC  develop an effective business plan for 100% positive results. 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 sm-mb-30">
                                        <div className="rs-addon-number">
                                            <div className="number-part">
                                                <div className="number-image">
                                                    <img src="assets/images/process/style1/3.png" alt="Process" />
                                                </div>
                                                <div className="number-text">
                                                    <div className="number-area"> <span className="number-prefix"> 3 </span></div>
                                                    <div className="number-title"><h3 className="title">Execute</h3></div>
                                                    <div className="number-txt">
                                                    We don’t let the plan die in the PowerPoint presentation. The plan and strategy developed by our experts are taken a step further by our experienced Data analysts, engineers, and architects. 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="rs-addon-number">
                                            <div className="number-part">
                                                <div className="number-image">
                                                    <img src="assets/images/process/style1/4.png" alt="Process" />
                                                </div>
                                                <div className="number-text">
                                                    <div className="number-area"> <span className="number-prefix"> 4 </span></div>
                                                    <div className="number-title"><h3 className="title">Deliver</h3></div>
                                                    <div className="number-txt">
                                                    Upon the completion of a task, we deliver the content right on time. Hop on board and you will love our punctuality. 

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Process Section End */}
                    {/* Blog Start */}
                    <div className="rs-blog style2 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="row y-middle md-mb-30">
                                <div className="col-lg-5 mb-20 md-mb-10">
                                    <div className="sec-title2">
                                        {/* <div className="sub-text">News Updates</div> */}
                                        <h2 className="title mb-23">What makes us &amp; best <span>and unique?  &amp; </span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-7 mb-20">
                                    <div className="btn-part text-right md-left">
                                        <a className="readon consultant discover" href="">View Updates</a>
                                    </div>
                                </div>
                            </div>
                            <div className="rs-carousel owl-carousel" data-loop="true" data-items={3} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="true" data-ipad-device={2} data-ipad-device-nav="false" data-ipad-device-dots="true" data-ipad-device2={2} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-md-device={3} data-md-device-nav="false" data-md-device-dots="true">
                                <div className="blog-item">
                                    {/* <div className="image-wrap">
                                        <a href="#"><img src="assets/images/blog/1.jpg" alt="Blog" /></a>
                                        <ul className="post-categories">
                                            <li><a href="">Branding</a></li>
                                        </ul>
                                    </div> */}
                                    <div className="blog-content">
                                        {/* <ul className="blog-meta mb-10">
                                            <li className="admin"> <i className="fa fa-user-o" /> admin</li>
                                            <li className="date"> <i className="fa fa-calendar-check-o" /> 16 Aug 2021</li>
                                        </ul> */}
                                        <h3 className="blog-title"><a href="">Trusting a random IT solutions</a></h3>
                                        <p>Trusting a random IT solutions provider with your company’s data isn’t easy. We take pride in claiming that we are no ordinary firm. Our team extensively works to build data-driven technology for you. With our strongly developed technology and expert professionals, we aim to turn your data into something meaningful.</p>
                                    </div>
                                </div>
                                <div className="blog-item">
                                    {/* <div className="image-wrap">
                                        <a href="#"><img src="assets/images/blog/2.jpg" alt="Blog" /></a>
                                        <ul className="post-categories">
                                            <li><a href="">Branding</a></li>
                                        </ul>
                                    </div> */}
                                    <div className="blog-content">
                                        {/* <ul className="blog-meta mb-10">
                                            <li className="admin"> <i className="fa fa-user-o" /> finance</li>
                                            <li className="date"> <i className="fa fa-calendar-check-o" /> 09 Sep 2021</li>
                                        </ul> */}
                                        <h3 className="blog-title"><a href="">A project that functions</a></h3>
                                        <p>Most data plans and strategies die at the initial stage because of a lack of resources. VSIT LLC  is equipped with the latest technology and architecture that not only helps in planning strategies but also puts them in action. </p>
                                    </div>
                                </div>

                                <div className="blog-item">
                                    {/* <div className="image-wrap">
                                        <a href="#"><img src="assets/images/blog/2.jpg" alt="Blog" /></a>
                                        <ul className="post-categories">
                                            <li><a href="">Branding</a></li>
                                        </ul>
                                    </div> */}
                                    <div className="blog-content">
                                        {/* <ul className="blog-meta mb-10">
                                            <li className="admin"> <i className="fa fa-user-o" /> developer</li>
                                            <li className="date"> <i className="fa fa-calendar-check-o" /> 18 Oct 2021</li>
                                        </ul> */}
                                        <h3 className="blog-title"><a href="">A strong foundation</a></h3>
                                        <p>With our carefully planned strategies, we aim to build a sturdy foundation to help your business grow more rapidly. We take help from AI and ML to design a plan that assists your company’s future requirements as well. </p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    {/* Blog End */}
                    {/* Testimonial Section Start */}
                    {/* <div className="rs-testimonial style2 bg10 pt-50 pb-50 md-pt-50 md-pb-50">
                        <div className="container">
                            <div className="sec-title2 text-center md-left mb-30">
                                <div className="sub-text">Right choice!</div>
                                <h2 className="title mb-0 white-color">What do our customers think<br /> about us?</h2>
                            </div>
                            <div className="rs-carousel owl-carousel" data-loop="true" data-items={3} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="true" data-ipad-device={2} data-ipad-device-nav="false" data-ipad-device-dots="true" data-ipad-device2={2} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-md-device={3} data-md-device-nav="false" data-md-device-dots="true">
                                <div className="testi-wrap">
                                    <div className="item-content">
                                        <span><img src="assets/images/testimonial/quote.png" alt="Testimonial" /></span>
                                        <p>The different tools and technologies used in Machine Learning are not easy to understand. Being new to this field, I found it challenging to find my firm’s requirements. But VSIT LLC  made me understand these basics very easily. They have a helpful team that can assist you with everything. </p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/1.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">David</div>
                                            <span className="testi-title">Full Stack developer</span>
                                            <div className="ratting-img">
                                                <img src="assets/images/testimonial/ratting.png" alt="Testimonial" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-wrap">
                                    <div className="item-content">
                                        <span><img src="assets/images/testimonial/quote.png" alt="Testimonial" /></span>
                                        <p>The best thing about VSIT LLC  is their team. Whatever help you need from them they are always available. They even helped me find the best practices for my website.</p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/2.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Emily Blunt</div>
                                            <span className="testi-title">Web Developer</span>
                                            <div className="ratting-img">
                                                <img src="assets/images/testimonial/ratting.png" alt="Testimonial" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-wrap">
                                    <div className="item-content">
                                        <span><img src="assets/images/testimonial/quote.png" alt="Testimonial" /></span>
                                        <p>VSIT LLC  helped me set up a cloud platform. I once faced some backend issues. But as soon as I called their customer support, the issue was resolved in 20 minutes. Thanks to customer support, my problem was solved in such a short time.</p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/3.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Ansu Fati</div>
                                            <span className="testi-title">Marketing</span>
                                            <div className="ratting-img">
                                                <img src="assets/images/testimonial/ratting.png" alt="Testimonial" />
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                           
                            </div>
                        </div>
                    </div> */}
                    {/* Testimonial Section End */}                   
                </div>
            </>
        )
    }

}


export default Home;