import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function Snowflake() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img15 aws-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            About Snowflake & Administration

                            <span className="watermark">snowflake</span>
                        </h1>
                        <span className="sub-text">Snowflake is a cloud-backed data warehouse platform that reduces the work of Database Administrators. It boosts your data adoption techniques by providing apt resources and task automation. Maintaining data platforms, especially on the cloud becomes complex. The Snowflake administration does this task for you.

                        </span>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}

            <div id="rs-about" className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70">
                <div className="container">
                    <div className="row y-middle">

                        <div className="col-lg-12 pl-46 md-pl-15">
                            <div className="sec-title3">
                                <h2 className="title pb-30">
                                    How does Snowflake profit organisations?
                                </h2>
                                {/* <p className="margin-0 pb-40">We provide the best Dataiku guidance to our clients. Dataiku implementation and execution is made easy with VSIT LLC . </p> */}
                                <ul className="icon-item">
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>24x7 monitoring:</b> Snowflake provides continuous monitoring of platforms to make work efficient for developers and data analysts. </span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Reduced cost:</b> Enables you to save money on platform administration</span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text"><b>Constant support:</b> Get complete support while implementing new, complex, and bulky data.</span>
                                    </li>
                                </ul>

                            </div>
                        </div>


                        <div className="col-lg-12 pl-46 md-pl-15 mt-50">
                            <div className="sec-title3">
                                <h2 className="title pb-30">
                                    Do you need a Snowflake administrator?
                                </h2>
                                {/* <p className="margin-0 pb-40">We provide the best Dataiku guidance to our clients. Dataiku implementation and execution is made easy with VSIT LLC . </p> */}
                                <ul className="icon-item">
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">Technology keeps on evolving with time. The various tools and techniques have developed a lot in the past few years. A Database Administrator performs the task of keeping you aligned with the changing trends. </span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">VSIT LLC  takes this job seriously. Our highly-trained Snowflake administrators are always present at your service. A secure database is necessary for maintaining a company’s confidentiality. Your cloud platform needs to be safe from all kinds of malware and hackers.
                                        </span>
                                    </li>
                                    <li>
                                        <span className="list-icon">
                                            <i className="fa fa-check" />
                                        </span>
                                        <span className="list-text">Shifting from data centres to the Cloud is a big deal. It becomes even more difficult when you shift from a traditional database to the modern one. But our Snowflake administrators do this work for you. Their work complies with the online laws and ethics. You no longer need to worry about running out of space on the data centre as VSIT LLC  efficiently help you build a cloud platform.
                                        </span>
                                    </li>
                                </ul>

                            </div>
                        </div>


                        <div className="col-lg-12 pl-46 md-pl-15 mt-50">
                            <div className="sec-title3">
                                <h2 className="title pb-30">
                                    Why trust VSIT LLC  Inc?
                                </h2>
                                <p className="margin-0 pb-40">Trained specialists: Our team consists of highly-trained professionals. They are well-versed in their field and are data specialists. Our experts are tech-friendly people passionate about their work.
                                </p>

                                <p className="margin-0 pb-40">Modern technologies: In the age of development, new technologies are constantly introduced.

                                </p>

                                <p className="margin-0 pb-40">We quickly adapt these technologies so our clients do not lag behind. Our team extensively uses modern technologies to deliver outstanding services.

                                </p>

                                <p className="margin-0 pb-40"><b>Reasonable prices: </b>We deliver quality services at reasonable prices. Our charges are decided as per the requirement of our customers. We take pride in charging only the justified amount so both parties gain profit.


                                </p>

                                <p className="margin-0 pb-40"><b>Snowflake guide:</b> It is not easy to learn everything about a tool or technology. One can’t instantly know everything about the different Snowflake practices. But we intend to accustom you to the various Snowflake practices through our guide. This provides all the needed assistance while creating a Snowflake Platform.


                                </p>


                            </div>
                        </div>

                        <div className="col-lg-12 md-mb-50">
                            <div className="images-part mt-30">
                                <img src="assets/images/pages/snowflake/1.png" alt="Images" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Services Section End */}
            <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            Snowflake assistance at VSIT LLC  Inc.

                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-50">
                            <div className="services-item">
                                {/* <div className="services-icon">
                          <img src="assets/images/services/style3/2.png" alt="Services">
                      </div> */}
                                <div className="services-content">
                                    <h2 className="services-title"><a>What do we offer?</a></h2>
                                    <p className="services-txt">Our team at VSIT LLC  Inc. has worked with multiple clients. With this experience, we know that companies have distinct requirements. They face challenges on different levels. Our experienced Snowflake administrators offer the following services: </p>
                                    <ul className="icon-item mt-20">
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">User outline and access administration</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Data platform cost management</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Automated platform development and recommendation</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Use of appropriate and suitable tools to support the Snowflake platform</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Properly organised data architecture and structure</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Continuously checking and managing Snowflake tools</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Troubleshooting platform related errors and problems</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Working connectivity between data sources and Snowflake platforms</span>
                                        </li>
                                        <li>
                                            <span className="list-icon">
                                                <i className="fa fa-check" />
                                            </span>
                                            <span className="list-text">Help you save cost as you pay only for the services you use</span>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* Services Section End */}
            <div className="rs-services style3 pt-100 pb-10">
                <div className="container">
                    <div className="sec-title mb-60">
                        <h2 className="title">
                            Why trust VSIT LLC  Inc?
                        </h2>
                        <p className="services-txt mt-30"><b>Trained specialists:</b> Our team consists of highly-trained professionals. They are well-versed in their field and are data specialists. Our experts are tech-friendly people passionate about their work. </p>
                        <p className="services-txt mt-30"><b>Modern technologies: </b> In the age of development, new technologies are constantly introduced. We quickly adapt these technologies so our clients do not lag behind. Our team extensively uses modern technologies to deliver outstanding services. </p>
                        <p className="services-txt mt-30"><b>Reasonable prices: </b> We deliver quality services at reasonable prices. Our charges are decided as per the requirement of our customers. We take pride in charging only the justified amount so both parties gain profit.  </p>
                        <p className="services-txt mt-30"><b>Snowflake guide:</b> It is not easy to learn everything about a tool or technology. One can’t instantly know everything about the different Snowflake practices. But we intend to accustom you to the various Snowflake practices through our guide. This provides all the needed assistance while creating a Snowflake Platform. </p>

                        <p className="services-txt">Contact VSIT LLC  for more information on Snowflake... </p>
                    </div>
                </div>
            </div>


            {/* Partner Section Start */}
            <div className="rs-patter-section black-bg pt-80 pb-75">
                <div className="container custom">
                    <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                            </a>
                        </div>
                        <div className="logo-img">
                            <a>
                                <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                                <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Partner Section End */}
        </div>
    )
}