import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CareersWrapper } from "../styles";

export default function Careers() {
  const [expandedJob, setExpandedJob] = useState(null);

  const toggleExpand = (jobId: any) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };
  const careersData = [
    {
      id: 1,
      designation: "Full Stack Developer",
      job_id: "V_5288",
      job_postedon: "28-02-2024",
      description:
        "We are looking to add a skilled Full Stack Developer to our vibrant team. You will be in charge of creating and managing front-end and back-end apps as a Full Stack Developer, making sure that they integrate seamlessly. You'll collaborate closely with our product and design teams to provide excellent, user-friendly software solutions.",
      responsibilities: [
        "Developing and maintaining web applications utilizing contemporary frameworks and technologies is your responsibility.",
        "Create, execute, and verify functionalities over the whole web development stack.",
        "Collaborate with cross-functional teams to define, develop, and implement new features.",
        "Applications should be optimized for optimum performance and scalability.",
        "Debug and troubleshoot problems to guarantee that apps run smoothly.",
      ],
      requiredSkills: [
        "Proficiency with front-end technologies, including HTML5, CSS3, JavaScript, and contemporary JavaScript frameworks (such as React, Angular, and Vue.js), is necessary.",
        "Solid experience developing back-end applications in Python, Java, and Node.js.",
        "Familiarity with both relational and non-relational databases, such as MongoDB and MySQL.",
        "Familiarity with server-side CSS preprocessors (such as Sass and Less).",
        "Knowledge of version control systems like Git.",
        "Comprehension of recommended practices for online security.",
        "Good communication and problem-solving abilities.",
        "Capacity to operate both individually and together in a hectic setting.",
      ],
    },
    {
      id: 2,
      designation: ".NET Developer",
      job_id: "V_5222",
      job_postedon: "20-02-2024",
      description:
        "We are looking to fill our team with a talented .NET Developer. Using the .NET framework, you will be in charge of creating and managing high-caliber software solutions as a .NET developer. Together with our development team, you will collaborate closely to produce scalable, reliable apps that satisfy our clients' demands.",
      responsibilities: [
        "Create, create, and manage online and desktop applications utilizing the .NET framework.",
        "Collaborate with cross-functional teams to develop, create, and deploy new features.",
        "Write clean, effective, and well-proved law that follows stylish practices.",
        "Test and debug apps to ensure they run smoothly and reliably.",
        "Participate in code reviews to verify that the code is high quality and consistent.",
        "Stay current on the newest technology and market trends in .NET development.",
      ],
      requiredSkills: [
        "Required skills include proficiency in C# and the .NET framework.",
        "Experience with ASP.NET MVC and/or ASP.NET Core.",
        "A solid comprehension of object-oriented programming (OOP) principles.",
        "Front-end technology knowledge, including HTML5, CSS3, JavaScript, and jQuery.",
        "Proficiency in SQL Server or similar relational database systems.",
        "Understanding of the software development lifecycle (SDLC) techniques.",
        "Excellent problem-solving and analytical abilities.",
        "Ability to operate both alone and collaboratively in a team setting.",
      ],
    },
    {
      id: 3,
      designation: "Java Developer",
      job_id: "V_2564",
      job_postedon: "02-02-2024",
      description:
        "We are seeking a skilled Java Developer to join our team. As a Java Developer, you will be responsible for developing and maintaining high-quality software solutions using Java and related technologies. You will collaborate with our development team to design and implement scalable, reliable, and efficient applications.",
      responsibilities: [
        "Design, develop, and maintain Java-based web applications.",
        "Implement new features and enhancements to existing applications.",
        "Work closely with cross-functional teams to understand requirements and deliver solutions.",
        "Write clean, efficient, and well-documented code following best practices.",
        "Test and debug applications to ensure optimal performance and reliability.",
        "Stay up-to-date with the latest technologies and industry trends in Java development.",
      ],
      requiredSkills: [
        "Proficiency in Java programming language.",
        "Experience with Spring Framework (Spring Boot, Spring MVC, Spring Security, etc.).",
        "Strong understanding of object-oriented programming (OOP) principles.",
        "Knowledge of web technologies such as HTML, CSS, and JavaScript.",
        "Experience with relational databases (e.g., MySQL, PostgreSQL).",
        "Familiarity with version control systems such as Git.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 4,
      designation: "SAP Developer",
      job_id: "V_3587",
      job_postedon: "02-02-2024",
      description:
        "We are looking for a skilled SAP Developer to join our team. As a SAP Developer, you will be responsible for developing and maintaining SAP solutions to meet business requirements. You will collaborate with our clients and internal teams to design, implement, and support SAP applications and integrations.",
      responsibilities: [
        "Design, develop, and configure SAP solutions based on business requirements.",
        "Customize and enhance existing SAP applications and functionalities.",
        "Collaborate with cross-functional teams to define, design, and implement SAP projects.",
        "Provide technical support and troubleshooting for SAP applications and integrations.",
        "Ensure the integrity and security of SAP systems and data.",
        "Stay up-to-date with the latest SAP technologies and best practices.",
      ],
      requiredSkills: [
        "Strong knowledge of SAP modules such as SAP ERP, SAP S/4HANA, SAP BW/4HANA, etc.",
        "Experience with SAP ABAP programming language.",
        "Understanding of SAP Fiori/UI5 development.",
        "Knowledge of SAP integration technologies (RFC, BAPI, IDoc, PI/PO, etc.).",
        "Ability to analyze and translate business requirements into technical solutions.",
        "Excellent problem-solving and communication skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 5,
      designation: "Sales Force Developer",
      job_id: "V_5468",
      job_postedon: "03-02-2024",
      description:
        "We are seeking a talented Sales Force Developer to join our team. As a Sales Force Developer, you will be responsible for designing, developing, and maintaining custom solutions on the Salesforce platform. You will work closely with our clients and internal teams to deliver high-quality Salesforce applications that meet business requirements.",
      responsibilities: [
        "Design, develop, and customize Salesforce solutions to meet business requirements.",
        "Implement and integrate Salesforce applications, modules, and features.",
        "Collaborate with stakeholders to define, design, and deliver Salesforce projects.",
        "Provide technical support and troubleshooting for Salesforce applications and integrations.",
        "Ensure data integrity and security within the Salesforce environment.",
        "Stay up-to-date with Salesforce technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in Salesforce development technologies such as Apex, Visualforce, Lightning Components.",
        "Experience with Salesforce configuration, customization, and integration.",
        "Knowledge of Salesforce CRM functionalities and modules.",
        "Understanding of Salesforce development best practices and design patterns.",
        "Ability to work independently and collaboratively in a team environment.",
        "Excellent problem-solving and communication skills.",
      ],
    },
    {
      id: 6,
      designation: "Data Engineer",
      job_id: "V_3457",
      job_postedon: "14-02-2024",
      description:
        "We are looking for a skilled Data Engineer to join our team. As a Data Engineer, you will be responsible for designing, building, and maintaining scalable data pipelines and systems. You will work closely with our data science and analytics teams to ensure efficient data processing and analysis.",
      responsibilities: [
        "Design, build, and maintain data pipelines and ETL processes.",
        "Implement data integration and transformation solutions for structured and unstructured data.",
        "Optimize data pipelines for performance, scalability, and reliability.",
        "Collaborate with data scientists and analysts to support their data needs.",
        "Ensure data quality, consistency, and integrity across various data sources and systems.",
        "Stay up-to-date with emerging technologies and best practices in data engineering.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Python, Java, or Scala.",
        "Experience with data processing frameworks such as Apache Spark, Apache Beam, or Apache Flink.",
        "Knowledge of distributed computing and parallel processing.",
        "Familiarity with cloud platforms and services (e.g., AWS, GCP, Azure).",
        "Understanding of relational and NoSQL databases.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    // Additional designations
    {
      id: 7,
      designation: "RPA Developer",
      job_id: "V_7895",
      job_postedon: "05-03-2024",
      description:
        "We are seeking a skilled RPA Developer to join our team. As an RPA Developer, you will be responsible for designing, developing, and deploying robotic process automation solutions. You will collaborate with stakeholders to identify automation opportunities and implement efficient and scalable RPA solutions.",
      responsibilities: [
        "Design, develop, and deploy robotic process automation (RPA) solutions using tools like UiPath, Automation Anywhere, or Blue Prism.",
        "Analyze business processes to identify automation opportunities and develop RPA solutions to streamline workflows.",
        "Collaborate with business analysts and stakeholders to gather requirements and define RPA project scope.",
        "Test and debug RPA solutions to ensure accuracy, efficiency, and reliability.",
        "Provide training and support to end-users on RPA solutions.",
        "Stay up-to-date with the latest RPA technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in RPA tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Experience with programming languages such as C#, Python, or Java.",
        "Strong analytical and problem-solving skills.",
        "Ability to work independently and collaboratively in a team environment.",
        "Excellent communication and interpersonal skills.",
      ],
    },
    {
      id: 8,
      designation: "Data Analyst",
      job_id: "V_4563",
      job_postedon: "08-03-2024",
      description:
        "We are looking for a Data Analyst to join our team. As a Data Analyst, you will be responsible for interpreting data, analyzing results, and providing insights to inform business decisions. You will work closely with stakeholders to understand their data needs and develop data-driven solutions.",
      responsibilities: [
        "Collect, clean, and analyze large datasets to extract meaningful insights.",
        "Develop and maintain dashboards, reports, and visualizations to communicate findings to stakeholders.",
        "Identify trends, patterns, and correlations in data to support decision-making.",
        "Collaborate with cross-functional teams to define data requirements and priorities.",
        "Provide recommendations for process improvements and optimization based on data analysis.",
        "Stay up-to-date with data analysis techniques and tools.",
      ],
      requiredSkills: [
        "Proficiency in data analysis tools such as SQL, Excel, or Python.",
        "Experience with data visualization tools such as Tableau, Power BI, or Google Data Studio.",
        "Strong analytical and problem-solving skills.",
        "Ability to work with large datasets and perform complex data analysis.",
        "Excellent communication and presentation skills.",
        "Attention to detail and accuracy.",
      ],
    },
    {
      id: 9,
      designation: "BI Developer",
      job_id: "V_9632",
      job_postedon: "10-03-2024",
      description:
        "We are seeking a talented BI Developer to join our team. As a BI Developer, you will be responsible for designing, developing, and maintaining business intelligence solutions. You will work closely with stakeholders to understand their reporting needs and deliver actionable insights.",
      responsibilities: [
        "Design, develop, and maintain ETL processes to extract, transform, and load data into the data warehouse.",
        "Develop and maintain BI dashboards, reports, and visualizations using tools like Power BI, Tableau, or QlikView.",
        "Collaborate with business users to gather requirements and define BI project scope.",
        "Optimize BI solutions for performance, scalability, and usability.",
        "Provide training and support to end-users on BI tools and solutions.",
        "Stay up-to-date with BI technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in ETL tools such as SSIS, Informatica, or Talend.",
        "Experience with BI platforms such as Power BI, Tableau, or QlikView.",
        "Strong SQL skills for data extraction and manipulation.",
        "Knowledge of data modeling and data warehousing concepts.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 10,
      designation: "QA Engineer",
      job_id: "V_7412",
      job_postedon: "12-03-2024",
      description:
        "We are looking for a talented QA Engineer to join our team. As a QA Engineer, you will be responsible for ensuring the quality and reliability of our software products. You will work closely with developers and product managers to design and execute test plans, identify defects, and drive resolution.",
      responsibilities: [
        "Design and execute test plans, test cases, and test scripts to ensure the quality and reliability of software products.",
        "Identify, record, and track defects using bug tracking systems like Jira or Bugzilla.",
        "Collaborate with cross-functional teams to reproduce and debug issues.",
        "Automate test cases and regression tests using tools like Selenium or TestNG.",
        "Perform manual and automated testing on web and mobile applications.",
        "Stay up-to-date with testing methodologies, tools, and best practices.",
      ],
      requiredSkills: [
        "Proficiency in software testing methodologies and tools.",
        "Experience with test automation frameworks such as Selenium, TestNG, or JUnit.",
        "Strong analytical and problem-solving skills.",
        "Knowledge of programming languages such as Java, Python, or JavaScript.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 11,
      designation: "Software Engineer",
      job_id: "V_1598",
      job_postedon: "15-03-2024",
      description:
        "We are seeking a skilled Software Engineer to join our team. As a Software Engineer, you will be responsible for designing, developing, and maintaining software solutions. You will work closely with cross-functional teams to deliver high-quality software products that meet customer needs and business requirements.",
      responsibilities: [
        "Design, develop, and maintain software applications and systems.",
        "Collaborate with product managers, designers, and other stakeholders to gather requirements and define project scope.",
        "Write clean, efficient, and well-documented code following best practices.",
        "Test and debug software applications to ensure optimal performance and reliability.",
        "Participate in code reviews and provide constructive feedback to team members.",
        "Stay up-to-date with the latest technologies and industry trends in software development.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Java, C#, Python, or JavaScript.",
        "Experience with software development frameworks and tools.",
        "Strong problem-solving and analytical skills.",
        "Knowledge of software engineering best practices and design patterns.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 12,
      designation: "Product Manager",
      job_id: "V_3579",
      job_postedon: "18-03-2024",
      description:
        "We are looking for an experienced Product Manager to join our team. As a Product Manager, you will be responsible for leading the development and delivery of software products. You will work closely with cross-functional teams to define product strategy, prioritize features, and drive product success.",
      responsibilities: [
        "Define product vision, strategy, and roadmap based on market research and customer feedback.",
        "Work closely with engineering, design, and marketing teams to prioritize features and define product requirements.",
        "Drive the entire product lifecycle from concept to launch, including product planning, development, and launch.",
        "Analyze market trends, competitive landscape, and customer needs to identify opportunities for product innovation and differentiation.",
        "Collaborate with sales and marketing teams to develop go-to-market strategies and drive product adoption.",
        "Measure and analyze product performance metrics to inform decision-making and drive continuous improvement.",
      ],
      requiredSkills: [
        "Proven experience as a Product Manager or similar role in software development.",
        "Strong understanding of product management principles and practices.",
        "Excellent communication, leadership, and collaboration skills.",
        "Ability to prioritize and manage multiple projects in a fast-paced environment.",
        "Analytical mindset with the ability to make data-driven decisions.",
        "Experience with Agile development methodologies.",
        "Bachelor's degree in Computer Science, Engineering, or related field.",
      ],
    },
    // Add more job data here...
  ];

  return (
    <div>
      {/* Process Section Start */}
      <div className="rs-process style2 gray-bg2 pt-50 pb-50 md-pt-50 md-pb-50">
        <div className="container custom">
          <div className="row y-middle mb-50">
            <div className="col-lg-5">
              <div className="sec-title md-mb-30">
                <h2 className="title">Job Openings at VSIT LLC</h2>
              </div>
            </div>
          </div>
          <CareersWrapper>
            {/* Process Section Start */}
            <div className="container custom">
              <div className="row">
                {careersData.map((item) => {
                  return (
                    <div className="col-lg-12 mb-30" key={item.id}>
                      <div className="rs-addon-number">
                        <div className="number-part">
                          <div className="number-title d-flex align-items-center justify-content-between">
                            <h3 className="title">{item.designation}</h3>
                            <i
                              className={`fa fa-chevron-${
                                expandedJob === item.id ? "up" : "down"
                              } fa-1x`}
                              onClick={() => toggleExpand(item.id)}
                            ></i>
                          </div>
                          <div className="number-txt">
                            <div className="loac-text mb-1 pb-0">
                              <strong>JOB ID:</strong> {item.job_id}
                            </div>
                            <div className="loac-text">
                              <strong>Job Posted On:</strong>{" "}
                              {item.job_postedon}
                            </div>
                            <p>{item.description}</p>
                            {expandedJob === item.id && (
                              <div>
                                <section>
                                  <h4 className="mt-5">Responsibilities:</h4>
                                  <ul>
                                    {item.responsibilities.map(
                                      (responsibility, index) => (
                                        <li key={index}>{responsibility}</li>
                                      )
                                    )}
                                  </ul>
                                </section>
                                <section>
                                  <h4>Required Skills:</h4>
                                  <ul>
                                    {item.requiredSkills.map((skill, index) => (
                                      <li key={index}>{skill}</li>
                                    ))}
                                  </ul>
                                </section>
                              </div>
                            )}
                          </div>
                          <div className="btn-part">
                            <Link
                              to="/RegistrationForm"
                              className="readon apply"
                            >
                              Apply Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Process Section End */}
          </CareersWrapper>
        </div>
      </div>
      {/* Process Section End */}
    </div>
  );
}
