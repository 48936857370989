import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function Videos() {
    return (
        <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                    All Technolgoy, learning and VSIT event videos
              </h1>
            </div>
          </div>
        </div>
        {/* Breadcrumbs End */}
        {/* Project Section Start */}
        <div className="rs-project style2 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="gridFilter mb-50 md-mb-30 text-center">
              <button className="active" data-filter="*">All</button>
              <button data-filter=".filter1">Artificial Intelligence</button>
              <button data-filter=".filter2">BigData</button>
              <button data-filter=".filter3">Hadoop</button>
              <button data-filter=".filter4">VSIT</button>
            </div>                    
            <div className="row grid">

            {/* filter 1 Start */}
              <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
                <div className="project-item">
                  <div className="project-img">
                    <iframe width="100%" height="350" src="https://www.youtube.com/embed/JMUxmLyrhSk" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>                 
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
                <div className="project-item">
                  <div className="project-img">
                    <iframe width="100%" height="350" src="https://www.youtube.com/embed/wTbrk0suwbg" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>                 
                </div>
              </div>   
            {/* filter 1 End */}       

            {/* filter 2 Start */}    
              <div className="col-lg-6 col-md-6 mb-30 grid-item filter2">
                    <div className="project-item">
                    <div className="project-img">
                        <iframe width="100%" height="350" src="https://www.youtube.com/embed/1vbXmCrkT3Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>                 
                    </div>
              </div> 
            {/* filter 2 End */}

            {/* filter 3 Start */}
              <div className="col-lg-6 col-md-6 mb-30 grid-item filter3">
                    <div className="project-item">
                    <div className="project-img">
                        <iframe width="100%" height="350" src="https://www.youtube.com/embed/aReuLtY0YMI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>                 
                    </div>
              </div>  
            {/* filter 3 End */}

            {/* filter 4 Start */}
              <div className="col-lg-6 col-md-6 mb-30 grid-item filter4">
                    {/* <div className="project-item">
                        <div className="project-img">
                            <iframe width="100%" height="350" src="https://www.youtube.com/embed/aReuLtY0YMI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>                 
                    </div> */}
              </div> 
            {/* filter 4 End */}

            </div>
          </div>
        </div>
        {/* Project Section End */}
      </div>
    )
}