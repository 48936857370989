import React, { useEffect, useState, FunctionComponent } from "react";
import Router from "../../routes";

import { Route, Link, Switch, Redirect } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const [scrolled, setScrolled] = useState([] as any);

  return (
    <>
      {/* Footer Start */}
      <footer id="rs-footer" className="rs-footer style1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <div className="footer-logo mb-40">
                  <a>
                    <img src="assets/images/brand-logo.png" alt="logo" />
                  </a>
                </div>
                <div className="textwidget white-color pb-40">
                  <p>
                    VSIT LLC is a leading IT solutions company that excels in
                    delivering AI and ML services. With an experience of #years,
                    we aim to deliver prime solutions to our customers.{" "}
                  </p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a href="#" target="_blank">
                      <span>
                        <i className="fa fa-facebook" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="# " target="_blank">
                      <span>
                        <i className="fa fa-linkedin" />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="# " target="_blank">
                      <span>
                        <i className="fa fa-instagram" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10 pl-55 md-pl-15">
                <h3 className="footer-title">Our Services</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/bigdata">Big Data</Link>
                  </li>
                  <li>
                    <Link to="/hadoop">Hadoop</Link>
                  </li>
                  <li>
                    <Link to="/machine-learning">Machine Learning</Link>
                  </li>
                  <li>
                    <Link to="/aws">AWS</Link>
                  </li>
                  <li>
                    <Link to="/dataiku">Dataiku</Link>
                  </li>
                  <li>
                    <Link to="/snowflake">Snowflake</Link>
                  </li>
                  <li>
                    <Link to="/microsoft-azure">Microsoft Azure</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <h3 className="footer-title">Contact Info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location" />
                    <div className="desc">
                      400 CHISHOLM PLACE, SUITE 214, PLANO, TX, 75075.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call" />
                    <div className="desc">
                      <a href="tel:(+91)6039434063">(945) 268-4454</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email" />
                    <div className="desc">
                      <a href="mailto:info@vsitsllc.com">info@vsitsllc.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-clock-1" />
                    <div className="desc">Office Hours: 10AM - 7PM</div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <h3 className="footer-title">Newsletter</h3>
                <p className="widget-desc white-color">
                  Stay up to update with our latest news and products.
                </p>
                <p>
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Your email address"
                    required
                  />
                  <input type="submit" defaultValue="Subscribe Now" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                {/* <ul className="copy-right-menu">
                                    <li><a>Home</a></li>
                                    <li><a>About</a></li>
                                    <li><a>Contact</a></li>
                                </ul> */}
              </div>
              {/* <div className="col-lg-12">
                                <div className="copyright text-lg-start text-center ">
                                    <p>© 2021 VSIT - Consulting Company Inc. All Rights Reserved.</p>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* Footer End */}

      {/* start scrollUp  */}
      <div id="scrollUp" className="orange-color">
        <i className="fa fa-angle-up" />
      </div>
      {/* End scrollUp  */}
    </>
  );
};

export default Footer;
