import React from 'react';
import ComingSoon from './ComingSoon';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';

export default function AboutUs() {
    return (
        <div>
            {/* Breadcrumbs Start */}
            <div className="rs-breadcrumbs img3">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            About VSIT LLC  Inc.
                            <span className="watermark">About</span>
                        </h1>
                        <span className="sub-text">VSIT LLC  is a leading IT solutions company that excels in delivering AI and ML services. With an experience of #years, we aim to deliver prime solutions to our customers. </span>
                        <p className='sub-text'>We are here to help our customers in every possible way. This is why we are trusted by leading companies of different sectors.</p>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs End */}
            {/* About Section Start */}
            <div className="rs-about style2 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-33 md-pr-15 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/about/about-3.png" alt="Images" />
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="sec-title">
                                <h2 className="title pb-22">
                                    We help to implement your ideas into automation
                                </h2>
                                <span className="sub-text big">Match the fast-changing world of technology by employing AI and Machine Learning tools. Data-driven techniques are the future of business optimization. Make sure that your organisation embraces this change and makes the most of it.</span>
                                <p className="margin-0 pt-15">If you are also looking for a team to help you with data-driven decision making processes to enhance your customer base, you have landed on the right page. We prefer quality over quantity and that is what makes VSIT LLC  one of the bests in the market.</p>
                                <p className="margin-0 pt-15">We are here to help our customers in every possible way. This is why we are trusted by leading companies of different sectors.</p>
                                <div className="btn-part mt-45 md-mt-30">
                                    <Link to="/contact-us" className="readon consultant discover">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rs-animation">
                    <div className="animate-style">
                        <img className="scale" src="assets/images/about/tri-circle-1.png" alt="About" />
                    </div>
                </div>
            </div>
            {/* About Section End */}
            {/* Services Section Start */}
            <div id="rs-services" className="rs-services chooseus-style about-style bg12 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/1.png" alt="Images" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a>Expert peoples</a></h3>
                                    <p className="services-txt">We have team of more than 47 recruiters in USA.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/2.png" alt="Images" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a>Big experience</a></h3>
                                    <p className="services-txt">It's all been good experiences here.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-item">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/3.png" alt="Images" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a>Committed to quality</a></h3>
                                    <p className="services-txt">Commitment is an act, not a word!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section End */}
            {/* Why Choose Section Start */}
            <div className="rs-choose pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 pr-70 md-pr-15 md-mb-50">
                            <div className="sec-title mb-35">
                                <h2 className="title pb-30">
                                Innovate with perfection

                                </h2>
                                <p className="margin-0">Perfection is a myth, they say. But that doesn’t mean that you give up. Our motto is to provide innovative solutions to our clients. All of our services are developed using innovative and modern technology. We upgrade our tools as per the changing technology. This allows us to stay updated with our client needs as well.
</p>
<p className='margin-0'>Our team assists you in efficiently managing and using data for outstanding business growth. We start from scratch and help you build effective cloud platforms. Our data engineers, architects, and strategists work step-by-step for 100% positive results.
</p>
<p className='margin-0'>Whether you are looking for IoT data employment, Data Engineers, or AI Strategists; VSIT LLC  can arrange them for you. We are ready to take on the challenges for our customers.
</p>
                            </div>
                            <p>Want to know more about our services or have any queries? Drop an email and we’ll reach out to you: info@vsitsllc.com
</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="rs-videos choose-video">
                                <div className="images-video">
                                    <img src="assets/images/choose/chosse-video.jpg" alt="images" />
                                </div>
                                <div className="animate-border">
                                    <a className="popup-border" href="https://www.youtube.com/watch?v=FMvA5fyZ338">
                                        <i className="fa fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Counter Section End */}
                <div className="rs-counter style1 pt-100 md-pt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                                <div className="counter-area">
                                    <div className="counter-list mb-20">
                                        <div className="counter-icon">
                                            <img src="assets/images/counter/icons/1.png" alt="Counter" />
                                        </div>
                                        <div className="counter-number">
                                            <span className="rs-count">582</span>
                                        </div>
                                    </div>
                                    <div className="content-part">
                                        <h5 className="title">Projects completed for our respected clients.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                                <div className="counter-area">
                                    <div className="counter-list mb-20">
                                        <div className="counter-icon">
                                            <img src="assets/images/counter/icons/2.png" alt="Counter" />
                                        </div>
                                        <div className="counter-number">
                                            <span className="rs-count">215</span>
                                            <span className="prefix">+</span>
                                        </div>
                                    </div>
                                    <div className="content-part">
                                        <h5 className="title">Experienced people serving to clients.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
                                <div className="counter-area">
                                    <div className="counter-list mb-20">
                                        <div className="counter-icon">
                                            <img src="assets/images/counter/icons/3.png" alt="Counter" />
                                        </div>
                                        <div className="counter-number">
                                            <span className="rs-count">25</span>
                                            <span className="prefix">+</span>
                                        </div>
                                    </div>
                                    <div className="content-part">
                                        <h5 className="title">Years experience in business &amp; consulting.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter-area">
                                    <div className="counter-list mb-20">
                                        <div className="counter-icon">
                                            <img src="assets/images/counter/icons/4.png" alt="Counter" />
                                        </div>
                                        <div className="counter-number">
                                            <span className="rs-count">70</span>
                                            <span className="prefix">+</span>
                                        </div>
                                    </div>
                                    <div className="content-part">
                                        <h5 className="title">Business &amp; consulting awards won over world.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Counter Section End */}
            </div>
            {/* Why Choose Section Start */}
            {/* Testimonial Section Start */}
            <div className="rs-testimonial main-home bg6 pt-50 pb-50 md-pt-50 md-pb-50">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 md-mb-40">
                            <div className="testi-image">
                                <img src="assets/images/testimonial/testi.png" alt="Testimonial" />
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-15">
                            <div className="rs-carousel owl-carousel" data-loop="true" data-items={1} data-margin={30} data-autoplay="false" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={1} data-md-device-nav="false" data-md-device-dots="true" data-center-mode="false" data-ipad-device2={1} data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-ipad-device={1} data-ipad-device-nav="false" data-ipad-device-dots="true" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="true">
                                <div className="testi-item">
                                    <div className="item-content">
                                        <span className="icon-img">
                                            <img src="assets/images/testimonial/quote.png" alt="" />
                                        </span>
                                        <p className="desc">
                                            Customer support is excellent and documentation good – novice can easily understand. Although I had a problem with the performance, thanks to the customer support, we have solved this problem as well..
                                        </p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/1.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Ben Stokes</div>
                                            <span className="testi-title">Python developer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-item">
                                    <div className="item-content">
                                        <span className="icon-img">
                                            <img src="assets/images/testimonial/quote.png" alt="" />
                                        </span>
                                        <p className="desc">
                                            These guys are awesome! Each time I needed their help, their response was quick and very helpful. Also, the theme is very flexible. Five stars from me for everything! I truly recommend this theme!!!
                                        </p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/2.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Angelina Jolie</div>
                                            <span className="testi-title">UI/UX Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-item">
                                    <div className="item-content">
                                        <span className="icon-img">
                                            <img src="assets/images/testimonial/quote.png" alt="" />
                                        </span>
                                        <p className="desc">
                                            The theme is fine. It is customizable and its demos are great. The education plugin also works fine. But, although I like it so much, I want to point out a few shortcomings. Theme is appropriate, I agree.
                                        </p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/3.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Megan Fox</div>
                                            <span className="testi-title">Senior CEO</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-item">
                                    <div className="item-content">
                                        <span className="icon-img">
                                            <img src="assets/images/testimonial/quote.png" alt="" />
                                        </span>
                                        <p className="desc">
                                            These guys are awesome! Each time I needed their help, their response was quick and very helpful. Also, the theme is very flexible. Five stars from me for everything! I truly recommend this theme!!!
                                        </p>
                                    </div>
                                    <div className="testi-content">
                                        <div className="image-wrap">
                                            <img src="assets/images/testimonial/avatar/4.jpg" alt="Testimonial" />
                                        </div>
                                        <div className="testi-information">
                                            <div className="testi-name">Nicole Kidman</div>
                                            <span className="testi-title">Envato Author</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial Section End */}
        </div>
    )
}