import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';

export default function CaseStudies() {
    return (
        <div>
            {/* Project Section Start */}
            <div className="rs-project style6 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
                <div className="container">
                    <div className="row">                      
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="assets/images/project/style1/2.jpg" alt="images" />
                                    <a className="p-icon"><i className="custom-icon" /></a>
                                </div>
                                <div className="project-content">
                                    <h3 className="title"><a>Data Hierarchy Management</a></h3>
                                    <span className="category"><a>Hierarchy management is an essential capability in MDM and PIM platforms as it can foster a holistic view on clients.</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="assets/images/project/style1/3.jpg" alt="images" />
                                    <a className="p-icon"><i className="custom-icon" /></a>
                                </div>
                                <div className="project-content">
                                    <h3 className="title"><a>Data Validation</a></h3>
                                    <span className="category"><a>Use data validation to restrict the type of data or the values that users enter into a cell. One of the most common data.</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="assets/images/project/style1/4.jpg" alt="images" />
                                    <a className="p-icon"><i className="custom-icon" /></a>
                                </div>
                                <div className="project-content">
                                    <h3 className="title"><a>E-Commerce (B2B,B2C)</a></h3>
                                    <span className="category"><a>In the B2B (Business-to-Business) eCommerce model, a company (often a manufacturer or a wholesaler) sells to other businesses.</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="assets/images/project/style1/5.jpg" alt="images" />
                                    <a className="p-icon"><i className="custom-icon" /></a>
                                </div>
                                <div className="project-content">
                                    <h3 className="title"><a>Technology Migration</a></h3>
                                    <span className="category"><a>A system migration is the process of transferring business process IT resources to a newer technologies.</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="project-item">
                                <div className="project-img">
                                    <img src="assets/images/project/style1/6.jpg" alt="images" />
                                    <a className="p-icon"><i className="custom-icon" /></a>
                                </div>
                                <div className="project-content">
                                    <h3 className="title"><a>Management Consulting</a></h3>
                                    <span className="category"><a>Management consulting is the practice of helping organizations to improve their performance.</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Project Section End */}
        </div>
    )
}