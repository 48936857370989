import React, { useState } from "react";
import { FormWrapper } from "../styles";

export default function RegistrationForm() {
  const HiringImg = require("../../assets/images/hiring.svg");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    Profile: "",
    JobTitle: "",
    Name: "",
    Email: "",
    Mobile: "",
  });

  // Additional state for "Other" job title input
  const [otherJobTitle, setOtherJobTitle] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === "JobTitle" && value === "Other") {
      // If "Other" is selected, clear JobTitle
      setFormData({
        ...formData,
        JobTitle: value,
      });
    } else {
      // For other cases, update the form data accordingly
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Update otherJobTitle when input changes
    if (name === "otherJobTitle") {
      setOtherJobTitle(value);
    }
  };

  return (
    <div className="container mt-5 mb-5 d-flex justify-content-center">
      <div className="row">
        <div className="col-sm-8">
          <FormWrapper>
            <h2>Submit Your Application Now!</h2>
            <form
              id="ttm-contactform"
              action="https://formspree.io/f/moqgwbka"
              method="POST"
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="form-group col-12">
                  Upload Resume
                  <label style={{ marginTop: 6 }}>
                    <span className="text-input">
                      <input
                        name="Profile"
                        type="file"
                        required
                        style={{ border: "transparent", color: "white" }}
                      />
                    </span>
                  </label>
                </div>
                <div className="form-group col-12">
                  <label style={{ marginBottom: "0.1rem" }}>Job Title</label>
                  <select
                    name="JobTitle"
                    value={formData.JobTitle}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Job Title</option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value=".NET Developer">.NET Developer</option>
                    <option value="Java Developer">Java Developer</option>
                    <option value="SAP">SAP</option>
                    <option value="Sales Force Developer">
                      Sales Force Developer
                    </option>
                    <option value="Data Engineer">Data Engineer</option>
                    <option value="RPA Developer">RPA Developer</option>
                    <option value="Data Analyst">Data Analyst</option>
                    <option value="BI Developer">BI Developer</option>
                    <option value="QA Engineer">QA Engineer</option>
                    <option value="Software Engineer">Software Engineer</option>
                    <option value="Product Manager">Product Manager</option>
                    <option value="Service Now Developer">
                      Service Now Developer
                    </option>
                    <option value="SQL Developer">SQL Developer</option>
                    <option value="DevOps Developer">DevOps Developer</option>
                    <option value="Other">Other</option>
                  </select>

                  {/* Render input field only if JobTitle is "Other" */}
                  {formData.JobTitle === "Other" && (
                    <input
                      type="text"
                      name="otherJobTitle"
                      className="mt-4"
                      placeholder="Enter your job title"
                      value={otherJobTitle}
                      onChange={handleChange}
                      required
                    />
                  )}
                </div>
                <div className="form-group col-12">
                  <label>
                    Full Name
                    <span className="text-input">
                      <input
                        name="Name"
                        type="text"
                        value={formData.Name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                      />
                    </span>
                  </label>
                </div>
                <div className="form-group col-12">
                  Email Address
                  <label>
                    <span className="text-input">
                      <input
                        name="Email"
                        type="email"
                        value={formData.Email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        required
                      />
                    </span>
                  </label>
                </div>
                <div className="form-group col-12">
                  Mobile Number
                  <label>
                    <span className="text-input">
                      <input
                        name="Mobile"
                        type="text"
                        value={formData.Mobile}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        required
                      />
                    </span>
                  </label>
                </div>
              </div>
              {loading ? (
                <div className="loader"></div>
              ) : success ? (
                <p>Thanks for applying job!</p>
              ) : (
                <button
                  type="submit"
                  id="submit-btn"
                  className="btn btn-warning w-auto mb-5 mt-3 p-3"
                >
                  <b>Register Now!</b>
                </button>
              )}
            </form>
          </FormWrapper>
        </div>
        <div className="col-sm-4">
          <img src={HiringImg} alt="hiring" className="w-100" />
        </div>
      </div>
    </div>
  );
}
