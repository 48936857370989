import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Contactus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // used emailjs.com
    // login cred: username: msraj1226@gmail.com and pass: a******l2**7
    emailjs
      .sendForm(
        "service_pyfyy4g",
        "template_fz2x8qa",
        form.current,
        "user_FIgygPWj3vQuFBn77mIao"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.error?.message
              ? error?.response?.data?.error?.message
              : "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                Have questions - connect with us
                <span className="watermark">Contact</span>
              </h1>
              <span className="sub-text">We help you always.</span>
            </div>
          </div>
        </div>
        {/* Breadcrumbs End */}

        {/* Contact Section Start */}
        <div className="rs-contact contact-style2 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0">
                <div className="contact-address">
                  <div className="sec-title mb-46">
                    <h2 className="title pb-20">Get in touch</h2>
                    <p className="margin-0">
                      Objectively innovate your empowered manufactured products
                      whereas parallel platforms for your ideas.
                    </p>
                  </div>

                  {/* <div class="alert alert-primary" role="alert">
                    Signing Authority : <b>Sanket N Patel </b>
                  </div>

                  <div class="alert alert-warning" role="alert">
                    Company EIN :  <b>88-4412683</b>
                  </div> */}

                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/1.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      400 CHISHOLM PLACE, SUITE 214, PLANO, TX, 75075.
                    </div>
                  </div>
                  {/* <div className="address-item mb-25">
                                        <div className="address-icon">
                                            <img src="assets/images/contact/icons/5.png" alt="Address" />
                                        </div>
                                        <div className="address-text">
                                            <a href="tel:(+088)589-8745">(+088) 589-8745</a><br />
                                            <a href="tel:(+088)442253">(+088) 222-9999</a>
                                        </div>
                                    </div> */}
                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/6.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a href="mailto:info@vsitsllc.com">info@vsitsllc.com</a>
                    </div>
                  </div>
                  <div className="address-item">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/7.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a>(945) 268-4454</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 padding-0">
                <div className="contact-map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.120036121003!2d-96.71763601107374!3d33.02696790538731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c18fbeeff1fa7%3A0x7d151ad73b260cbe!2s400%20Chisholm%20Pl%2C%20Plano%2C%20TX%2075075%2C%20USA!5e0!3m2!1sen!2sin!4v1718278090333!5m2!1sen!2sin"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}

        {/* Contact Section Start*/}
        <div className="rs-contact main-home office-modify1 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0 office-buliding" />
              <div className="col-lg-6 padding-0">
                <div className="contact-section contact-style2">
                  <div className="sec-title mb-60">
                    <h2 className="title">Contact us</h2>
                  </div>
                  <div className="contact-wrap">
                    <div id="form-messages" />
                    <form id="contact-form" method="post" action="mailer.php">
                      <fieldset>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="email"
                              name="email"
                              placeholder="E-Mail"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Phone Number"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="Website"
                              name="subject"
                              placeholder="Your Website"
                              required
                            />
                          </div>
                          <div className="col-lg-12 mb-35">
                            <textarea
                              className="from-control"
                              id="message"
                              name="message"
                              placeholder="Your message Here"
                              required
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="btn-part">
                          <div className="form-group mb-0">
                            <input
                              className="readon submit"
                              type="submit"
                              defaultValue="Submit Now"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}
        {/* Partner Section Start */}
        <div className="rs-patter-section black-bg pt-80 pb-75">
          <div className="container custom">
            <div
              className="rs-carousel owl-carousel"
              data-loop="true"
              data-items={5}
              data-margin={30}
              data-autoplay="true"
              data-hoverpause="true"
              data-autoplay-timeout={5000}
              data-smart-speed={800}
              data-dots="false"
              data-nav="false"
              data-nav-speed="false"
              data-md-device={5}
              data-md-device-nav="false"
              data-md-device-dots="false"
              data-center-mode="false"
              data-ipad-device2={4}
              data-ipad-device-nav2="false"
              data-ipad-device-dots2="false"
              data-ipad-device={4}
              data-ipad-device-nav="false"
              data-ipad-device-dots="false"
              data-mobile-device={2}
              data-mobile-device-nav="false"
              data-mobile-device-dots="false"
            >
              <div className="logo-img">
                <a>
                  <img
                    className="hovers-logos rs-grid-img"
                    src="assets/images/partner/main-home/1.png"
                    alt=""
                  />
                  <img
                    className="mains-logos rs-grid-img "
                    src="assets/images/partner/main-home/1.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="logo-img">
                <a>
                  <img
                    className="hovers-logos rs-grid-img"
                    src="assets/images/partner/main-home/2.png"
                    alt=""
                  />
                  <img
                    className="mains-logos rs-grid-img "
                    src="assets/images/partner/main-home/2.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="logo-img">
                <a>
                  <img
                    className="hovers-logos rs-grid-img"
                    src="assets/images/partner/main-home/3.png"
                    alt=""
                  />
                  <img
                    className="mains-logos rs-grid-img "
                    src="assets/images/partner/main-home/3.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="logo-img">
                <a>
                  <img
                    className="hovers-logos rs-grid-img"
                    src="assets/images/partner/main-home/4.png"
                    alt=""
                  />
                  <img
                    className="mains-logos rs-grid-img "
                    src="assets/images/partner/main-home/4.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="logo-img">
                <a>
                  <img
                    className="hovers-logos rs-grid-img"
                    src="assets/images/partner/main-home/5.png"
                    alt=""
                  />
                  <img
                    className="mains-logos rs-grid-img "
                    src="assets/images/partner/main-home/5.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Partner Section End */}
      </div>
    </>
  );
};

export default Contactus;
