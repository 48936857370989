import { Route, Switch, BrowserRouter } from "react-router-dom";
import React from "react";
import ScrollIntoView from "../../components/ScrollIntoView";

// single pages
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contactus from "../Pages/Contactus";

// technical tab pages
import BigData from "../Pages/BigData";
import Hadoop from "../Pages/Hadoop";
import MachineLearning from "../Pages/MachineLearning";
import AWS from "../Pages/Aws";
import Dataiku from "../Pages/Dataiku";
import Snowflake from "../Pages/Snowflake";
import MicrosoftAzure from "../Pages/MicrosoftAzure";

// software built tab pages
import Tram from "../Pages/Tram";
import Traverse from "../Pages/Traverse";
import CloudFoundation from "../Pages/CloudFoundation";
import PAMS from "../Pages/PAMS";
import SQLMorph from "../Pages/SQLMorph";

// services tab pages
import AIStrategy from "../Pages/AIStrategy";
import DataStrategy from "../Pages/DataStrategy";
import SnowflakeAdministration from "../Pages/SnowflakeAdministration";
import DataScience from "../Pages/DataScience";
import DataEngineering from "../Pages/DataEngineering";
import CDMS from "../Pages/CDMS";
import ManagedMLOps from "../Pages/ManagedMLOps";

// expedient tab pages
import Careers from "../Pages/Careers";
import CaseStudies from "../Pages/CaseStudies";
import Ebooks from "../Pages/Ebooks";
import Videos from "../Pages/Videos";
import Documentation from "../Pages/Documentation";
import Blog from "../Pages/Blog";
import FAQ from "../Pages/FAQ";

// other pages
import ComingSoon from "../Pages/ComingSoon";
import StudentRegistration from "../Pages/StudentRegistration";

import RegistrationForm from "../Pages/RegistrationForm";
import Success from "../Pages/Success";

export function Router() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={"/"} component={Home} />
        {/* single pages */}
        <Route path="/home" component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={Contactus} />

        {/* technical tab pages */}
        <Route path="/bigdata" component={BigData} />
        <Route path="/hadoop" component={Hadoop} />
        <Route path="/machine-learning" component={MachineLearning} />
        <Route path="/aws" component={AWS} />
        <Route path="/dataiku" component={Dataiku} />
        <Route path="/snowflake" component={Snowflake} />
        <Route path="/microsoft-azure" component={MicrosoftAzure} />

        {/* software built tab pages */}
        <Route path="/tram" component={Tram} />
        <Route path="/traverse" component={Traverse} />
        <Route path="/cloud-foundation" component={CloudFoundation} />
        <Route path="/pams" component={PAMS} />
        <Route path="/sql-morph" component={SQLMorph} />

        {/* services tab pages */}
        <Route path="/ai-strategy" component={AIStrategy} />
        <Route path="/data-strategy" component={DataStrategy} />
        <Route
          path="/snowflake-administration"
          component={SnowflakeAdministration}
        />
        <Route path="/data-science" component={DataScience} />
        <Route path="/data-engineering" component={DataEngineering} />
        <Route path="/cdms" component={CDMS} />
        <Route path="/managed-mlops" component={ManagedMLOps} />

        {/* expedient tab pages */}
        <Route path="/careers" component={Careers} />
        <Route path="/case-studies" component={CaseStudies} />
        <Route path="/ebooks" component={Ebooks} />
        <Route path="/videos" component={Videos} />
        <Route path="/documentation" component={Documentation} />
        <Route path="/blog" component={Blog} />
        <Route path="/faq" component={FAQ} />

        {/* other pages */}
        <Route path="/comingsoon" component={ComingSoon} />
        <Route path="/student-registration" component={StudentRegistration} />

        <Route path="/RegistrationForm" component={RegistrationForm} />
        <Route path="/Success" component={Success} />
      </Switch>
    </ScrollIntoView>
  );
}

export default Router;
