import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Router from "../../routes";

const Header = () => {
  const form = useRef();

  const [isVisble, setIsVisible] = useState(true);

  // const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs.sendForm('service_pyfyy4g', 'template_fz2x8qa', form.current, 'user_FIgygPWj3vQuFBn77mIao')
  //         .then((result) => {
  //             console.log(result.text);
  //             Swal.fire({
  //                 title: 'Thank you for registering!',
  //                 text: 'We will get back soon.',
  //                 icon: 'success',
  //                 confirmButtonText: 'OK'
  //             })
  //         }, (error) => {
  //             console.log(error.text);
  //             Swal.fire({
  //                 title: 'Error!',
  //                 text: error?.response?.data?.error?.message ? error?.response?.data?.error?.message : 'Something went wrong!',
  //                 icon: 'error',
  //                 confirmButtonText: 'OK'
  //             })
  //         });
  //     e.target.reset()
  // };

  // const onSidebar = () => {
  //     setIsVisible(true)
  // }

  // const closeSidebar = () => {
  //     setIsVisible(false)
  // }

  return (
    <>
      {/*Full width header Start*/}
      <div className="full-width-header">
        {/*Header Start*/}
        <header id="rs-header" className="rs-header style2 header-transparent">
          {/* Topbar Area Start */}
          <div className="topbar-area style1">
            <div className="container custom">
              <div className="row y-middle">
                <div className="col-lg-10">
                  <div className="topbar-contact">
                    <ul>
                      <li>
                        <i className="flaticon-email" />
                        <a href="mailto:info@vsitsllc.com">info@vsitsllc.com</a>
                      </li>
                      <li>
                        <i className="flaticon-call" />
                        <a href="tel:(+1)9999999999"> (945) 268-4454</a>
                      </li>
                      <li>
                        <i className="flaticon-location" />
                        400 CHISHOLM PLACE, SUITE 214, PLANO, TX, 75075.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 text-right">
                  <div className="toolbar-sl-share">
                    <ul>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/vsit-llc"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Topbar Area End */}

          {/* Menu Start */}
          <div className="menu-area menu-sticky">
            <div className="container custom">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <a href="#">
                      <img src="assets/images/brand-logo.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li className="current-menu-item">
                            <a href="#">Home</a>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="#">Technical</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/machine-learning">
                                  Machine Learning
                                </Link>
                              </li>
                              <li>
                                <Link to="/aws">AWS</Link>
                              </li>
                              <li>
                                <Link to="/dataiku">Dataiku</Link>
                              </li>
                              <li>
                                <Link to="/snowflake">Snowflake</Link>
                              </li>
                              <li>
                                <Link to="/bigdata">Big Data</Link>
                              </li>
                              <li>
                                <Link to="/hadoop">Hadoop</Link>
                              </li>
                              <li>
                                <Link to="/microsoft-azure">
                                  Microsoft Azure
                                </Link>
                              </li>

                              <li className="last-item menu-item-has-children">
                                <div className="d-flex justify-content-between">
                                  <a href="#">
                                    Technical Partners{" "}
                                    <i className="pl-5 fa fa-chevron-right text-danger" />
                                  </a>
                                </div>
                                <ul className="sub-menu">
                                  <li>
                                    <a
                                      href="https://www.capitalone.com/"
                                      target="_blank"
                                    >
                                      Capital one{" "}
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.unitedhealthgroup.com/"
                                      target="_blank"
                                    >
                                      UHG
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.t-mobile.com/"
                                      target="_blank"
                                    >
                                      T-mobile
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.acxiom.com/"
                                      target="_blank"
                                    >
                                      Acxiom
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.statefarm.com/"
                                      target="_blank"
                                    >
                                      State Farm
                                    </a>{" "}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="#">Software Built</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/cloud-foundation">
                                  Cloud Foundation
                                </Link>
                              </li>
                              <li>
                                <Link to="/pams">PAMS</Link>
                              </li>
                              <li>
                                <Link to="/sql-morph">SQL Morph</Link>
                              </li>
                              {/* <li><Link to="/tram">Tram</Link></li> */}
                              <li>
                                <Link to="/traverse">Traverse</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="#">Services</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to="ai-strategy">AI Strategy</Link>
                              </li>
                              <li>
                                <Link to="data-strategy">Data Strategy</Link>
                              </li>
                              <li>
                                <Link to="snowflake-administration">
                                  Snowflake Administration
                                </Link>
                              </li>
                              <li>
                                <Link to="data-science">Data Science</Link>
                              </li>
                              <li>
                                <Link to="data-engineering">
                                  Data Engineering
                                </Link>
                              </li>
                              <li>
                                <Link to="managed-mlops">Cloud DataOps</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="#">Expedient</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to="careers">Careers</Link>
                              </li>
                              <li>
                                <Link to="case-studies">Case Studies</Link>
                              </li>
                              <li>
                                <Link to="ebooks">eBooks</Link>
                              </li>
                              <li>
                                <Link to="videos">Videos</Link>
                              </li>
                              <li>
                                <Link to="documentation">Documentation</Link>
                              </li>
                            </ul>
                          </li>
                          <li className>
                            <Link to="/about-us">What we?</Link>
                          </li>
                          <li className>
                            <Link to="/contact-us">Reach us</Link>
                          </li>
                        </ul>{" "}
                        {/* //.nav-menu */}
                      </nav>
                    </div>{" "}
                    {/* //.main-menu */}
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="humburger">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
                          href="#"
                        >
                          <div className="bar">
                            <span className="dot1" />
                            <span className="dot2" />
                            <span className="dot3" />
                            <span className="dot4" />
                            <span className="dot5" />
                            <span className="dot6" />
                            <span className="dot7" />
                            <span className="dot8" />
                            <span className="dot9" />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}

          {/* Canvas Menu start */}
          <nav className="right_menu_togle hidden-md">
            <div className="close-btn">
              <a id="nav-close" className="nav-close">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <div className="canvas-logo">
              <a>
                <img src="assets/images/brand-logo.png" alt="logo" />
              </a>
            </div>
            <div className="offcanvas-text">
              <p>
                VSIT LLC is a leading IT solutions company that excels in
                delivering AI and ML services. With an experience of #years, we
                aim to deliver prime solutions to our clients.{" "}
              </p>
            </div>
            <div className="media-img">
              <img src="assets/images/pages/ai/3.gif" alt="Images" />
            </div>
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Address</h4>
                    <em>400 CHISHOLM PLACE, SUITE 214, PLANO, TX, 75075. </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:info@vsitsllc.com">info@vsitsllc.com</a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em>(945) 268-4454</em>
                  </div>
                </div>
              </div>
              <ul className="social">
                <li>
                  <a href="https://www.linkedin.com/company/vsit-llc">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}

          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li className="current-menu-item">
                <a href="#">Parent</a>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Technical</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/machine-learning">Machine Learning</Link>
                  </li>
                  <li>
                    <Link to="/aws">AWS</Link>
                  </li>
                  <li>
                    <Link to="/dataiku">Dataiku</Link>
                  </li>
                  <li>
                    <Link to="/snowflake">Snowflake</Link>
                  </li>
                  <li>
                    <Link to="/bigdata">Big Data</Link>
                  </li>
                  <li>
                    <Link to="/hadoop">Hadoop</Link>
                  </li>
                  <li>
                    <Link to="/microsoft-azure">Microsoft Azure</Link>
                  </li>

                  <li className="last-item menu-item-has-children">
                    <div className="d-flex justify-content-between">
                      <a href="#">
                        Technical Partners{" "}
                        <i className="pl-5 fa fa-chevron-right text-danger" />
                      </a>
                    </div>
                    <ul className="sub-menu">
                      <li>
                        <a href="https://www.capitalone.com/" target="_blank">
                          Capital one{" "}
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.unitedhealthgroup.com/"
                          target="_blank"
                        >
                          UHG
                        </a>{" "}
                      </li>
                      <li>
                        <a href="https://www.t-mobile.com/" target="_blank">
                          T-mobile
                        </a>{" "}
                      </li>
                      <li>
                        <a href="https://www.acxiom.com/" target="_blank">
                          Acxiom
                        </a>{" "}
                      </li>
                      <li>
                        <a href="https://www.statefarm.com/" target="_blank">
                          State Farm
                        </a>{" "}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Software Built</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/cloud-foundation">Cloud Foundation</Link>
                  </li>
                  <li>
                    <Link to="/pams">PAMS</Link>
                  </li>
                  <li>
                    <Link to="/sql-morph">SQL Morph</Link>
                  </li>
                  {/* <li><Link to="/tram">Tram</Link></li> */}
                  <li>
                    <Link to="/traverse">Traverse</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Services</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="ai-strategy">AI Strategy</Link>
                  </li>
                  <li>
                    <Link to="data-strategy">Data Strategy</Link>
                  </li>
                  <li>
                    <Link to="snowflake-administration">
                      Snowflake Administration
                    </Link>
                  </li>
                  <li>
                    <Link to="data-science">Data Science</Link>
                  </li>
                  <li>
                    <Link to="data-engineering">Data Engineering</Link>
                  </li>
                  <li>
                    <Link to="managed-mlops">Cloud DataOps</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Expedient</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="case-studies">Case Studies</Link>
                  </li>
                  <li>
                    <Link to="ebooks">eBooks</Link>
                  </li>
                  <li>
                    <Link to="videos">Videos</Link>
                  </li>
                  <li>
                    <Link to="documentation">Documentation</Link>
                  </li>
                </ul>
              </li>
              <li className>
                <Link to="/about-us">About</Link>
              </li>
              <li className>
                <Link to="/contact-us">Reach us</Link>
              </li>
            </ul>{" "}
            {/* //.nav-menu */}
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Address</h4>
                    <em>400 CHISHOLM PLACE, SUITE 214, PLANO, TX, 75075. </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:info@vsitsllc.com">info@vsitsllc.com</a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em>(945) 268-4454</em>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
      {/*Full width header End*/}

      <Router />
    </>
  );
};

export default Header;
